import * as actionTypes from './resetPasswordActionTypes';

import messageType from '../../../helpers/enums/messageTypes';
import util from '../../../helpers/utils/util';
import httpVerb from '../../../helpers/enums/httpVerbs';
let apiParams = {};
export function ResetPassword(user) {
  return function(dispatch) {
    var headers = {
      'Content-Type': 'application/json'
    };
    util.toggleSpinner(false);
    apiParams = { user };

    return util
      .axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/password/reset/confirm`, apiParams, { headers })
      .then(response => {
        if (response.data.error === undefined) {
          dispatch({
            type: actionTypes.RESET_PASSWORD_SUCCESS,
            payload: response.data
          });

          util.displayMessage(messageType.SUCCESS, response.data.message);
          util.toggleSpinner(true);
        } else {
          dispatch({
            type: actionTypes.RESET_PASSWORD_ERROR,
            payload: response.data
          });

          util.displayMessage(messageType.ERROR, response.data.message);
          util.toggleSpinner(true);
        }
      })
      .catch(error => {
        util.displayMessage(messageType.ERROR, error.message);
        util.toggleSpinner(true);
      });
  };
}
