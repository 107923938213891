import React from 'react';
import { UITextMessages } from '../helpers/constants/uiTextMessages';
import { Row, Col } from 'antd';
import { ReactComponent as TickMark } from '../assets/images/tickmark.svg';
import userTypes from '../helpers/enums/userTypes';

const Features = (props) => {
  return (
    <div>
      <h3>{UITextMessages.label.KEY_FEATURES}</h3>
      {(parseInt(props.userTypeId) === parseInt(userTypes.VC)
        ? UITextMessages.message.VC_KEY_FEATURES_POINTS
        : UITextMessages.message.KEY_FEATURES_POINTS
      ).map((feature, index) => {
        return (
          <Row key={index} className='key_feature_points'>
            <Col xs={2} sm={2} md={3} lg={3} xl={3}>
              <TickMark className='tickmark' />
            </Col>
            <Col xs={22} sm={22} md={21} lg={21} xl={21}>
              {feature}
            </Col>
          </Row>
        );
      })}
      <div className='login-image' />
    </div>
  );
};

export default Features;
