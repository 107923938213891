import * as actionTypes from './tokenAuthenticationActionTypes';

const tokenAuthenticationReducer = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.TOKEN_ALREADY_EXISTS:
      return { ...state, tokenStatus: payload };
    default:
      return state;
  }
};
export default tokenAuthenticationReducer;
