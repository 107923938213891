import * as actionTypes from './registerActionTypes';

const registrationReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.REGISTRATION_SUCCESS:
      return { ...state, registrationInfo: action.payload };
    case actionTypes.CLEAR_REGISTRATION_REDUCER:
        return { ...state, registrationInfo: {} };
    default:
      return state;
  }
};
export default registrationReducer;
