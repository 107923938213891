import * as actionTypes from './forgotPasswordActionTypes';

const forgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        passwordInfo: action.payload
      });

    case actionTypes.CLEAR_FORGOT_PASSWORD_REDUCER:
      return { ...state, passwordInfo: {} };

    default:
      return state;
  }
};

export default forgotPasswordReducer;
