import * as actionTypes from './BackgroundSettingActionTypes';

const backgroundSettingReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_BACKGROUND_COLOR:
      return { ...state, bgColorInfo: action.payload };

    case actionTypes.UPDATE_BACKGROUND_IMAGE:
      return { ...state, bgImageInfo: action.payload };

    case actionTypes.FETCH_ASSET_LIBRARY_IMAGE_SUCCESS:
      return { ...state, assetLibraryImages: action.payload };

    case actionTypes.FETCH_MY_LIBRARY_IMAGE_SUCCESS:
      return { ...state, myLibraryImages: action.payload };

    case actionTypes.UPDATE_USER_ASSET_SETTINGS:
      return { ...state, userAsset: action.payload };
    case actionTypes.UPDATE_RECENT_COLORS:
      return { ...state, recentColors: action.payload };
    case actionTypes.UPDATE_OVERLAY_RECENT_COLORS:
      return { ...state, recentOverlayColors: action.payload };

    default:
      return state;
  }
};
export default backgroundSettingReducer;
