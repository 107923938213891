import React, { Component } from 'react';
import { Row, Col, Form, Icon, Input, Button, Select, Radio, Divider, Checkbox } from 'antd';
import { NavLink, Link } from 'react-router-dom';
import SocialLogin from '../login/socialLogin/SocialLogin';
import { connect } from 'react-redux';
import * as actions from './registrationActions';
import Features from '../../components/Features';
import csc from 'country-state-city';
import { UITextMessages } from '../../helpers/constants/uiTextMessages';
import userTypes from '../../helpers/enums/userTypes';
import util from '../../helpers/utils/util';
import paypalFormTypes from '../../helpers/enums/paypalFormTypes';
//Image import
import { ReactComponent as EarthGlobe } from '../../assets/images/earth-globe.svg';
import { ReactComponent as OrLOGO } from '../../assets/images/or.svg';
import { ReactComponent as RegisteredSuccess } from '../../assets/images/register-sucessful.svg';
import { ReactComponent as Mail } from '../../assets/images/mail.svg';
import { ReactComponent as User } from '../../assets/images/user.svg';
import { ReactComponent as Lock } from '../../assets/images/pass.svg';
import { ReactComponent as LogoNew } from '../../assets/images/logo_new.svg';
import { Scrollbars } from 'react-custom-scrollbars';
//Less Import
import './registration.less';
import ReactGA from 'react-ga';
const { Option } = Select;

class CommonRegistration extends Component{

    constructor(props) {
        super(props);    
        this.state = {
            name: '',
            email: '',
            password: '',
            country: '',
            userTypeId: userTypes.EL,
            checked: true,
            disabled: false,
            submit: false,
          };
        }    
      
    onCountrySelect = (countryName, countryId) => {
        this.setState({ country: countryName, countryId: countryId });
      }

      
  onChange = (e) => {
    this.setState({
      checked: e.target.checked,
    });
    if (e.target.checked) {
      this.setState({
        submit: false,
      });
    }
  };

  handleAccountTypeChange = (event) => {
    this.setState({
      userTypeId: event.target.value,
    });
  };

  
  handleUserRegistration = (e) => {
    e.preventDefault();
   console.log(this.props);
    const state = this.state;
    if (this.state.checked) {
      this.props.validateFields((err, values) => {
        if (!err) {
          this.props.registerUser({ ...values, country: state.country, userTypeId: state.userTypeId });
        }
      });
      this.setState({
        submit: false,
      });
    } else {
      this.setState({
        submit: true,
      });
    }
  };

    render(){
        let countries = [];

        countries = csc.getAllCountries().map((country) => {
          return (
            <Option onClick={this.onCountrySelect.bind(this, country.name, country.id)} key={country.name}>
              {' '}
              {country.name}{' '}
            </Option>
          );
        });

        
        return (
            <div className='registration-container'>
              <Row justify='space-around' style={{ padding: '3% 6.9% 3%' }} align='middle'>
                <Col>
                  <Row className='login-logo text-center' align='middle'>
                    {/* <img src={Logo} alt='logo' className='login-logo-image' style={{ height: '73px', width: '184px' }} /> */}
                    <LogoNew />
                  </Row>
                  <Row style={{ marginBottom: '0.4%', marginTop: '20px' }}>
                    <Row className='createAcc_label'>{UITextMessages.label.SELECT_ACCOUNT_TYPE}</Row>
                  </Row>
                  <Form>                  
                        <Form.Item className='select-option' rules={[{ required: true }]}>
                      <Radio.Group value={this.state.userTypeId} onChange={this.handleAccountTypeChange}>
                        <Radio value={userTypes.VC}>{UITextMessages.label.VIRTUAL_CLASSROOM}</Radio>
                        <Radio value={userTypes.EL}>{UITextMessages.label.RAPTIVITY_2020}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Form>
                  <Row style={{ marginBottom: '0.4%' }}>
                    <Row className='createAcc_label'>{UITextMessages.label.CREATE_AN_ACCOUNT}</Row>
                  </Row>
                  <Form>
                    <Form.Item>
                      {this.props.getFieldDecorator('name', {
                        rules: [
                          {                            
                            pattern: this.props.IsRegistration ? new RegExp('^[a-zA-Z][a-zA-Z ]+') : new RegExp('^[^-\\s][a-zA-Z ]*$'),
                            message: UITextMessages.message.ENTER_VALID_NAME,
                          },
                          {
                            required: true,
                            message: UITextMessages.message.ENTER_NAME,
                          },
                        ],
                      })(<Input placeholder={UITextMessages.label.NAME_ASTERISK} className='registration_name' prefix={<Icon component={User} />} />)}
                    </Form.Item>
                    <Form.Item>
                      {this.props.getFieldDecorator('email', {
                        rules: [
                          {
                            type: 'email',
                            message: UITextMessages.message.INVALID_EMAIL_ADDRESS,
                          },
                          {
                            required: true,
                            message: UITextMessages.message.ENTER_EMAIL_ADDRESS,
                          },
                        ],
                      })(<Input prefix={<Icon component={Mail} />} placeholder={UITextMessages.label.EMAIL_ADDRESS_ASTERISK} />)}
                    </Form.Item>
                    <Form.Item>
                      {this.props.getFieldDecorator('password', {
                        rules: [
                          {                            
                            pattern: new RegExp(
                              '(?!^[0-9~!@#$%^&*()_\\[+=,\\]\\{\\}\\\\.?|<>/;:\\\'\\`"-]*$)^([a-zA-Z0-9~!@#$%^&*()_\\[+=,\\]\\{\\}\\\\.?|<>/;:\\\'\\`"-]{6,})$'
                            ),
                            message: UITextMessages.message.INVALID_PASSWORD,
                          },
                          {
                            required: true,
                            message: UITextMessages.message.ENTER_PASSWORD,
                          },
                        ],
                      })(
                        <Input.Password
                          suffix={
                            // <Tooltip title='Minimum length is 6 alphanumeric characters'>
                            <Icon type='info-circle' style={{ color: 'rgba(0,0,0,.45)' }} />
                            // </Tooltip>
                          }
                          prefix={<Icon component={Lock} />}
                          type='password'
                          placeholder={UITextMessages.label.PASSWORD_ASTERISK}
                        />
                      )}
                    </Form.Item>
                    <Form.Item className='select-option'>
                      <Row>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1} className='icon-prefix'>
                          <Icon component={EarthGlobe} />
                        </Col>
                        <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                          <Select showSearch placeholder='Country'>
                            {countries}
                          </Select>
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item className='policy-checker' style={ !this.props.IsRegistration ? { marginBottom: '3%' } : null}>
                      <Checkbox checked={this.state.checked} disabled={this.state.disabled} onChange={this.onChange}>
                        {UITextMessages.message.GDPR_POLICY}{' '}
                        <a target='_blank' href='https://www.raptivity.com/Raptivity2020-EULA.html'>
                          {' '}
                          {UITextMessages.label.EULA}
                        </a>
                      </Checkbox>
                      {!this.state.submit ? '' : <span style={{ color: 'red' }}>{UITextMessages.message.PLEASE_ACCEPT_PRIVACY_POLICY}</span>}
                    </Form.Item>
                    <Form.Item style={this.props.IsRegistration ? { marginBottom: '0'} : {marginBottom: '3.2%'}}>
                      <Button
                        size='large'
                        type='primary'
                        htmlType='submit'
                        style={{ width: '100%' }}
                        className='register_button'
                        onClick={this.handleUserRegistration}
                      >
                        {UITextMessages.label.REGISTER}
                      </Button>
                    </Form.Item>
                  </Form>
                   {this.props.IsRegistration ? ( <Form.Item style={{ marginBottom: '5px' }}>
                    <Row align='center' style={{ textAlign: 'center' }}>
                      <span className='or-txt' style={{ color: '#929292', display: 'block', lineHeight: '1', margin: '4px 0' }}>
                        or
                      </span>                     
                    </Row>
                    <Row gutter={16} style={{ marginBottom: '2%' }}>
                      <SocialLogin userTypeId={this.state.userTypeId} />
                    </Row>
                  </Form.Item> ) : null}
                  {this.props.IsRegistration ? (<Row className='createAcc-link-label'>
                    <span>{UITextMessages.label.ALREADY_HAVE_AN_ACCOUNT}&nbsp;</span>
                    <NavLink to='/' className='createAcc-Link-Label'>
                      {UITextMessages.label.LOG_IN}
                    </NavLink>
                  </Row> ): null}
                </Col>
              </Row>
              {!this.props.IsRegistration ? ( <div style={{ display: 'none' }} id='paypalForm'>
          {parseInt(this.state.userTypeId) === parseInt(userTypes.VC)
            ? util.paypalForm(paypalFormTypes.VCPREMIUM)
            : util.paypalForm(paypalFormTypes.PREMIUM)}
        </div> ) : null}
            </div>
          );
    }
}
export default CommonRegistration;