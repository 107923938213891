import React, { useState } from "react";
import { Modal, message, Button, Divider } from "antd";
import { UITextMessages } from "../helpers/constants/uiTextMessages";
import referFriendPopupImg from "../assets/images/refer-a-friend-popup.png";
import ReCAPTCHA from "react-google-recaptcha";
import { sendReferralInvite } from '../containers/myProfile/profileActions';
import { useRef } from 'react';

import "../assets/less/createReferralCode.less";
import { resetWarningCache } from "prop-types";

const CreateReferralCode = ({
  visible,
  handleOnCancel,
  referralCode,
  dispatchProps
}) => {

  const [recaptchaValue , setRecaptchaValue] = useState('');
  const captchRef = useRef();

  const [disabled, setDisabled] = useState(true);

  const handleOnModalCancel = () => {
    handleOnCancel();
    setDisabled(true);
  };

  const success = () => {
    message.success("Invitation sent successfully");
  };

  const error = () => {
    message.error("Please enter valid email");
  };

  const multipleEmailErrormsg =()=>{
    message.warning("Only one email is allowed.");
  }
  const referralInviteHandler = () => {
    setDisabled(false);
  };

  const warning =()=>{
    message.warning("Captcha verification required.")
  }

  const resetRecaptchaValue = () => {
    // Reset the recaptchaValue to null or whatever initial value it should have
    setRecaptchaValue('');
};

  const inviteEmailHandler = () => {
    const { dispatch } = dispatchProps;
    const email = document.getElementById("email").value.trim(); // Retrieve and trim the email input

    if (email === '') {
        error(); // Display error message if email field is empty
    } else if (email.includes(';') || email.includes(',')) {
        // Display error message if email contains semicolons or commas
        multipleEmailErrormsg();
    } else if (recaptchaValue === '' || recaptchaValue === null) {
        warning(); // Display warning message if reCAPTCHA value is missing
    } else {
        dispatch(sendReferralInvite(referralCode, email, recaptchaValue)) // Send referral invite for the single email
            .then((resp) => {
                document.getElementById('spinner').hidden = true; // Hide spinner after sending invite
                resetRecaptchaValue();
            });
        handleOnModalCancel(); // Handle modal cancelation
        success(); // Display success message
    }
};

  
  const Captchalogic =value=>{
    console.log(value);
    setRecaptchaValue(value)
  }

  return (
    <Modal
      visible={visible}
      onCancel={handleOnModalCancel}
      zIndex={"9999"}
      centered={true}
      footer={false}
      // closable={false}
    >
      <div className="invite-first-slide">
        <img className="refer-popup-img" src={referFriendPopupImg} alt="" />

        {disabled ? (
          <div>
            <h2 className="refer-friends">
              {UITextMessages.label.REFER_EARN_EXPERIENCE}
            </h2>
            <h4 className="refer-content">
              {UITextMessages.label.REFER_FRIEND_CONTENT}
            </h4>
            <Button
              className="referral-code-copy"
              onClick={referralInviteHandler}
            >
              {UITextMessages.label.REFER_NOW}
            </Button>
          </div>
        ) : (
          <div>
            <h2 className="invite-your-friends">
              {UITextMessages.label.REFERRAL_CODE_TITLE}
            </h2>
              <label className="invite-to" htmlFor="email">
              To:
            </label>
            <input
              className="invite-email"
              type="text"
              id="email"
              placeHolder={UITextMessages.label.REFER_EMAIL_PLACEHOLDER}
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            />
            <Divider />
            <h4 className="invite-page-content" id="invite-page-content">
              Hi,
              <br />
              <br />
              {UITextMessages.label.EMAIL_CONTENT_FIRST}
              <span className="referral-code">
                {referralCode}
              </span>
              {UITextMessages.label.EMAIL_CONTENT_SECOND}
            </h4>
            <br />
            <div style = {{marginLeft:"80px"}}>
            <ReCAPTCHA 
            sitekey='6LfJPaEpAAAAAGSf8O-x5swfqRDq5eupQRZsCnhA' 
            onChange={Captchalogic} 
            ref={captchRef} />
            </div>
            <div style={{ textAlign: "center" }}>
              <Button
                className="referral-code-copy"
                onClick={inviteEmailHandler}
              >
                {UITextMessages.label.INVITE}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CreateReferralCode;
