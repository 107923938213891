import React from 'react';
import { Layout, Row, Col } from 'antd';

const { Content } = Layout;

export default props => {
  return (
    <Row justify='space-around' type='flex'>
      <Col span={24}>
        <Content style={{ minHeight: '30em' }}>{props.children}</Content>
      </Col>
    </Row>
  );
};
