import * as actionTypes from './accountActionTypes';
const accountReducers = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ACCOUNT_DETAILS_SUCCESS:
      return { ...state, accountInfo: action.payload.accountDetails };
    case actionTypes.FETCH_ACCOUNT_DETAILS_ERROR:
      return { ...state, accountInfo: action.payload };
    default:
      return state;
  }
};
export default accountReducers;
