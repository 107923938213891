import * as actionTypes from './tokenAuthenticationActionTypes';

import messageType from '../../helpers/enums/messageTypes';
import util from '../../helpers/utils/util';
import httpVerb from '../../helpers/enums/httpVerbs';
let apiParams = {};
export function checkToken() {
  return function(dispatch) {
    let headers = {
      'Content-Type': 'application/json'
    };
    let username = sessionStorage.getItem('email');
    let token = sessionStorage.getItem('token');
    apiParams = { username, token };
    return util
      .axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/checkToken`, apiParams, { headers })
      .then(response => {
        dispatch({
          type: actionTypes.TOKEN_ALREADY_EXISTS,
          payload: { tokenExists: false }
        });
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.alreadyLoggedIn) {
          dispatch({
            type: actionTypes.TOKEN_ALREADY_EXISTS,
            payload: { tokenExists: error.response.data.alreadyLoggedIn }
          });
        }
      });
  };
}
export function logoutCurrentSession(username, token) {
  return function(dispatch) {
    let headers = {
      'Content-Type': 'application/json'
    };
    // util.toggleSpinner(false);

    apiParams = { username, token };

    return util
      .axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/logoutCurrentSession`, apiParams, { headers })
      .then(response => {
        dispatch({
          type: actionTypes.TOKEN_ALREADY_EXISTS,
          payload: { tokenExists: false }
        });
        // util.toggleSpinner(true);
      })
      .catch(error => {
        // util.toggleSpinner(true);
      });
  };
}

export function deleteOtherSession(username, token) {
  return function(dispatch) {
    let headers = {
      'Content-Type': 'application/json'
    };
    // util.toggleSpinner(false);

    apiParams = { username, token };

    return util
      .axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/deleteOtherSession`, apiParams, { headers })
      .then(response => {
        dispatch({
          type: actionTypes.TOKEN_ALREADY_EXISTS,
          payload: { tokenExists: false }
        });
        // util.toggleSpinner(true);
      })
      .catch(error => {
        // util.toggleSpinner(true);
      });
  };
}

export function tokenExists(value) {
  return function(dispatch) {
    dispatch({
      type: actionTypes.TOKEN_ALREADY_EXISTS,
      payload: { tokenExists: value }
    });
  };
}
