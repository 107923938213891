import React, { useEffect } from 'react';
import { Modal, Button } from 'antd';
import { UITextMessages } from '../../helpers/constants/uiTextMessages';
import { connect, useDispatch } from 'react-redux';
import * as actions from './tokenAuthenticationAction';
import { withRouter } from 'react-router-dom';

const TokenAuthentication = props => {
  const dispatch = useDispatch();
  const deleteOtherSession = () => {
    let username = sessionStorage.getItem('email');
    let token = sessionStorage.getItem('token');

    dispatch(actions.deleteOtherSession(username, token)).then(() => {
      localStorage.removeItem('updateToken');
      sessionStorage.removeItem('updateToken');

      localStorage.setItem('updateToken', 'set');
      sessionStorage.setItem('updateToken', 'set');

      if (props.isAuthenticated) window.location.reload(false);
    });
  };

  const handleLogoutCancel = async event => {
    await dispatch(actions.tokenExists(false));
    props.handleLogout();
  };

  const logoutCurrentSession = () => {
    dispatch(actions.tokenExists(false));
    let username = sessionStorage.getItem('email');
    let token = sessionStorage.getItem('token');

    dispatch(actions.logoutCurrentSession(username, token)).then(props.handleLogout());
    // if (window.location.pathname.includes('builder')) {
    //   window.location.reload(false);
    // }
  };

  const multipleSessionModal = () => {
    return (
      <Modal
        visible={true}
        title={UITextMessages.label.MULTIPLE_SESSIONS}
        maskClosable={false}
        onCancel={handleLogoutCancel}
        closable={true}
        className="multipleSession-popup"
        footer={[
          <>
            <div className='button-group session-buttons'>
            <Button type='primary' className='session-twice-login-cancel' onClick={() => handleLogoutCancel()}>
                {UITextMessages.label.CANCEL}
              </Button>

              <Button type='primary' className='session-twice-login-ok' onClick={() => deleteOtherSession()}>
                {UITextMessages.label.OK}
              </Button>
            </div>
          </>
        ]}
        zIndex={'9999'}
        centered={true}
      >
        <div>
          <h4 >{UITextMessages.message.LOGOUT_PREVIOUS_SESSION}<br/>{UITextMessages.message.LOGOUT_PREVIOUS_SESSION_Text}</h4>
        </div>
      </Modal>
    );
  };
  const forceLogout = () => {
    logoutCurrentSession();
    return '';
  };

  return props.tokenExists.tokenExists && props.isAuthenticated
    ? props.tokenExists.tokenExists == 'logout'
      ? forceLogout()
      : multipleSessionModal()
    : '';
};

const mapStateToProps = state => {
  let tokenExists = false;
  if (state.tokenAuthenticationReducer && state.tokenAuthenticationReducer.tokenStatus) {
    tokenExists = state.tokenAuthenticationReducer.tokenStatus;
  }
  return { tokenExists };
};
export default connect(mapStateToProps)(withRouter(TokenAuthentication));
