import React, { Component } from 'react';
import { Row, Col, Form, Icon, Input, Button, Select, Radio, Divider, Checkbox } from 'antd';
import { NavLink, Link } from 'react-router-dom';
import SocialLogin from '../login/socialLogin/SocialLogin';
import { connect } from 'react-redux';
import * as actions from './proRegistrationActions';
import Features from '../../components/Features';
import csc from 'country-state-city';
import { UITextMessages } from '../../helpers/constants/uiTextMessages';
import userTypes from '../../helpers/enums/userTypes';
import CommonRegistration from '../registration/CommonRegistration';

//Image import
import { ReactComponent as EarthGlobe } from '../../assets/images/earth-globe.svg';
import { ReactComponent as OrLOGO } from '../../assets/images/or.svg';
import { ReactComponent as RegisteredSuccess } from '../../assets/images/register-sucessful.svg';
import { ReactComponent as Mail } from '../../assets/images/mail.svg';
import { ReactComponent as User } from '../../assets/images/user.svg';
import { ReactComponent as Lock } from '../../assets/images/pass.svg';
import { ReactComponent as LogoNew } from '../../assets/images/logo_new.svg';
import util from '../../helpers/utils/util';
import paypalFormTypes from '../../helpers/enums/paypalFormTypes';
import { Scrollbars } from 'react-custom-scrollbars';
//Less Import
import './proRegistration.less';
import ReactGA from 'react-ga';
const { Option } = Select;

class PremiumRegistration extends Component {
  componentDidMount() {
    ReactGA.initialize('UA-1052826-1');
    ReactGA.pageview(window.location.pathname);
  }
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      country: '',
      checked: true,
      disabled: false,
      submit: false,
      userTypeId: userTypes.EL,
    };
  }
  toggleChecked = () => {
    this.setState({ checked: !this.state.checked });
  };

  toggleDisable = () => {
    this.setState({ disabled: !this.state.disabled });
  };

  onChange = (e) => {
    this.setState({
      checked: e.target.checked,
    });
    if (e.target.checked) {
      this.setState({
        submit: false,
      });
    }
  };
  onCountrySelect = (countryName, countryId) => {
    this.setState({ country: countryName, countryId: countryId });
  };

  handleUserRegistration = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const state = this.state;
    if (this.state.checked) {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.dispatch(actions.EmployeeRegistration({ ...values, country: state.country, userTypeId: state.userTypeId })).then((data) => {
            data.status === 200 && this.handlePayPalRedirection();
          });
        }
      });
      this.setState({
        submit: false,
      });
    } else {
      this.setState({
        submit: true,
      });
    }
  };
  handleAccountTypeChange = (event) => {
    event.stopPropagation();
    this.setState({
      userTypeId: event.target.value,
    });
  };
  getRegistrationSection = (countries) => {
    const { getFieldDecorator } = this.props.form;
    if (this.props.registrationInfo.status === 200) {
      sessionStorage.setItem('email', this.props.registrationInfo.email);
    }
    return (
      <CommonRegistration IsRegistration = {false} getFieldDecorator = {getFieldDecorator} handleUserRegistration={this.handleUserRegistration} validateFields={this.props.form.validateFields} registerUser={this.props.registerUser}/>
  )
  };

  getFeatureSectionView = () => {
    return (
      <div className='feature-inner-container'>
        <Features userTypeId={this.state.userTypeId} />
      </div>
    );
  };

  handlePayPalRedirection = () => {
    setTimeout(() => {
      let paypalForm = document.getElementById('paypalForm');
      let paypalButton = paypalForm.getElementsByTagName('button');
      paypalButton[0].click();
    }, 900);
  };

  render() {
    let countries = [];

    countries = csc.getAllCountries().map((country) => {
      return (
        <Option onClick={this.onCountrySelect.bind(this, country.name, country.id)} key={country.name}>
          {' '}
          {country.name}{' '}
        </Option>
      );
    });

    return (
      <Scrollbars className='scroller-main-wrapper login-scroller' style={{ height: 'calc(100vh - 49px)' }}>
        <Row className='wrapper-container'>
          <Row type='flex' justify='center' className='login-main-container'>
            <Col style={{ backgroundColor: 'white' }} lg={12} xl={12} className='register-container'>
              {this.getRegistrationSection(countries)}
            </Col>
            <Col lg={12} xl={12} className='feature_container feature_container_background'>
              {this.getFeatureSectionView()}
            </Col>
          </Row>
        </Row>
      </Scrollbars>
    );
  }
}
const mapStateToProps = (state) => {
  let registrationInfo = '';
  if (state.proRegistrationReducer.registrationInfo !== undefined) {
    registrationInfo = state.proRegistrationReducer;
    return registrationInfo;
  } else {
    return { registrationInfo };
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (user) => {
      dispatch(actions.EmployeeRegistration(user));
    },
  };
};

export default connect(mapStateToProps)(Form.create({ name: 'registration' })(PremiumRegistration));
