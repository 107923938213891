export const FETCH_DEFAULT_BUILDER_SETTING = 'FETCH_DEFAULT_BUILDER_SETTING';
export const FETCH_DEFAULT_BUILDER_SETTING_INPROGRESS = 'FETCH_DEFAULT_BUILDER_SETTING_INPROGRESS';
export const FETCH_PREVIEW_SETTING_INPROGRESS = 'FETCH_PREVIEW_SETTING_INPROGRESS';
export const FETCH_DEFAULT_BUILDER_SETTING_SUCCESS = 'FETCH_DEFAULT_BUILDER_SETTING_SUCCESS';
export const FETCH_DEFAULT_BUILDER_SETTING_ERROR = 'FETCH_DEFAULT_BUILDER_SETTING_ERROR';
export const UPDATE_LAYOUT_SETTING = 'UPDATE_LAYOUT_SETTING';
export const UPDATE_BACKGROUND_SETTING = 'UPDATE_BACKGROUND_SETTING';
export const SAVE_BUILDER_SUCCESS = 'SAVE_BUILDER_SUCCESS';
export const SAVE_BUILDER_ERROR = 'SAVE_BUILDER_ERROR';
export const UPDATE_USER_ASSET = 'UPDATE_USER_ASSET';
export const UPDATE_USER_ASSET_ERROR = 'UPDATE_USER_ASSET_ERROR';
export const UPDATE_DEFAULT_ASSET = 'UPDATE_DEFAULT_ASSET';
export const UPDATE_BG_IMAGE = 'UPDATE_BG_IMAGE';
export const UPDATE_BG_COLOR = 'UPDATE_BG_COLOR';
export const SET_CURRENT_SLIDE = 'SET_CURRENT_SLIDE';
export const UPDATE_SLIDE_VIDEO = 'UPDATE_SLIDE_VIDEO';
export const UPDATE_SLIDE_ITEM = 'UPDATE_SLIDE_ITEM';
export const UPDATE_SLIDES_DATA = 'UPDATE_SLIDES_DATA';
export const UPDATE_INTERACTION_TITLE = 'UPDATE_INTERACTION_TITLE';
export const UPDATE_SELECTED_AUDIO = 'UPDATE_SELECTED_AUDIO';
export const REMOVE_SELECTED_AUDIO = 'REMOVE_SELECTED_AUDIO';
export const UPDATE_AUDIO_MODE = 'UPDATE_AUDIO_MODE';
export const UPDATE_ACROSS_ALL_AUDIO_FLAG = 'UPDATE_ACROSS_ALL_AUDIO_FLAG';
export const UPDATE_GLOBAL_SETTING = 'UPDATE_GLOBAL_SETTING';
export const UPDATE_TIME_INTERVAL_FONT_SETTING = 'UPDATE_TIME_INTERVAL_FONT_SETTING';
export const UPDATE_INTRODUCTION_SETTING = 'UPDATE_INTRODUCTION_SETTING';
export const UPDATE_CLOSURE_SETTING = 'UPDATE_CLOSURE_SETTING';
export const UPDATE_TOGGLE_FLAG_SETTING = 'UPDATE_TOGGLE_FLAG_SETTING';
export const UPDATE_USER_FONTLIST = 'UPDATE_USER_FONTLIST';
export const UPDATE_USED_FONT_LIST = 'UPDATE_USED_FONT_LIST';
export const UPDATE_HOTSPOT_DATA = 'UPDATE_HOTSPOT_DATA';
export const UPDATE_PANORAMA_HOTSPOT_DATA = 'UPDATE_PANORAMA_HOTSPOT_DATA';
export const UPDATE_INTRODUCTION_CLOSURE_DATA = 'UPDATE_INTRODUCTION_CLOSURE_DATA';
export const CLEAR_BUILDER_SETTING = 'CLEAR_BUILDER_SETTING';
export const RESET_UNSAVED_CHANGES = 'RESET_UNSAVED_CHANGES';
export const SET_UNSAVED_CHANGES = 'SET_UNSAVED_CHANGES';
export const ADD_NEW_TOPICS = 'ADD_NEW_TOPICS';
export const ADD_NEW_TOPICS_LAYOUT = 'ADD_NEW_TOPICS_LAYOUT';
export const ADD_NEW_SLIDE_DATA = 'ADD_NEW_SLIDE_DATA';
export const ADD_NEW_TOPIC_BACKGROUND = 'ADD_NEW_TOPIC_BACKGROUND';
export const UPDATE_SIMPLE_INTRODUCTION_SLIDE = 'UPDATE_SIMPLE_INTRODUCTION_SLIDE';
export const UPDATE_CURRENT_HOTSPOT_DATA = 'UPDATE_CURRENT_HOTSPOT_DATA';
export const UPDATE_GAMEBASED_FLAG_TOGGLE = 'UPDATE_GAMEBASED_FLAG_TOGGLE';
export const CHANGE_TO_CURRENT_INTERACTION_SETTING = 'CHANGE_TO_CURRENT_INTERACTION_SETTING';
export const UPDATE_MULTI_INTERACTION_SETTING = 'UPDATE_MULTI_INTERACTION_SETTING';
export const EDIT_USER_ASSET_SUCCESS = 'EDIT_USER_ASSET_SUCCESS';
export const EDIT_USER_ASSET_ERROR = 'EDIT_USER_ASSET_ERROR';