import * as actionTypes from './publishActionTypes';

const previewReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PUBLISH_IN_PROGRESS:
      return { publishStatus: actionTypes.PUBLISH_IN_PROGRESS };
    case actionTypes.PUBLISH_SUCCESS:
      return { publishStatus: actionTypes.PUBLISH_SUCCESS, publishedDetails: action.payload.data };
    case actionTypes.PUBLISH_ERROR:
      return { publishStatus: actionTypes.PUBLISH_ERROR };
    case actionTypes.DOWNLOAD_SUCCESS:
      return { ...state, publishedDetails: { ...state.publishedDetails } };
    case actionTypes.RESET_STATUS:
      return { ...state, publishStatus: '' };
    default:
      return state;
  }
};
export default previewReducer;
