import * as actionTypes from './profileActionTypes';
import { TOKEN_ALREADY_EXISTS } from '../tokenAuthentication/tokenAuthenticationActionTypes';

import messageType from '../../helpers/enums/messageTypes';
import util from '../../helpers/utils/util';
import { FETCH_ACCOUNT_DETAILS_SUCCESS } from '../myAccount/accountActionTypes';
import httpVerb from '../../helpers/enums/httpVerbs';
let apiParams = {};

const jsonwebtoken  = require('jsonwebtoken');
const JWT_API_SECRET= '3141245367';

const encryptData = (user) =>{
const token = jsonwebtoken.sign({ email: user.email },JWT_API_SECRET);
return token;
  
  }
export function getAllUserInfo() {
  return function (dispatch) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + sessionStorage.getItem('token'),
      Username: sessionStorage.getItem('email'),
    };
    const user = {
      email: sessionStorage.getItem('email'),
    };
    util.toggleSpinner(false);
    apiParams = { user };
    const token = encryptData(user);
    return util
      .axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/profile/info`,{token}, {
        headers: headers,
      })
      .then((res) => {        
        localStorage.setItem('expiryDate', res.data.user.accountDetails.expiryDate);
        localStorage.setItem('currentPlan', res.data.user.accountDetails.currentPlan);
        localStorage.setItem('userCount', res.data.user.accountDetails.userCount);
        localStorage.setItem('usedStorageSpaceInBytes', res.data.user.accountDetails.usedStorageSpaceInBytes);
        localStorage.setItem('storageSpaceInBytes', res.data.user.accountDetails.storageSpaceInBytes);
        localStorage.setItem('occupation',res.data.user.occupation);
        localStorage.setItem('country',res.data.user.country);
        localStorage.setItem('encId',res.data.user.accountDetails.encId);
        sessionStorage.setItem('expiryDate', res.data.user.accountDetails.expiryDate);
        sessionStorage.setItem('currentPlan', res.data.user.accountDetails.currentPlan);
        sessionStorage.setItem('userCount', res.data.user.accountDetails.userCount);
        sessionStorage.setItem('usedStorageSpaceInBytes', res.data.user.accountDetails.usedStorageSpaceInBytes);
        sessionStorage.setItem('storageSpaceInBytes', res.data.user.accountDetails.storageSpaceInBytes);
        sessionStorage.setItem('userId', res.data.user.userId);
        sessionStorage.setItem('userTypeId', res.data.user.userTypeId);
        sessionStorage.setItem('encId',res.data.user.accountDetails.encId);

        dispatch({
          type: actionTypes.FETCH_USER_SUCCESS,
          payload: res.data,
        });

        dispatch({
          type: FETCH_ACCOUNT_DETAILS_SUCCESS,
          payload: res.data.user,
        });
        if (!window.location.href.includes('mywork')) {
          util.toggleSpinner(true);
        }
        return res.data;
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.alreadyLoggedIn) {
          dispatch({
            type: TOKEN_ALREADY_EXISTS,
            payload: { tokenExists: err.response.data.alreadyLoggedIn },
          });
        }
        util.toggleSpinner(true);
      });
  };
}

export function saveUserInfo(user) {
  return function (dispatch) {
    const data = new FormData();
    data.append('user', JSON.stringify(user));

    const headers = {
      'Content-Type': `multipart/form-data`,
      Authorization: 'bearer ' + sessionStorage.getItem('token'),
      Username: sessionStorage.getItem('email'),
    };
    apiParams = data;
    
    return util
      .axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/auth/profile?email=${user.email || sessionStorage.getItem('email')}`, apiParams, {
        headers: headers,
      })
      .then((response) => {        
        localStorage.setItem('country',response.data.country);
        localStorage.setItem('occupation',response.data.occupation);
        if (response.data.error) {
          dispatch({
            type: actionTypes.UPDATE_PROFILE_ERROR,
            payload: response.data,
          });
          util.displayMessage(messageType.ERROR, response.data.message);
        } else {
          dispatch({
            type: actionTypes.UPDATE_PROFILE_SUCCESS,
            payload: response.data,
          });
          util.displayMessage(messageType.SUCCESS, response.data.message);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.alreadyLoggedIn) {
          dispatch({
            type: TOKEN_ALREADY_EXISTS,
            payload: { tokenExists: error.response.data.alreadyLoggedIn },
          });
        } else {
          util.displayMessage(messageType.ERROR, error);
        }
      });
  };
}

export function uploadProfilePic(data) {
  return async function (dispatch) {
    const email = sessionStorage.getItem('email');
    const userId = data.get('userId');
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `bearer ${sessionStorage.getItem('token')}`,
      Username: email
    }
    dispatch({ 
      type: actionTypes.UPLOAD_PROFILE_PIC, 
      payload: { loading: !0 } 
    });
    data.delete('userId');
    try {
      const profileResponse = await util.axios(
        httpVerb.PUT, 
        `${process.env.REACT_APP_API_URL}/api/auth/upload/${userId}/pic`,
        data,
        { headers }
      );
      if(profileResponse.data.error) {
        dispatch({
          type: actionTypes.UPLOAD_PROFILE_PIC,
          payload: { loading: !0 } 
        });
        util.displayMessage(messageType.ERROR, profileResponse.data.message);
        return 
      }
      dispatch({
        type: actionTypes.UPLOAD_PROFILE_PIC,
        payload: { loading: !1, data: profileResponse.data }
      });
    }catch(uploadErr) {
      dispatch({
        type: actionTypes.UPLOAD_PROFILE_PIC,
        payload: { loading: !1 }
      });
      util.displayMessage(messageType.ERROR, uploadErr.message);
    } 
  }
}

export function storeReferalCode (refCode){
  return function (dispatch) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'bearer ' + sessionStorage.getItem('token'),
    Username: sessionStorage.getItem('email'),
  };
  const params = {
    referralCode:refCode,
    email: sessionStorage.getItem("email"),
  };
  const data = new FormData();
  data.append('user', JSON.stringify(params));
  return util
  .axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/auth/profile?email=${params.email}`, data, {
    headers: headers,
  })
  .then((response) => {        
    util.toggleSpinner(false);
    if (response.data.error) {
      util.displayMessage(messageType.ERROR, response.data.error);
    }
    document.getElementById('spinner').hidden = true;
  })
  .catch((error) => {
    util.toggleSpinner(false);
    if (error.response && error.response.data && error.response.data.alreadyLoggedIn) {
      util.displayMessage(messageType.ERROR, error.response.data);
    } else {
      util.displayMessage(messageType.ERROR, error);
    }
  });
}
}

export function sendReferralInvite (refCode, toEmail , recaptchaValue){
  return function (dispatch) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'bearer ' + sessionStorage.getItem('token'),
    Username: localStorage.getItem('email'),
  };
  const params = {
    referralCode:refCode,
    emails: toEmail,
    recaptcha: recaptchaValue,
  };
  console.log("Params", params);
  return util
  .axios(httpVerb.GET, `${process.env.REACT_APP_API_URL}/api/sendReferralInvite?referralCode=${refCode}&emails=${toEmail.toString()}&userEmail=${sessionStorage.getItem('token')}&recaptcha=${recaptchaValue}`,{
    headers: headers,
  })
  .then((response) => {        
    util.toggleSpinner(false);
    if (response.data.error) {
      // util.displayMessage(messageType.ERROR, response.data.error);
    }
    document.getElementById('spinner').hidden = true;
  })
  .catch((error) => {
    util.toggleSpinner(false);
    if (error.response && error.response.data && error.response.data.alreadyLoggedIn) {
      // util.displayMessage(messageType.ERROR, error.response.data);
    } else {
      // util.displayMessage(messageType.ERROR, error);
    }
  });
}
}