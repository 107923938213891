import DeviceDetector from 'device-detector-js';
import httpVerb from '../../../helpers/enums/httpVerbs';
import util from '../../../helpers/utils/util';

let apiParams = {};
export const loginWithFacebook = (loginType, userTypeId) => {
  var headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  };
  const deviceDetector = new DeviceDetector();
  const deviceInfo = deviceDetector.parse(navigator.userAgent);
  apiParams = { loginType, deviceInfo, userTypeId };

  var resp = util.axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/facebook/login`, apiParams, { headers });

  return resp;
};
