import * as actionTypes from './emailVerificationActionTypes';

const emailVerificationReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.Email_Verification_SUCCESS:
      return { ...state, verificationInfo: action.payload };

    default:
      return state;
  }
};
export default emailVerificationReducer;
