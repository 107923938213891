import React from "react";
import { message as AntDMessage, notification as AntDNotification } from "antd";
import paypalFormTypes from "../enums/paypalFormTypes";
import xliffVersionType from "../enums/xliffVersionTypes";
import httpVerb from "../enums/httpVerbs";
import * as _ from "lodash";
import jsToXliff12 from "xliff/jsToXliff12";
import js2xliff from "xliff/js2xliff";
import xliff12ToJs from "xliff/xliff12ToJs";
import xliff2js from "xliff/xliff2js";
import { local } from "d3";
import axios from "axios";
import convert from "xml-js";
import { UITextMessages } from "../constants/uiTextMessages";

AntDMessage.config({
  duration: 3,
  maxCount: 1,
});

AntDNotification.config({
  placement: "topRight",
  top: 60,
  style: { zIndex: 1000 },
});

const util = () => {
  return {
    axios: (verb, apiUrl, data, headers) => {
      switch (verb) {
        case httpVerb.POST:
          return axios.post(apiUrl, data, headers);
          break;
        case httpVerb.GET:
          return axios.get(apiUrl, headers);
          break;
        case httpVerb.PUT:
          return axios.put(apiUrl, data, headers);
      }
    },

    displayMessage: (type, message, interval = 3) => {
      !_.isEmpty(message) && AntDMessage[type](message, interval);
    },

    displayNotification: (
      type,
      message,
      description,
      duration = 0,
      key,
      icon,
      placement,
      btn
    ) => {
      AntDNotification[type]({
        message,
        description,
        duration,
        key,
        icon,
        placement,
        btn,
      });
    },

    getAssetList: (defaultAsset, userAsset, assetId) => {
      let allAsset = [];
      let newAllAsset = [];
      if (defaultAsset !== null && userAsset !== null) {
        allAsset = defaultAsset.concat(userAsset);
      } else if (defaultAsset !== null || userAsset === null) {
        allAsset = defaultAsset;
      } else if (defaultAsset === null || userAsset !== null) {
        allAsset = userAsset;
      } else {
        return allAsset;
      }

      allAsset.map((asset, index) => {
        if (asset.assettypeid == assetId) {
          newAllAsset.push(asset);
        }
      });

      return newAllAsset;
    },

    paypalForm: (form, email, btnText = "Subscribe", amount = 0) => {
      let paypalForm = "";
      let emailId = sessionStorage.getItem("email");
      if (!emailId) {
        emailId = email;
      }
      switch (form) {
        case paypalFormTypes.VCQUARTERLY:
          paypalForm = (
            <form
              target="_blank"
              action="https://www.paypal.com/cgi-bin/websc"
              method="post"
            >
              <fieldset>
                <input type="hidden" name="add" value="1" />
                <input type="hidden" name="cmd" value="_s-xclick" />
                {/* <input type='hidden' name='hosted_button_id' value='WGQUPLLGKRACC' /> */}
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="UNUY7YVK5YP88"
                />
                <input
                  type="hidden"
                  name="business"
                  value="r_javalagi2003@yahoo.com"
                />
                <input
                  type="hidden"
                  name="custom"
                  value={`Raptivity2020_${emailId}_VCQUARTERLY`}
                />
                <button
                  type="submit"
                  class="ant-btn ant-btn-primary"
                  name="submit"
                  value="Submit"
                  data-item=""
                >
                  Switch to Quarterly
                </button>
              </fieldset>
            </form>
          );
          break;
        case paypalFormTypes.VCPREMIUM:
          paypalForm = (
            <form
              target="_blank"
              action="https://www.paypal.com/cgi-bin/websc"
              method="post"
            >
              <fieldset>
                <input type="hidden" name="add" value="1" />
                <input type="hidden" name="cmd" value="_s-xclick" />
                {/* <input type='hidden' name='hosted_button_id' value='787XCLRN4F8SQ' /> */}
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="RXQWFEG6LQTFY"
                />
                <input
                  type="hidden"
                  name="business"
                  value="r_javalagi2003@yahoo.com"
                />
                <input
                  type="hidden"
                  name="custom"
                  value={`Raptivity2020_${emailId}`}
                />
                <button
                  type="submit"
                  class="ant-btn ant-btn-primary"
                  name="submit"
                  value="Submit"
                  data-item=""
                >
                  Switch to Annual
                </button>
              </fieldset>
            </form>
          );
          break;
        case paypalFormTypes.PREMIUM:
          paypalForm = (
            // <form target='_blank' action='https://www.paypal.com/cgi-bin/webscr' method='post'>
            //   <fieldset>
            //     <input type='hidden' name='add' value='1' />
            //     <input type='hidden' name='cmd' value='_s-xclick' />
            //     {/* <input type='hidden' name='hosted_button_id' value='MB56HBZQ55XH2' /> */}
            //     {/* <input type='hidden' name='hosted_button_id' value='ZRT9TCJMGUZGN' /> */}
            //     <input type='hidden' name='hosted_button_id' value='52NYWYBGQ565S' />
            //     {/* <input type='hidden' name='hosted_button_id' value='HZZM2LQEL6A4N' /> */}
            //     <input type='hidden' name='business' value='r_javalagi2003@yahoo.com' />
            //     <input type='hidden' name='custom' value={`Raptivity2020_${emailId}`} />
            //     <input type='hidden' name='return' value=' https://www.raptivity.com/Raptivity2020-PaymentCompletion.html' />
            // <button type='button' onClick={() => window.location.href = `${process.env.REACT_APP_REDIRECTION_URL}?plan=year&from=product`} class='ant-btn ant-btn-primary choose-plan-btn' name='submit' value='Submit' data-item=''>

            <button
              type="button"
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_REDIRECTION_URL}?plan=year&from=product`)
              }
              // class="choose-plan-btn ${renewBtn} "
              class={
                "choose-plan-btn " +
                (btnText == "Renew Now" ? "renew-btn-now" : "")
              }
              name="submit"
              value="Submit"
              data-item=""
            >
              {btnText}
            </button>
            //     <button type='submit' class='ant-btn ant-btn-primary' name='submit' value='Submit' data-item=''>
            //     Switch to Annual
            //     </button>
            //   </fieldset>
            // </form>
          );
          break;
        case paypalFormTypes.BTSONE:
          paypalForm = (
            <form
              target="_blank"
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
            >
              <fieldset>
                <input type="hidden" name="add" value="1" />
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="ZRT9TCJMGUZGN"
                />
                <input
                  type="hidden"
                  name="business"
                  value="r_javalagi2003@yahoo.com"
                />
                <input
                  type="hidden"
                  name="custom"
                  value={`Raptivity2020_${emailId}`}
                />
                <input
                  type="hidden"
                  name="return"
                  value=" https://www.raptivity.com/Raptivity2020-PaymentCompletion.html"
                />
                <button
                  type="submit"
                  class="ant-btn ant-btn-primary"
                  name="submit"
                  value="Submit"
                  data-item=""
                >
                  Buy Now
                </button>
              </fieldset>
            </form>
          );
          break;
        case paypalFormTypes.BTSFIVE:
          paypalForm = (
            <form
              target="_blank"
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
            >
              <fieldset>
                <input type="hidden" name="add" value="1" />
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="WMY3M37M4FK3A"
                />
                {/* <input type='hidden' name='hosted_button_id' value='XVTT486HB3ZZ6' /> */}
                <input
                  type="hidden"
                  name="business"
                  value="r_javalagi2003@yahoo.com"
                />
                <input
                  type="hidden"
                  name="custom"
                  value={`Raptivity2020_${emailId}`}
                />
                <input
                  type="hidden"
                  name="return"
                  value=" https://www.raptivity.com/Raptivity2020-PaymentCompletion.html"
                />
                <button
                  type="submit"
                  class="ant-btn ant-btn-primary"
                  name="submit"
                  value="Submit"
                  data-item=""
                >
                  Buy Now
                </button>
              </fieldset>
            </form>
          );
          break;
        case paypalFormTypes.BTSTEN:
          paypalForm = (
            <form
              target="_blank"
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
            >
              <fieldset>
                <input type="hidden" name="add" value="1" />
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="5HXTZX86D5SQG"
                />
                <input
                  type="hidden"
                  name="business"
                  value="r_javalagi2003@yahoo.com"
                />
                <input
                  type="hidden"
                  name="custom"
                  value={`Raptivity2020_${emailId}`}
                />
                <input
                  type="hidden"
                  name="return"
                  value=" https://www.raptivity.com/Raptivity2020-PaymentCompletion.html"
                />
                <button
                  type="submit"
                  class="ant-btn ant-btn-primary"
                  name="submit"
                  value="Submit"
                  data-item=""
                >
                  Buy Now
                </button>
              </fieldset>
            </form>
          );
          break;
        case paypalFormTypes.QUARTERLY:
          paypalForm = (
            //   <form target='_blank' action='https://www.paypal.com/cgi-bin/webscr' method='post'>
            //     <fieldset>
            //       <input type='hidden' name='add' value='1' />
            //       <input type='hidden' name='cmd' value='_s-xclick' />
            //       {/* <input type='hidden' name='hosted_button_id' value='SSXZQRCCMNGCE' /> */}
            //       {/* <input type='hidden' name='hosted_button_id' value='7ZMMUNXUY9GLC' /> */}
            //       <input type='hidden' name='hosted_button_id' value='LHZYXTP2X82L4' />
            //       <input type='hidden' name='business' value='r_javalagi2003@yahoo.com' />
            //       <input type='hidden' name='custom' value={`Raptivity2020_${emailId}_QUARTERLY`} />
            //       <input type='hidden' name='return' value=' https://www.raptivity.com/Raptivity2020-PaymentCompletion.html' />
            // <button type='button' onClick={() => window.location.href = `${process.env.REACT_APP_REDIRECTION_URL}?plan=quarter&from=product`} class='ant-btn ant-btn-primary choose-plan-btn' name='submit' value='Submit' data-item=''>
            <button
              type="button"
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_REDIRECTION_URL}?plan=quarter&from=product`)
              }
              class="choose-plan-btn"
              name="submit"
              value="Submit"
              data-item=""
            >
              {btnText}
            </button>
            //       <button type='submit' class='ant-btn ant-btn-primary' name='submit' value='Submit' data-item=''>
            //       Switch to Quarterly
            //       </button>
            //   </fieldset>
            // </form>
          );
          break;
        case paypalFormTypes.MONTHLY:
          paypalForm = (
            // <form target='_blank' action='https://www.paypal.com/cgi-bin/webscr' method='post'>
            //   <fieldset>
            //     <input type='hidden' name='add' value='1' />
            //     <input type='hidden' name='cmd' value='_s-xclick' />
            //     <input type='hidden' name='hosted_button_id' value='WCENFCTMCAHFW' />
            //     {/* <input type='hidden' name='hosted_button_id' value='GEB5WWCMMKETW' /> */}
            //     <input type='hidden' name='business' value='r_javalagi2003@yahoo.com' />
            //     <input type='hidden' name='custom' value={`Raptivity2020_${emailId}_MONTHLY`} />
            //     <input type='hidden' name='return' value=' https://www.raptivity.com/Raptivity2020-PaymentCompletion.html' />
            // <button type='button' onClick={() => window.location.href =  `${process.env.REACT_APP_REDIRECTION_URL}?plan=month&from=product`}  class='ant-btn ant-btn-primary choose-plan-btn' name='submit' value='Submit' data-item=''>
            <button
              type="button"
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_REDIRECTION_URL}?plan=month&from=product`)
              }
              class="choose-plan-btn"
              name="submit"
              value="Submit"
              data-item=""
            >
              {btnText}
            </button>
            //      <button type='submit' class='ant-btn ant-btn-primary' name='submit' value='Submit' data-item=''>
            //      Switch to Monthly
            //     </button>
            //    </fieldset>
            // </form>
          );
          break;
        case paypalFormTypes.CANCEL_PREMIUM:
          paypalForm = (
            <form
              target="_blank"
              action="https://www.paypal.com/cgi-bin/websc"
              method="post"
              className="cancel-subscription-btn"
            >
              <fieldset>
                <input type="hidden" name="add" value="1" />
                <input type="hidden" name="cmd" value="_subscr-find" />
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="6VCM5KMYH9TVS"
                />
                <input
                  type="hidden"
                  name="business"
                  value="r_javalagi2003@yahoo.com"
                />
                <input
                  type="hidden"
                  name="custom"
                  value={`Raptivity2020_${emailId}`}
                />
                {/* <button type='submit' class='ant-btn ant-btn-primary choose-plan-btn' name='submit' value='Submit' data-item=''> */}
                <button
                  type="submit"
                  class="choose-plan-btn"
                  id="choose-plan-btn"
                  name="submit"
                  value="Submit"
                  data-item=""
                >
                  Clicking here
                </button>
              </fieldset>
            </form>
          );
          break;
        case paypalFormTypes.VCMONTHLY:
          paypalForm = (
            <form
              target="_blank"
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
            >
              <fieldset>
                <input type="hidden" name="add" value="1" />
                <input type="hidden" name="cmd" value="_s-xclick" />
                {/* <input type='hidden' name='hosted_button_id' value='A4FD99ZQL9HTY' /> */}
                {/* <input type='hidden' name='hosted_button_id' value='DFRSRUMBHTK9N' /> */}
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="M6FNG694PK92Y"
                />
                <input
                  type="hidden"
                  name="business"
                  value="r_javalagi2003@yahoo.com"
                />
                <input
                  type="hidden"
                  name="custom"
                  value={`Raptivity2020_${emailId}_VCMONTHLY`}
                />
                <input
                  type="hidden"
                  name="return"
                  value=" https://www.raptivity.com/Raptivity2020-PaymentCompletion.html"
                />
                <button
                  type="submit"
                  class="ant-btn ant-btn-primary"
                  name="submit"
                  value="Submit"
                  data-item=""
                >
                  Switch to Monthly
                </button>
              </fieldset>
            </form>
          );
          break;
        //for one day testing
        case paypalFormTypes.ONEDAY:
          paypalForm = (
            <form
              target="_blank"
              action="https://www.sandbox.paypal.com/cgi-bin/webscr"
              method="post"
            >
              <fieldset>
                <input type="hidden" name="add" value="1" />
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="PQLZ36YC9Z2X8"
                />
                {/* <input type='hidden' name='business' value='r_javalagi2003@yahoo.com' /> */}
                <input
                  type="hidden"
                  name="custom"
                  value={`Raptivity2020_${emailId}_ONEDAY`}
                />
                <input
                  type="hidden"
                  name="return"
                  value=" https://www.raptivity.com/Raptivity2020-PaymentCompletion.html"
                />
                <button
                  type="submit"
                  class="ant-btn ant-btn-primary"
                  name="submit"
                  value="Submit"
                  data-item=""
                >
                  {btnText}
                </button>
              </fieldset>
            </form>
          );
          break;
        case paypalFormTypes.CANCEL_ONEDAY:
          paypalForm = (
            <form
              target="_blank"
              action="https://www.sandbox.paypal.com/cgi-bin/websc"
              method="post"
            >
              <fieldset>
                <input type="hidden" name="add" value="1" />
                <input type="hidden" name="cmd" value="_subscr-find" />
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="3GUPC59AQUJ74"
                />
                {/* <input type='hidden' name='business' value='r_javalagi2003@yahoo.com' /> */}
                <input
                  type="hidden"
                  name="custom"
                  value={`Raptivity2020_${emailId}`}
                />
                <button
                  type="submit"
                  class="ant-btn ant-btn-primary"
                  name="submit"
                  value="Submit"
                  data-item=""
                >
                  Cancel Subscription
                </button>
              </fieldset>
            </form>
          );
          break;
        case paypalFormTypes.DEACTIVATE:
          paypalForm = (
            <form
              target="_blank"
              action="https://www.paypal.com/cgi-bin/websc"
              method="post"
            >
              <fieldset>
                <input type="hidden" name="add" value="1" />
                <input type="hidden" name="cmd" value="_subscr-find" />
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="6VCM5KMYH9TVS"
                />
                <input
                  type="hidden"
                  name="business"
                  value="r_javalagi2003@yahoo.com"
                />
                <input
                  type="hidden"
                  name="custom"
                  value={`Raptivity2020_${emailId}`}
                />
                <button
                  type="submit"
                  class="ant-btn ant-btn-primary"
                  name="submit"
                  value="Submit"
                  data-item=""
                >
                  Deactivate Now
                </button>
              </fieldset>
            </form>
          );
          break;
        case paypalFormTypes.PREMIUMINTERACTION:
          paypalForm = (
            <form
              target="_blank"
              name="premimum"
              style={{ display: "none" }}
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
            >
              <fieldset>
                <input type="hidden" name="add" value="1" />
                <input type="hidden" name="cmd" value="_xclick" />
                <input type="hidden" name="amount" value={amount} />
                {/* <input type='hidden' name='hosted_button_id' value='6VCM5KMYH9TVS' /> */}
                <input
                  type="hidden"
                  name="business"
                  value="r_javalagi2003@yahoo.com"
                />
                <input
                  type="hidden"
                  name="custom"
                  value={`PremiumInteraction_${emailId}`}
                />
                <input
                  type="hidden"
                  name="return"
                  value={process.env.REACT_APP_API_URL}
                />
                {/* <button type='submit' class='ant-btn ant-btn-primary' name='submit' value='Submit' data-item=''>
                          Buy Now
                          </button> */}
              </fieldset>
            </form>
          );
          break;
        default:
          if (email) {
            const { name, country } = email;
            const { price, plan } = btnText;
            const [firstName, lastName] = name.split(" ");
            let planType = '';

            if (_.capitalize(plan) === UITextMessages.label.QUARTERLY) {
              planType = '_'+plan
            }

            if (_.capitalize(plan) === UITextMessages.label.MONTHLY) {
              planType = '_'+plan
            }

            paypalForm = (
            <form name='raptivity-annual-signup-payment' id='raptivity-annual-signup-payment'
              action={process.env.REACT_APP_PAYPAL_URL} method='post'>
              <fieldset>
                <input type='hidden' name='add' value='1' />
                <input type='hidden' name='cmd' value='_xclick' />
                <input type='hidden' name='hosted_button_id' value='4Q84LM4CLFX3U' />
                <input type='hidden' name='business' value='janhavi@harbingergroup.com' />
                <input type='hidden' name='custom' value={`Raptivity2020_${emailId}${planType}`} />
                <input type='hidden' name='amount' value={price} />
                <input type='hidden' name='first_name' value={firstName} />
                <input type='hidden' name='last_name' value={lastName} />
                <input type='hidden' name='country' value={country} />
                <input type='hidden' name='quantity' value='1' />
                <input type='hidden' name='return'
                  value={`${process.env.REACT_APP_RAPTIVITY_URL}/myprofile?tab=1`} />
                <button type='submit' class='rp-btn-primary' name='submit-form' value='Submit'
                  data-item=''>
                  Proceed to Payment
                </button>
              </fieldset>
            </form>);
          }
          break;
      }
      return paypalForm;
    },

    toggleSpinner: (value) => {
      const spinner = document.getElementById("spinner");
      spinner && (spinner.hidden = value);
    },
    downloadFile: (filename, text) => {
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    interactionNote: (data) => {
      let interactionNoteData = `Below is the list of activities created and published by you in Raptivity for Virtual Classroom.\r\nSave this list to use as reference for later use during your online sessions.\r\nJust copy and paste the URL in the chat section of online learning platform and let the learners experience the activities created by you.\r\n\r\n`;
      if (data && data.length > 0) {
        data.forEach((noteData) => {
          interactionNoteData += `${noteData.publishedinteractionname}\r\n${noteData.name}\r\n${noteData.publishedinteractionurl}\r\n\r\n`;
        });
      } else {
        interactionNoteData += `\r\n\r\n<Publish link not available. You need to publish activity.> \r\n\r\n`;
      }
      interactionNoteData += "Created from https://app.raptivity.com";

      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(interactionNoteData)
      );
      element.setAttribute(
        "download",
        `${UITextMessages.label.MY_ACTIVITY_NOTE}`
      );

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },

    convertJstoXliff: (interactionData, xliffVersion) => {
      let xliffJs = {
        resources: {
          //fileName
          parallax: {},
        },
        sourceLanguage: "en-US",
      };

      let xliffSource = interactionData.xliffContent;
      xliffSource.map(function (content, index) {
        let contentName = "Content " + index;
        let hasHTML = /<\/?[a-z][\s\S]*>/i.test(content);
        if (hasHTML) {
          content = content.replaceAll("<", "&lt;").replaceAll(">", "&gt;");
        }

        // if(index == 0){
        //   xliffJs.resources.parallax[`${contentName}`] = {
        //     source: `<![CDATA[${content}]]>`,
        //     target: `<![CDATA[${content}]]>`,
        //   };
        // }else
        xliffJs.resources.parallax[`${contentName}`] = {
          // source: `<![CDATA[${content}]]>`,
          // target: `<![CDATA[${content}]]>`,
          source: `${content}`,
          target: `${content}`,
        };
      });

      let xliffFile = "";

      switch (xliffVersion) {
        case xliffVersionType.xliff12:
          jsToXliff12(xliffJs, (err, res) => {
            xliffFile = res;
          });
          break;
        case xliffVersionType.xliff2:
          js2xliff(xliffJs, (err, res) => {
            xliffFile = res;
          });
          break;

        default:
          break;
      }
      // xliffFile = xliffFile.replace(new RegExp('&lt;', 'g'), '<');
      // xliffFile = xliffFile.replace(new RegExp('&gt;', 'g'), '>');
      xliffFile = xliffFile.replace(new RegExp("&amp;", "g"), "&");
      xliffFile = `<?xml version='1.0' encoding='utf-8'?>`.concat(xliffFile);
      return xliffFile;
    },

    convertXliffToJs: (file, xliffVersion, interactionData) => {
      let xliffFile = _.cloneDeep(file);
      console.log("xliffFile", xliffFile);
      xliffFile = xliffFile.replace(
        "<?xml version='1.0' encoding='utf-8'?>",
        ""
      );
      let xliffJson = {};
      // let xmlObj = convert.xml2js(xliffFile);
      // console.log(xmlObj);
      switch (xliffVersion) {
        case xliffVersionType.xliff12:
          xliff12ToJs(xliffFile, (err, res) => {
            xliffJson = res;
          });
          break;
        case xliffVersionType.xliff2:
          xliff2js(xliffFile, (err, res) => {
            xliffJson = res;
          });
          break;

        default:
          break;
      }

      let xliffJsonKeys = Object.keys(xliffJson.resources.parallax);
      let i = 0;
      let contentToBeReplaced = [];
      for (i = 0; i < xliffJsonKeys.length; i++) {
        let { target } = xliffJson.resources.parallax[xliffJsonKeys[i]];
        if (target.includes("&lt;") && target.includes("&gt;"))
          target = target.replaceAll("&lt;", "<").replaceAll("&gt;", ">");

        contentToBeReplaced.push(target);
      }
      // contentToBeReplaced[0] = interactionData.xliffContent[0];

      let interactionXliffContent = interactionData.xliffContent;
      return { contentToBeReplaced, interactionXliffContent };
    },

    saveFileUsingBlob: (data, type, fileName) => {
      const blob = new Blob([data], {
        type: type,
      });

      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display:none";
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
    setImageSize: () => {
      let elmnt = document.getElementsByClassName("image-col");
      let imageBox = document.getElementsByClassName("imgBox");
      for (let index = 0; index < elmnt.length; index++) {
        const element = elmnt[index];
        let yHeight = element.offsetHeight;
        let xWidth = element.offsetWidth;

        imageBox[index].style.height = yHeight + "px";
        imageBox[index].style.width = xWidth + "px";
      }
    },
    navbarSize: () => {
      let elmnt = document.getElementById("navBar");
      let xWidth = elmnt.offsetWidth;
      document.getElementsByClassName(
        "interaction-inner-title"
      ).style.paddingLeft = xWidth + "px";
    },

    registerHotJarEvents: (eventName, value) => {
      window.hj && window.hj(eventName, value);
    },

    virtualScroll: (numberOfData, startIndex, totalCount, apiCall, event) => {
      let element = event.target;

      if (
        Math.round(element.scrollHeight) - Math.round(element.scrollTop) ===
        Math.round(element.clientHeight)
      ) {
        if (startIndex < totalCount) {
          apiCall(numberOfData, startIndex);
          event.stopPropagation();
        }
      }
    },
    getParameterByName: (name, url) => {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    getSliderLabels: (contentType, swapContentType) => {
      switch (contentType) {
        case contentType.IMAGE_TEXT:
          return swapContentType ? (
            <>
              <span className="left-panel">{UITextMessages.label.TEXT}</span>
              <span className="right-panel">{UITextMessages.label.IMAGE}</span>
            </>
          ) : (
            <>
              <span className="left-panel">{UITextMessages.label.IMAGE}</span>
              <span className="right-panel">{UITextMessages.label.TEXT}</span>
            </>
          );

        case contentType.IMAGE:
          return this.state.swapContentType ? (
            <>
              <span className="left-panel">
                {UITextMessages.label.QUESTION}
              </span>
              <span className="right-panel">{UITextMessages.label.IMAGE}</span>
            </>
          ) : (
            <>
              <span className="left-panel">{UITextMessages.label.IMAGE}</span>
              <span className="right-panel">
                {UITextMessages.label.QUESTION}
              </span>
            </>
          );

        case contentType.VIDEO:
          return this.state.swapContentType ? (
            <>
              <span className="left-panel">
                {UITextMessages.label.QUESTION}
              </span>
              <span className="right-panel">{UITextMessages.label.VIDEO}</span>
            </>
          ) : (
            <>
              <span className="left-panel">{UITextMessages.label.VIDEO}</span>
              <span className="right-panel">
                {UITextMessages.label.QUESTION}
              </span>
            </>
          );

        case contentType.TEXT:
          return this.state.swapContentType ? (
            <>
              <span className="left-panel">
                {UITextMessages.label.QUESTION}
              </span>
              <span className="right-panel">{UITextMessages.label.TEXT}</span>
            </>
          ) : (
            <>
              <span className="left-panel">{UITextMessages.label.TEXT}</span>
              <span className="right-panel">
                {UITextMessages.label.QUESTION}
              </span>
            </>
          );

        case contentType.IMAGE_VIDEO:
          return swapContentType ? (
            <>
              <span className="left-panel">{UITextMessages.label.VIDEO}</span>
              <span className="right-panel">{UITextMessages.label.IMAGE}</span>
            </>
          ) : (
            <>
              <span className="left-panel">{UITextMessages.label.IMAGE}</span>
              <span className="right-panel">{UITextMessages.label.VIDEO}</span>
            </>
          );
        case contentType.TEXT_VIDEO:
          return swapContentType ? (
            <>
              <span className="left-panel">{UITextMessages.label.VIDEO}</span>
              <span className="right-panel">{UITextMessages.label.TEXT}</span>
            </>
          ) : (
            <>
              <span className="left-panel">{UITextMessages.label.TEXT}</span>
              <span className="right-panel">{UITextMessages.label.VIDEO}</span>
            </>
          );
        case contentType.PDF:
          return swapContentType ? (
            <>
              <span className="left-panel">{UITextMessages.label.VIDEO}</span>
              <span className="right-panel">{UITextMessages.label.TEXT}</span>
            </>
          ) : (
            <>
              <span className="left-panel">{UITextMessages.label.TEXT}</span>
              <span className="right-panel">{UITextMessages.label.VIDEO}</span>
            </>
          );

        default:
          break;
      }
    },
    handleChangeComplete: (color) => {
      let rgb = "";
      rgb = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
      console.log("color overlay");
      return rgb;
    },
    getFill: (index, selectedBackground, selectedColor, backgroundImage) => {
      if (selectedBackground === backgroundImage) {
        return `url(#backgroundImageFill_${index})`;
      } else {
        return selectedColor;
      }
    },
    getAltText: (selectedBackground, backgroundImage, selectedAltText) => {
      if (selectedBackground === backgroundImage) {
        return selectedAltText;
      } else {
        return "";
      }
    },
    getBoxWidth() {
      let box = null;
      if (this.container) {
        box = this.container.getBoundingClientRect();
      }
      return box && box.width ? box.width : "100vw";
    },
    removeHotSpot(props) {
      const { id, globalSetting, layoutSetting, index, dispatcher } = props;
      const hotspot = _.cloneDeep(globalSetting.clickAndRevealData.hotspots);
      const slides = _.cloneDeep(layoutSetting.slides);
      const filteredIndex = hotspot.findIndex(
        (hot) => hot.slideIndex === id && hot.hotSpotIndex === index
      );
      let correct = [],
        incorrect = [];

      filteredIndex !== -1 && hotspot.splice(filteredIndex, 1);

      //rearrange the index in hotspot array
      let rIdx = 0;
      hotspot.forEach((hot, index) => {
        if (hot.slideIndex === id) {
          hotspot[index].hotSpotIndex = rIdx;
          hotspot[index].hotspotTitle = `Hotspot - ${rIdx + 1}`;
          rIdx++;
        }
      });

      for (let h of hotspot) {
        let {
          y,
          x,
          width,
          height,
          hotSpotIndex,
          feedback,
          slideIndex,
          selectedShape,
          modalText,
        } = h;
        if (feedback === "correct" && slideIndex === id) {
          correct.push({
            top: y,
            left: x,
            width: width,
            height: height,
            index: hotSpotIndex,
            message: modalText,
            shape: selectedShape,
          });
        }

        if (feedback === "incorrect" && slideIndex === id) {
          incorrect.push({
            top: y,
            left: x,
            width: width,
            height: height,
            index: hotSpotIndex,
            message: modalText,
            shape: selectedShape,
          });
        }
      }
      slides[id].questionData.answer["correct"] = correct;
      slides[id].questionData.answer["inCorrect"] = incorrect;

      dispatcher.updateHotSpotData(hotspot);
      dispatcher.updateLayoutSetting(slides);
    },
    updateHotSpot(props) {
      const { id, globalSetting, layoutSetting, index, data, dispatcher } =
        props;
      const hotspot = _.cloneDeep(globalSetting.clickAndRevealData.hotspots);
      const slides = _.cloneDeep(layoutSetting.slides);
      const {
        questionData: { answer, feedbackPanel },
      } = slides[id];
      let filteredIndex = hotspot.findIndex(
        (hot) => hot.hotSpotIndex === index && hot.slideIndex === id
      );
      const updatedData = { ...hotspot[filteredIndex], ...data };
      let correct = [],
        incorrect = [];
      let feedbackCorrect = [],
        feedbackIncorrect = [];

      hotspot.splice(filteredIndex, 1, updatedData);

      hotspot.forEach((hot) => {
        let {
          y,
          x,
          width,
          height,
          hotSpotIndex,
          feedback,
          modalText,
          slideIndex,
          selectedShape,
        } = hot;
        if (feedback === "correct" && slideIndex === id) {
          correct.push({
            top: y,
            left: x,
            width: width,
            height: height,
            index: hotSpotIndex,
            message: modalText,
            shape: selectedShape,
          });
          feedbackCorrect.push(modalText);
        }

        if (feedback === "incorrect" && slideIndex === id) {
          incorrect.push({
            top: y,
            left: x,
            width: width,
            height: height,
            index: hotSpotIndex,
            message: modalText,
            shape: selectedShape,
          });
          feedbackIncorrect.push(modalText);
        }
      });

      answer["correct"] = correct;
      answer["inCorrect"] = incorrect;
      feedbackPanel["correct"] = feedbackCorrect;
      feedbackPanel["wrong"] = feedbackIncorrect;

      dispatcher.updateHotSpotData(hotspot);
      dispatcher.updateLayoutSetting(slides);
    },
    formatDuration(sec) {
      const min = Math.floor(sec / 60);
      let seconds = Math.abs(Math.floor(sec - min * 60));
      String(seconds).length === 1 && (seconds = "0" + seconds);
      return `${min}:${seconds}`;
    },
    getSeekTime(seek) {
      const splitTime = seek.split(":");
      if (splitTime.length === 2) {
        let [mm, ss] = splitTime;
        ss = ss.length === 2 ? Number(ss) : Number(`${ss}0`);
        return Number(mm) * 60 + ss;
      } else {
        let [hh, mm, ss] = splitTime;
        return Number(hh) * 60 + Number(mm) * 60 + Number(ss);
      }
    },
    isIndianTimeZone() {
      const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
      const zones = ["Asia/Calcutta", "Asia/Kolkata"];
      return zones.includes(timeZone);
    },
    get plans() {
      return {
        [paypalFormTypes.MONTHLY]: 30,
        [paypalFormTypes.QUARTERLY]: 75,
        [paypalFormTypes.YEARLY]: 199,
        YEARLY_DISCOUNT: 49.75
      }
    } 
  };
};

export default util();
