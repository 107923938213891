import * as actionTypes from './resetPasswordActionTypes';

const resetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return { ...state, resetPasswordInfo: action.payload };

    default:
      return state;
  }
};
export default resetPasswordReducer;
