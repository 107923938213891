import * as actionTypes from './previewActionTypes';

import util from '../../helpers/utils/util';

import httpVerb from '../../helpers/enums/httpVerbs';
let apiParams = {};

export function getPreviewData(interactionId, userEmail, isDefaultPreview) {
  return function(dispatch) {
    let headers = {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + sessionStorage.getItem('token'),
      Username: sessionStorage.getItem('email')
    };

    util.toggleSpinner(false);

    dispatch({
      type: actionTypes.PREVIEW_IN_PROGRESS,
      payload: {}
    });
    let url = `${process.env.REACT_APP_API_URL}/api/preview`;
    if (isDefaultPreview) {
      url = `${process.env.REACT_APP_API_URL}/api/defaultPreview`;
    }
    apiParams = { interactionId, userEmail, isDefaultPreview, userId: sessionStorage.getItem('userId') };

    return util
      .axios(httpVerb.POST, url, apiParams, {
        headers: headers
      })
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: actionTypes.PREVIEW_ERROR,
            payload: response.data
          });

          // util.displayMessage(messageType.ERROR, response.data.error);
        } else {
          dispatch({
            type: actionTypes.PREVIEW_SUCCESS,
            payload: response.data
          });
          // util.displayMessage(messageType.SUCCESS, response.data.message);
        }

        util.toggleSpinner(true);
      })
      .catch(error => {
        util.toggleSpinner(true);
        //util.displayMessage(messageType.ERROR, error);
      });
  };
}

export function updateViewMode(value) {
  return function(dispatch) {
    dispatch({
      type: actionTypes.UPDATE_VIEW_MODE,
      payload: value
    });
  };
}
