import * as actionTypes from './previewActionTypes';

const previewReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PREVIEW_SUCCESS:
      return { ...state, previewInfo: action.payload.data };
    case actionTypes.UPDATE_VIEW_MODE:
      return { ...state, viewMode: action.payload };
    case actionTypes.PREVIEW_ERROR:
      return { ...state, previewInfo: action.payload.data };
    default:
      return state;
  }
};
export default previewReducer;
