import React from 'react';
import { UITextMessages } from '../helpers/constants/uiTextMessages';
import { Button } from 'antd';

export const insert = () => {
  return (
    <div id='cookieConsent'>
      <div style={{ margin: '10px 20px' }}>
        <div className='popup-text'>
          {' '}
          <div>We use cookies to ensure you get better browsing experience on our website.</div>
          <div className='btnAlign'>
            <Button type='primary' onClick={() => cookieConsent()}>
              Accept &amp; Close
            </Button>
            <a target='_blank' href='https://www.raptivity.com/Raptivity2020-CookiePolicy.html' className='ant-btn mybtn btnMore'>
              Know more
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export const cookieConsent = () => {
  setCookie('isCookieSet', 1);
  let cookieConsent = document.getElementById('cookieConsent');
  if (cookieConsent) cookieConsent.style.display = 'none';
};

export const setCookie = (cookieVar, flag) => {
  var d = new Date();
  var numberOfDays = 30;
  d.setTime(d.getTime() + numberOfDays * 24 * 60 * 60 * 1000);
  var expiry = 'expires=' + d.toUTCString() + ';path=/';
  document.cookie = cookieVar + '=' + flag + ';' + expiry;

  var decodedCookie = decodeURIComponent(document.cookie);
};

export const getCookie = cname => {
  var name = cname + '=';

  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const checkCookie = () => {
  var cookie = getCookie('isCookieSet');

  setTimeout(function() {
    let cookieConsent = document.getElementById('cookieConsent');
    if (cookie != '') {
      if (cookie == '1') {
        setTimeout(function() {
          cookieConsent &&
          (cookieConsent.style.display = 'none');
        }, 0);
      }
    } else {
      setTimeout(function() {
        cookieConsent.style.display = 'block';
      }, 0);
    }
  }, 100);
};

export default () => (
  <>
    {insert()}
    {checkCookie()}
  </>
);
