import * as actionTypes from './proRegisterActionTypes';

const proRegistrationReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PRO_REGISTRATION_SUCCESS:
      return { ...state, registrationInfo: action.payload };

    default:
      return state;
  }
};
export default proRegistrationReducer;
