import { notification as AntDNotification } from 'antd';
import React from 'react';
import { UITextMessages } from '../helpers/constants/uiTextMessages';
import messageType from '../helpers/enums/messageTypes';
import paypalFormType from '../helpers/enums/paypalFormTypes';
import util from '../helpers/utils/util';
import _ from 'lodash';

export const hideDisplayPopup = type => {
  let elementExpiryPopup = document.getElementsByClassName('ExpiryPopup');
  if (elementExpiryPopup.length > 0) {
    elementExpiryPopup[0].remove();
  }
};

export const ExpiryPopup = isSubscribed => {
  let accountNewInfo = {
    expiryDate: sessionStorage.getItem('expiryDate'),
    currentPlan: sessionStorage.getItem('currentPlan'),
    userCount: sessionStorage.getItem('userCount'),
    isSubscribed: isSubscribed
  };
  if (accountNewInfo && sessionStorage.getItem('expiryPopupSet') === null && accountNewInfo.isSubscribed == 0) {
    const expiryDate = new Date(accountNewInfo.expiryDate);
    let deactivationDate = _.cloneDeep(expiryDate);
    const currentDate = new Date(Date.now());
    const diffTime = Math.abs(expiryDate.getTime() - currentDate.getTime());
    let remainingDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (expiryDate < currentDate) {
      remainingDays = 0;
    }

    const paypalForm = subscriptionPackage => {
      if (subscriptionPackage) {
        switch (subscriptionPackage.toLowerCase()) {
          case 'basic':
            return util.paypalForm(paypalFormType.BASIC);

          case 'standard':
            return util.paypalForm(paypalFormType.STANDARD);

          case 'premium':
            return util.paypalForm(paypalFormType.PREMIUM);

          default:
            break;
        }
      }
    };

    const close = () => {
      localStorage.setItem('expiryPopupSet', 'Set');
      sessionStorage.setItem('expiryPopupSet', 'Set');
    };
    const key = `open${Date.now()}`;
    const subscriptionButton = subscriptionPackageName => <span onClick={() => AntDNotification.close(key)}>{subscriptionPackageName}</span>;

    const displayPopup = (type, remainingDays, subscriptionPackage) => {
      AntDNotification[type]({
        message: 'Subscription Notification',
        duration: 0,
        description: `${
          remainingDays > 1
            ? `Your subscription for the ${subscriptionPackage} is valid for the next ${remainingDays} day(s).`
            : 'Your subscription has been ended.'
        }`,
        // btn: subscriptionButton(paypalForm(subscriptionPackage)),
        btn: <a href='/myaccount'>Click to subscribe now.</a>,
        key,
        className: 'ExpiryPopup',
        // placement: "topLeft",
        onClose: close
      });
    };

    if (accountNewInfo.currentPlan && accountNewInfo.currentPlan.includes('Free')) {
      deactivationDate.setMonth(deactivationDate.getMonth() + 1);
    } else {
      deactivationDate.setMonth(deactivationDate.getMonth() + 3);
    }

    if (remainingDays < 0) {
      remainingDays = 0;
    }

    if (currentDate >= deactivationDate) {
    } else {
      if (remainingDays < UITextMessages.message.REMAINING_DAYS && sessionStorage.getItem('popupCounter') === null) {
        sessionStorage.setItem('popupCounter', 'set');
        sessionStorage.setItem('popupCounter', 'set');
        displayPopup(messageType.INFO, remainingDays, accountNewInfo.currentPlan);
      } else if (remainingDays < UITextMessages.message.REMAINING_DAYS && sessionStorage.getItem('popupCounter') !== null) {
        sessionStorage.removeItem('popupCounter', 'set');
        sessionStorage.removeItem('popupCounter', 'set');
        hideDisplayPopup(messageType.INFO);
        displayPopup(messageType.INFO, remainingDays, accountNewInfo.currentPlan);
        sessionStorage.setItem('popupCounter', 'set');
        sessionStorage.setItem('popupCounter', 'set');
      }
    }
  }
};
