/* eslint-disable react/react-in-jsx-scope */
import React, { Component } from 'react';
import { Col } from 'antd';
import * as actions from './socialLoginActions';
import { connect } from 'react-redux';
import { UITextMessages } from '../../../helpers/constants/uiTextMessages';
import userTypes from '../../../helpers/enums/userTypes';
import util from '../../../helpers/utils/util';

//Less Import
import './SocialLogin.less';

class SocialLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  facebookLogin = (userTypeId = userTypes.EL) => {
    const loginType = {
      name: 'facebook',
    };
    if (window.location.href.includes('userTypeId')) {
      userTypeId = util.getParameterByName('userTypeId');
    }
    actions
      .loginWithFacebook(loginType, userTypeId)
      .then((res) => {
        window.open(res.data.facebook.url, '_self');
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  googleLogin = (userTypeId = userTypes.EL) => {
    const loginType = {
      name: 'google',
    };
    if (window.location.href.includes('userTypeId')) {
      userTypeId = util.getParameterByName('userTypeId');
    }
    actions
      .loginWithFacebook(loginType, userTypeId)
      .then((res) => {
        window.open(res.data.facebook.url, '_self');
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  render() {
    return (
      <>
        <Col span={12} style={{ backgroundColor: 'white' }}>
          <button id='facebook-signup' className='login-btn login-btn--facebook' onClick={() => this.facebookLogin(this.props.userTypeId)}>
            {UITextMessages.label.FACEBOOK}
          </button>
        </Col>
        <Col span={12} style={{ backgroundColor: 'white' }}>
          <button id='google-signup' className='login-btn login-btn--google' onClick={() => this.googleLogin(this.props.userTypeId)}>
            {UITextMessages.label.GOOGLE}
          </button>
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let facebookInfo = {};
  if (state.loginReducer.facebookInfo !== undefined) {
    facebookInfo = state.loginReducer;
    return facebookInfo;
  } else {
    return { facebookInfo };
  }
};

export default connect(mapStateToProps)(SocialLogin);
