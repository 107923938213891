const interactionType = {
  PARALLAX: 1,
  TIMELINE: 2,
  SIMPLE: 3,
  GAMEBASED: 5,
  CLICK_AND_REVEAL: 4,
  PANORAMA: 6,
  CLICK_AND_REVEAL_ASSESSMENT: 7,
  KNIFE_HIT: 10,
  BOWLING: 12,
  CHEES_AND_MOUSE: 11,
  MOUNTAIN_CLIMB: 14,
  HORSE_RIDING: 13,
  PIZZA: 15,
  BALLOON_HIT: 16,
  FLIPCARD: 17,
  PARKING_LOT: 18,
  ROULETTE: 19,
  PINIT: 20,
  READ_OR_ANSWER: 21,
  SPIN_THE_WHEEL : 22,
  OPEN_BOOK_CHALLENGE : 23,
  SPIN_THE_WHEEL_NEW : 24,
  SUPER_GOLDIO : 25,
  INTERACTIVE_VIDEO: 26
};

export default interactionType;
