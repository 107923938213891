import React from 'react';
import { UITextMessages } from '../helpers/constants/uiTextMessages';
import { Modal } from 'antd';

import util from '../helpers/utils/util';
import UserTypeContext from '../context/userTypeContext';
import paypalFormTypes from '../helpers/enums/paypalFormTypes';
import userType from '../helpers/enums/userTypes';
import DeviceDetector from 'device-detector-js';

const writeToUsBody = (currentPlan) => {
  const deviceDetector = new DeviceDetector();
  const deviceInfo = deviceDetector.parse(navigator.userAgent);
  console.log(deviceInfo);
  return `
Hello Support, %0D%0A%0D%0A

I have a query/issue/feedback regarding Raptivity as mentioned below: %0D%0A%0D%0A

%0D%0A%0D%0A

 

.....PLEASE DO NOT DELETE THIS INFORMATION. WE WILL BE USING IT TO SERVE YOU BETTER.....  %0D%0A%0D%0A

Current Plan: ${currentPlan} %0D%0A

Browser Information : ${deviceInfo.client.name} ${deviceInfo.client.version} %0D%0A
  `;
};

export default (props) => (
  <UserTypeContext.Consumer>
    {(context) => {
      let currentPlan = context.currentPlan && context.currentPlan.includes('Free') ? context.currentPlan : UITextMessages.label.PREMIUM;
      return (
        <a
          className='write-us-btn'
          href={`mailto:support@raptivity.com?Subject=${
            parseInt(context.contextId) === parseInt(userType.VC)
              ? UITextMessages.label.VIRTUAL_CLASSROOM_SUBJECT_LINE
              : UITextMessages.label.RAPTIVITY_2020_SUBJECT_LINE
          }&body=${writeToUsBody(currentPlan)}`}
          target='_top'
        >
          {UITextMessages.label.WRITE_TO_US}
        </a>
      );
    }}
  </UserTypeContext.Consumer>
);
