export const UPDATE_BACKGROUND_COLOR = 'UPDATE_BACKGROUND_COLOR';
export const UPDATE_BACKGROUND_IMAGE = 'UPDATE_BACKGROUND_IMAGE';
export const FETCH_ASSET_LIBRARY_IMAGE_SUCCESS = 'FETCH_ASSET_LIBRARY_IMAGE_SUCCESS';
export const FETCH_ASSET_LIBRARY_IMAGE_ERROR = 'FETCH_ASSET_LIBRARY_IMAGE_ERROR';
export const FETCH_MY_LIBRARY_IMAGE_SUCCESS = 'FETCH_MY_LIBRARY_IMAGE_SUCCESS';
export const FETCH_MY_LIBRARY_IMAGE_ERROR = 'FETCH_MY_LIBRARY_IMAGE_ERROR';
export const UPDATE_USER_ASSET_SETTINGS = 'UPDATE_USER_ASSET_SETTINGS';
export const UPDATE_RECENT_COLORS = 'UPDATE_RECENT_COLORS';
export const UPDATE_COUNTER = 'UPDATE_COUNTER';
export const UPDATE_OVERLAY_RECENT_COLORS = 'UPDATE_OVERLAY_RECENT_COLORS';
