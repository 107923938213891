import * as actionTypes from './registerActionTypes';

import messageType from '../../helpers/enums/messageTypes';
import util from '../../helpers/utils/util';
import httpVerb from '../../helpers/enums/httpVerbs';
let apiParams = {};

export function EmployeeRegistration(user) {
  return function(dispatch) {
    let headers = {
      'Content-Type': 'application/json'
    };
    util.toggleSpinner(false);

    apiParams = { user };

    return util
      .axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/registration`, apiParams, { headers })
      .then(response => {
        if (response.data.error === undefined) {
          dispatch({
            type: actionTypes.REGISTRATION_SUCCESS,
            payload: response.data
          });

          // util.displayMessage(messageType.SUCCESS, response.data.message);
          util.toggleSpinner(true);
        } else {
          console.log(response.data.error);
          dispatch({
            type: actionTypes.REGISTRATION_ERROR,
            payload: response.data
          });

          util.displayMessage(messageType.ERROR, response.data.message);
          util.toggleSpinner(true);
        }
      })
      .catch(error => {
        util.displayMessage(messageType.ERROR, error);
        util.toggleSpinner(true);
      });
  };
}

export function clearRegistrationReducer() {
  return function(dispatch) {
    dispatch({
      type: actionTypes.CLEAR_REGISTRATION_REDUCER,
      payload: {}
    });
  };
}
