import * as actionTypes from './loginActionTypes';

const loginReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return { ...state, loginInfo: action.payload, userInfo: action.payload.user };

    case actionTypes.LOGIN_ERROR:
      return { ...state, loginInfo: action.payload, userInfo: action.payload.user };

    case actionTypes.LOGIN_INPROGRESS:
      return { ...state, loginInfo: action.payload, userInfo: action.payload };

    default:
      return state;
  }
};
export default loginReducer;
