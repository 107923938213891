import React from 'react';
import { UITextMessages } from '../helpers/constants/uiTextMessages';
import { Layout } from 'antd';

const { Footer } = Layout;

export default () => (
  <Footer>
    <span>&copy; 2008-{new Date().getFullYear()} Raptivity. All Rights Reserved.</span>
    &nbsp;
    <a target='_blank' href='https://www.raptivity.com/Raptivity2020-PrivacyPolicy.html'>
      {' '}
      {UITextMessages.label.PRIVACY_POLICY}
    </a>{' '}
    &nbsp; | &nbsp;{' '}
    <a target='_blank' href='https://www.raptivity.com/Raptivity2020-TermsOfUse.html '>
      {' '}
      {UITextMessages.label.TERMS_OF_USE}
    </a>
  </Footer>
);
