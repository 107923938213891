import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./configureStore";
import Routes from "./Routes";
import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";
import CookiePolicy from "./components/CookiePolicy";
import Spinner from "./components/Spinner";
import { hideDisplayPopup } from "./components/ExpiryPopup";
import messageType from "./helpers/enums/messageTypes";
import PotraitPrevent from "./components/PotraitPrevent";
import TokenAuthentication from "./containers/tokenAuthentication/TokenAuthentication";
import UserTypeContext from "./context/userTypeContext";
import util from "./helpers/utils/util";
import { UITextMessages } from "./helpers/constants/uiTextMessages";
import storageType from "./helpers/enums/storageTypes";

import { Layout } from "antd";
import { Icon } from "antd";

//Less Import
import "./assets/less/App.less";
import storageTypes from "./helpers/enums/storageTypes";

const store = configureStore();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      viewModeNav: "default",
      app: { contextId: null, currentPlan: null },
    };
  }
  componentDidMount() {
    window.addEventListener("online", () =>
      util.displayNotification(
        messageType.INFO,
        "Internet Connectivity",
        UITextMessages.message.ONLINE_CONNECTIVITY,
        3,
        "NetworkNotification",
        <Icon type="wifi" style={{ color: "#00bf6f" }} />
      )
    );
    window.addEventListener("offline", () => {
      util.displayNotification(
        messageType.WARNING,
        "Internet Connectivity",
        UITextMessages.message.OFFLINE_CONNECTIVITY,
        0,
        "NetworkNotification",
        <Icon type="wifi" style={{ color: "#ff0000" }} />
      );
    });
  }

  userHasAuthenticated = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  allStorage = (storageTypes, storage) => {
    var keys = Object.keys(storage);
    var i = keys.length;
    var values = Object.values(storage);

    while (i--) {
      if (storageType.LOCALSTORAGE === storageTypes) {
        sessionStorage.setItem(keys[i], values[i]);
      } else {
        localStorage.setItem(keys[i], values[i]);
      }
    }
  };

  authenticate = () => {
    if (
      localStorage.getItem("token") &&
      !sessionStorage.getItem("currentPlan")
    ) {
      (async () => {
        await this.allStorage(storageType.LOCALSTORAGE, localStorage);
      })();
    } else if (
      !localStorage.getItem("email") &&
      sessionStorage.getItem("currentPlan")
    ) {
      (async () => {
        await this.allStorage(storageTypes.SESSIONSTORAGE, sessionStorage);
      })();
    }
    if (sessionStorage.getItem("token")) {
      !this.state.isAuthenticated && this.userHasAuthenticated(true);
      return true;
    }
  };

  setUserTypeContext = (id, currentPlan) => {
    this.setState({ app: { contextId: id, currentPlan } });
  };

  setViewModeNav = (mode) => {
    this.setState({ viewModeNav: mode });
  };

  handleLogout = async (event) => {
    hideDisplayPopup(messageType.INFO);
    localStorage.clear();
    sessionStorage.clear();
    this.userHasAuthenticated(false);
  };

  render() {
    let isDefaultPreview = false;
    if (window.location.pathname.includes("/default/")) {
      isDefaultPreview = true;
      this.authenticate();
    }
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      authenticate: this.authenticate,
      setViewModeNav: this.setViewModeNav,
      isDefaultPreview: isDefaultPreview,
      allStorage: this.allStorage,
      setUserTypeContext: this.setUserTypeContext,
    };

    return (
      <Provider store={store}>
        <Router>
          <Spinner />
          <TokenAuthentication
            isAuthenticated={this.state.isAuthenticated}
            handleLogout={this.handleLogout}
          />
          {this.state.isAuthenticated && <PotraitPrevent />}
          <Layout>
            <UserTypeContext.Provider value={this.state.app}>
              {(this.state.isAuthenticated || isDefaultPreview) && (
                <Header
                  userTypeId={this.state.app.contextId}
                  isDefaultPreview={isDefaultPreview}
                  isAuthenticated={this.state.isAuthenticated}
                  viewModeNav={this.state.viewModeNav}
                  handleLogout={this.handleLogout}
                  setUserTypeContext={this.setUserTypeContext}
                />
              )}

              <Content>
                <Routes childProps={childProps} />
              </Content>
            </UserTypeContext.Provider>

            {this.state.viewModeNav !== "preview" ? <Footer /> : null}
            <CookiePolicy />
          </Layout>
        </Router>
      </Provider>
    );
  }
}

export default App;
