import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './resetPasswordActions';
import { Row, Col, Form, Icon, Input, Button, Tooltip } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import Features from '../../../components/Features';
import jwtDecode from 'jwt-decode';

import jwt from 'jsonwebtoken';
//Less Import
import './resetPassword.less';
import { UITextMessages } from '../../../helpers/constants/uiTextMessages';


//Image
//import logo from '../../../assets/images/logo.png';
import { ReactComponent as Lock } from '../../../assets/images/pass.svg';
import { ReactComponent as Information } from '../../../assets/images/information.svg';
import { ReactComponent as PasswordSuccessfulLock } from '../../../assets/images/password-img.svg';
import { ReactComponent as LogoNew } from '../../../assets/images/logo_new.svg';
import { Scrollbars } from 'react-custom-scrollbars';

class ResetPassword extends Component {
  constructor(props) {
    const queryParams = new URLSearchParams(window.location.search);
    const maildecode = jwtDecode(queryParams.get("token"))
    console.log("queryParams===========>",queryParams);
    console.log("maildecode========>",maildecode);
    super(props);
    this.state = {
      password: '',
      confirmPassword: ''
    };
    this.email = maildecode.emailusername;
    console.log("maildecode.emailusername====>",maildecode.emailusername);
  }
  compareWithNewPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(UITextMessages.message.TWO_PASSWORDS_ENTERED_NOT_MATCHED);
    } else {
      callback();
    }
  };

  validateNewPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  handleResetPassword = async e => {
    debugger;
    e.preventDefault();
    const urlParams = new URLSearchParams(window.location.search);
    const hash = urlParams.get('hash');

    if (!hash) {
        // Hash is not present in the URL
        console.error("Hash not found in URL.");
        // Display error message or redirect the user
        return;
    }

    this.props.form.validateFields(async(err, values) => {
        if (!err) {
            const { password } = values;
            const token = jwt.sign({ email: this.email }, 'X85E15jN45DXNQuLcNUpnMkPbYEtPpw9', { expiresIn: "5m" });

            let user = {
                token,
                password,
                hash
            };
            await this.props.resetPassword(user);
        }
    });
};

  // componentDidMount=()=>{
  //   // const queryParams = new URLSearchParams(window.location.search);
  //   // const maildecode = jwtDecode(queryParams.get("token"))
  //   // console.log("queryParams===========>",queryParams);
  //   // console.log("maildecode========>",maildecode);
  // }
  getResetPasswordSection = () => {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className='main-container'>
        <Row justify='space-around' style={{ padding: '10.6% 10.2% 0%' }} align='middle' className='forgot-form'>
          <Row className='logo'>
            {/* <img src={logo} alt='logo' className='login-logo-image' style={{ height: '73px', width: '184px' }} /> */}
            <LogoNew />
          </Row>
          <Row className='forgot-form-page' style={{ marginTop: '15%' }}>
            <Row>
              <Row className='resetPassword_label'>{UITextMessages.label.RESET_PASSWORD}</Row>
            </Row>
            <Form style={{ marginTop: '6%' }}>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: UITextMessages.message.ENTER_PASSWORD
                    },
                    {
                      // pattern: new RegExp('(?!^[0-9]*$)^([a-zA-Z0-9~!@#$%^&*()_\\[+=,\\]\\{\\}\\\\.?|<>/;:\\\'\\`"-]{6,})$'),
                      pattern: new RegExp(
                        '(?!^[0-9~!@#$%^&*()_\\[+=,\\]\\{\\}\\\\.?|<>/;:\\\'\\`"-]*$)^([a-zA-Z0-9~!@#$%^&*()_\\[+=,\\]\\{\\}\\\\.?|<>/;:\\\'\\`"-]{6,})$'
                      ),
                      message: UITextMessages.message.INVALID_PASSWORD
                    },
                    {
                      validator: this.validateNewPassword
                    }
                  ]
                })(
                  <Input.Password
                    suffix={
                      // <Tooltip title='Minimum length is 6 alphanumeric characters'>
                      // <Icon component={Information} />
                      // </Tooltip>
                      <Icon type='info-circle' style={{ color: 'rgba(0,0,0,.45)' }} />
                    }
                    prefix={<Icon component={Lock} />}
                    type='password'
                    placeholder={UITextMessages.label.NEW_PASSWORD}
                    onChange={this.handleChangePassword}
                    className='password'
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('confirm', {
                  rules: [
                    {
                      required: true,
                      message: UITextMessages.message.ENTER_PASSWORD
                    },
                    // {
                    // 	// pattern: new RegExp('(?!^[0-9]*$)^([a-zA-Z0-9~!@#$%^&*()_\\[+=,\\]\\{\\}\\\\.?|<>/;:\\\'\\`"-]{6,})$'),
                    // 	pattern: new RegExp(
                    // 		'(?!^[0-9~!@#$%^&*()_\\[+=,\\]\\{\\}\\\\.?|<>/;:\\\'\\`"-]*$)^([a-zA-Z0-9~!@#$%^&*()_\\[+=,\\]\\{\\}\\\\.?|<>/;:\\\'\\`"-]{6,})$'
                    // 	),
                    // 	message: UITextMessages.message.INVALID_PASSWORD
                    // },
                    {
                      validator: this.compareWithNewPassword
                    }
                  ]
                })(
                  <Input.Password
                    prefix={<Icon component={Lock} />}
                    type='password'
                    placeholder={UITextMessages.label.CONFIRM_NEW_PASSWORD}
                    onChange={this.handleChangePasswordConfirm}
                    className='password'
                  />
                )}
              </Form.Item>
            </Form>
          </Row>
          <Row className='forgot-buttons'>
            <Form.Item style={{ marginBottom: '0px' }}>
              <Button
                size='large'
                type='primary'
                htmlType='submit'
                style={{ width: '100%' }}
                className='reset_password_button'
                onClick={this.handleResetPassword}
              >
                {UITextMessages.label.UPDATE_PASSWORD}
              </Button>
            </Form.Item>
          </Row>
          <Row align='middle' className='createAcc-link-label'>
            <Col align='middle'>
              {UITextMessages.label.BACK_TO}{' '}
              <Link to='/' className='createAcc-Link-Label'>
                {UITextMessages.label.LOG_IN}
              </Link>
            </Col>
          </Row>
        </Row>
      </div>
    );
  };

  getFeatureSectionView = () => {
    return (
      <div className='feature-inner-container'>
        <Features />
      </div>
    );
  };

  getResetFormView = () => {
    return (
      <Scrollbars className='scroller-main-wrapper login-scroller' style={{ height: 'calc(100vh - 49px)' }}>
        <Row className='wrapper-container'>
          <Row type='flex' justify='center' className='login-main-container' style={{ marginTop: '2%' }}>
            <Col style={{ backgroundColor: 'white' }} lg={12} xl={12} className='reset-password-container'>
              {this.props.resetPasswordInfo.status === 200 ? this.getSuccessfulResetPasswordView() : this.getResetPasswordSection()}
            </Col>
            <Col style={{ boxShadow: '0 0 8px lightgray' }} lg={12} xl={12} className='feature_container feature_container_background'>
              {this.getFeatureSectionView()}
            </Col>
          </Row>
        </Row>
      </Scrollbars>
    );
  };

  getSuccessfulResetPasswordView = () => {
    return (
      <div className='main-container'>
        <Row justify='space-around' style={{ padding: '7% 7% 4% 7%', marginTop: '25%' }} align='middle' className='forgot-form'>
          <Row type='flex' justify='center' style={{ marginBottom: '5%' }}>
            <Icon component={PasswordSuccessfulLock} style={{ fontSize: '102px' }} />
          </Row>
          <Row type='flex' justify='center'>
            <p className='password-heading'>{UITextMessages.message.PASSWORD_UPDATED_SUCCESSFULLY}</p>
            <p className='password-instruction'>{UITextMessages.message.YOU_CAN_LOGIN_WITH_NEW_PASSWORD}</p>
          </Row>

          <Row align='middle' className='createAcc-link-label'>
            <Col align='middle'>
              {UITextMessages.label.BACK_TO}{' '}
              <Link to='/' className='createAcc-Link-Label'>
                {UITextMessages.label.LOG_IN}
              </Link>
            </Col>
          </Row>
        </Row>
      </div>
    );
  };

  render() {
    return this.getResetFormView();
  }
}

const mapStateToProps = state => {
  let resetPasswordInfo = '';
  if (state.resetPasswordReducer.resetPasswordInfo !== undefined) {
    resetPasswordInfo = state.resetPasswordReducer;
    return resetPasswordInfo;
  } else {
    return { resetPasswordInfo };
  }
};

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: user => {
      dispatch(actions.ResetPassword(user));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'reset_password' })(withRouter(ResetPassword)));
