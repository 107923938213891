import * as actionTypes from './emailVerificationActionTypes';
import messageType from '../../helpers/enums/messageTypes';
import util from '../../helpers/utils/util';
import httpVerb from '../../helpers/enums/httpVerbs';
let apiParams = {};

export function timestampVerification(verficationCode) {
  return function(dispatch) {
    let headers = {
      'Content-Type': 'application/json'
    };
    util.toggleSpinner(false);

    return util
      .axios(httpVerb.GET, `${process.env.REACT_APP_API_URL}/api/accountVerify/${verficationCode}`, {}, { headers })
      .then(response => {
        window.location.href = response.data.redirectionUrl;
      })
      .catch(error => {
        window.location.href = `${window.location.origin}`;

        util.toggleSpinner(true);
      });
  };
}

export function EmailVerification(user) {
  return function(dispatch) {
    let headers = {
      'Content-Type': 'application/json'
    };
    util.toggleSpinner(false);
    apiParams = { user };

    return util
      .axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/emailVerification`, apiParams, { headers })
      .then(response => {
        if (response.data.error === undefined) {
          // sessionStorage.setItem('email', response.data.email);
          dispatch({
            type: actionTypes.Email_Verification_SUCCESS,
            payload: response.data
          });

          // util.displayMessage(messageType.SUCCESS, response.data.message);
          util.toggleSpinner(true);
        } else {
          dispatch({
            type: actionTypes.Email_Verification_ERROR,
            payload: response.data
          });

          util.displayMessage(messageType.WARNING, response.data.message);
          util.toggleSpinner(true);
        }
      })
      .catch(error => {
        util.displayMessage(messageType.ERROR, error.message);
        util.toggleSpinner(true);
      });
  };
}
