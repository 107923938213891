import * as actionTypes from './profileActionTypes';
const profileReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_SUCCESS:
    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return { ...state, userInfo: action.payload };
    case actionTypes.UPLOAD_PROFILE_PIC:
      return { ...state, userInfo: { 
        ...state.userInfo, 
        user: { 
          ...state.userInfo.user, 
          profilepicurl: action.payload.data ? 
                         action.payload.data.url : 
                         state.userInfo.user.profilepicurl
        },
          photo: action.payload 
        }  
      }
    default:
      return state;
  }
};
export default profileReducer;
