import { Layout, Icon, Row, Col, Empty } from 'antd';
import React, { Component } from 'react';

import { UITextMessages } from '../../helpers/constants/uiTextMessages';

import userTypes from '../../helpers/enums/userTypes';
import UserTypeContext from '../../context/userTypeContext';


import { ReactComponent as MyWorkImg } from '../../assets/images/my-work.svg';

class BuilderImage extends Component {

    render()
    {
        return(
            <Row type='flex' justify='center' gutter={25} className='side-spacing'>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <span>
              <MyWorkImg />
              <br />
              <br />
              <h3>
                <UserTypeContext.Consumer>
                  {(context) =>
                    parseInt(context.contextId) === parseInt(userTypes.VC)
                      ? UITextMessages.message.VC_INTERACATION_NOT_FOUND
                      : UITextMessages.message.INTERACATION_NOT_FOUND
                  }
                </UserTypeContext.Consumer>
              </h3>
            </span>
          }
        />
      </Row>
        )
    }
}
export default BuilderImage;