import * as actionTypes from './interactionsActionTypes';
const interactionsReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INTERACTIONS_SETTING_SUCCESS:
      let {interactionsData = []} = state;
        return {
          ...state,
          interactionsData: interactionsData.concat(action.payload.data.interactionData),
          startIndex: action.payload.data.startIndex,
          countOfInteraction: action.payload.data.totalCount
        };
      case actionTypes.CLEAR_INTERACTION_SETTING:
      return { ...state, interactionsData: [], startIndex: 0, countOfInteraction: 0 };
    case actionTypes.ADD_REMOVE_INTERACTION_FAVOURITES:
      return { ...state, favourites: action.payload }
    case actionTypes.FETCH_INTERACTIONS_FAVOURITES_LIST:
      return { ...state, favouritesList: action.payload }
    default:
      return state;
  }
};
export default interactionsReducer;
