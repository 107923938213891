import React, { useState, useEffect } from "react";
import { Button, Input, Form, Modal, Select } from "antd";
import { saveUserInfo } from "../containers/myProfile/profileActions";
import csc from "country-state-city"; // Adjust the import if necessary for version 10.2.3
const { Option } = Select;

const PersonalInfoPopup = (props) => {

  const { form , userInfo ,dispatch , setModalVisible} = props;
  const [countries, setCountries] = useState([]);
  const [occupationList, setOccupationList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedOccupation, setSelectedOccupation] = useState(null);
  const [otherOccupation, setOtherOccupation] = useState(null);

  useEffect(() => {
    setCountries(csc.getAllCountries());
    setOccupationList([
      { id: "Teacher", name: "Teacher" },
      { id: "Instructor", name: "Instructor" },
      { id: "Professor", name: "Professor" },
      { id: "Lecturer", name: "Lecturer" },
      { id: "Trainer", name: "Trainer" },
      { id: "EducationConsultant", name: "Education Consultant" },
      { id: "Author", name: "Author" },
      { id: "CourseManager", name: "Course Manager" },
      { id: "eLearningDesigner", name: "eLearning Designer" },
      { id: "CorporateTrainer", name: "Corporate Trainer" },
      { id: "InstructionalDesigner", name: "Instructional Designer" },
      { id: "SubjectMatterExpert", name: "Subject Matter Expert" },
      { id: "Student", name: "Student" },
      { id: "Other", name: "Other" },
    ]);
  }, []);

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  const handleOccupationChange = (value) => {
    setSelectedOccupation(value);
  };

  const handleOtherOccupationChange = (e) => {
    setOtherOccupation(e.target.value);
  };

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      // const { form, dispatch, userInfo } = this.props;
      form.validateFields((err, values) => {
        if (!err) {
          // Handle the "Other" occupation case
          if (values.occupation === "Other") {
            values.occupation = `${values.occupation}:${values.otherOccupation}`;
            delete values.otherOccupation;
          }
  
          // Ensure occupation, country, organizationName, and name are defined and valid
          if (
            values.occupation !== undefined &&
            values.organizationName !== undefined &&
            values.country !== undefined &&
            values.name !== undefined && // Ensure name field is included
            userInfo.user.email // Ensure email is available from Redux state
          ) {
            const reqBody = {
              email: userInfo.user.email,
              name: values.name,
              occupation: values.occupation,
              country: values.country,
              referralCode: userInfo.user.referralCode || null,
              organizationName: values.organizationName,
            };
            // Dispatch the saveUserInfo action with the form data
            dispatch(saveUserInfo(reqBody));
            setModalVisible(false); // Close the modal
          }
        }
      });
    } catch (err) {
      console.log("Sso profile error", err.message);
    }
  };
  

  return (
    <>
      <Modal
        title={
          <div style={{ textAlign: "center" }}>
            Your profile is incomplete. Please complete your profile.
          </div>
        }
        visible={props.isModalVisible}
        centered
        footer={null}
        closable={false}
        style={{ width: "500px" }}
      >
        <Form layout="vertical">
          <Form.Item label="Name">
            {form.getFieldDecorator("name", {
              rules: [{ required: true, message: "Please enter your name." }],
            })(<Input placeholder="Enter your name" />)}
          </Form.Item>
          <Form.Item label="Company Name">
            {form.getFieldDecorator("organizationName", {
              rules: [
                {
                  required: true,
                  message: "Please enter your Company Name.",
                },
              ],
            })(<Input placeholder="Enter your Company Name" />)}
          </Form.Item>
          <Form.Item label="Country">
            {form.getFieldDecorator("country", {
              rules: [{ required: true, message: "Please select your country." }],
            })(
              <Select
                showSearch
                placeholder="Select your country or type to search"
                onChange={handleCountryChange}
                value={selectedCountry}
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                }
              >
                {countries.map((country) => (
                  <Option key={country.id} value={country.name}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Occupation">
            {form.getFieldDecorator("occupation", {
              rules: [{ required: true, message: "Please select your occupation." }],
            })(
              <Select
                showSearch
                placeholder="Select your occupation or type to search"
                onChange={handleOccupationChange}
                value={selectedOccupation}
              >
                {occupationList.map((occupation) => (
                  <Option key={occupation.id} value={occupation.name}>
                    {occupation.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          {selectedOccupation === "Other" && (
            <Form.Item label="Please specify your occupation">
              {form.getFieldDecorator("otherOccupation", {
                rules: [{ required: true, message: "Please specify your occupation." }],
              })(
                <Input
                  placeholder="Enter your occupation"
                  value={otherOccupation}
                  onChange={handleOtherOccupationChange}
                />
              )}
            </Form.Item>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              onClick={handleSubmit}
            >
              Complete Profile
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default Form.create()(PersonalInfoPopup);
