/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import Features from '../../components/Features';
import { Link } from 'react-router-dom';
import * as actions from './forgotPasswordActions';
import { connect } from 'react-redux';

import { Row, Col, Form, Icon, Input, Button } from 'antd';
import { UITextMessages } from '../../helpers/constants/uiTextMessages';
import { ReactComponent as RegisteredSuccess } from '../../assets/images/register-sucessful.svg';
//Less Import
import './forgotPassword.less';
//mail
import { ReactComponent as Mail } from '../../assets/images/mail.svg';
//images
//import logo from '../../assets/images/logo.png';
import { ReactComponent as LogoNew } from '../../assets/images/logo_new.svg';
import { Scrollbars } from 'react-custom-scrollbars';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }

  handleForgotPassword = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { dispatch } = this.props;
        dispatch(actions.ResetPassword(values));
      }
    });
  };

  getForgotSectionView = () => {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className='main-container'>
        <Row justify='space-around' style={{ padding: '10.6% 10.2% 0%' }} align='middle' className='forgot-form'>
          <Row className='logo'>
            {/* <img src={logo} alt='logo' className='login-logo-image' style={{ height: '73px', width: '184px' }} /> */}
            <LogoNew />
          </Row>
          <Row className='forgot-form-page'>
            <span className='forgot_label'>{UITextMessages.label.FORGOT_PASSWORD}</span>
            <br />
            <span className='forgot_subLabel'>{UITextMessages.label.ENTER_EMAIL_TO_RESET_PASSWORD}</span>
            {/* <br />
						<span className='forgot_subLabel'>{UITextMessages.label.ENTER_EMAIL_TO_RESET_PASSWORD_EMAIL}</span> */}
            <Form style={{ marginTop: '6%' }}>
              <Form.Item>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      message: UITextMessages.message.INVALID_EMAIL_ADDRESS
                    },
                    {
                      required: true,
                      message: UITextMessages.message.ENTER_EMAIL_ADDRESS
                    }
                  ]
                })(
                  <Input
                    onPressEnter={this.handleForgotPassword}
                    prefix={<Icon component={Mail} />}
                    placeholder={UITextMessages.label.EMAIL_ADDRESS}
                    className='email-input'
                  />
                )}
              </Form.Item>
            </Form>
          </Row>
          <Row className='forgot-buttons'>
            <Form.Item style={{ marginBottom: '0px' }}>
              <Button type='primary' htmlType='submit' style={{ width: '100%' }} size='large' onClick={this.handleForgotPassword}>
                {UITextMessages.label.RESET_PASSWORD}
              </Button>
            </Form.Item>
          </Row>
          <Row align='middle' className='createAcc-link-label'>
            <Col align='middle'>
              {UITextMessages.label.BACK_TO}{' '}
              <Link to='/' className='createAcc-Link-Label'>
                {UITextMessages.label.LOG_IN}
              </Link>
            </Col>
          </Row>
        </Row>
      </div>
    );
  };

  getFeatureSectionViewForForgotPassword = () => {
    return (
      <div className='feature-inner-container'>
        <Features />
      </div>
    );
  };

  getResetEmailSentView = () => {
    return (
      <Row align='middle' className='reset_emailSent-success-container'>
        <Row className='success-image'>
          <RegisteredSuccess />
        </Row>
        <Row className='email-sent-label'>Email Sent</Row>
        <Row className='email-sent-subLabel'>A password reset link has been sent to your registered email. Please check the email to reset your password.</Row>
        <Row className='back-to-login-label'>
          <span>{UITextMessages.label.BACK_TO} </span>
          <Link to='/' className='login-link' onClick={()=>{
            const {dispatch} = this.props;
            dispatch(actions.clearForgotPasswordReducer())
          }}>
            {UITextMessages.label.LOG_IN}
          </Link>
        </Row>
      </Row>
    );
  };

  render() {
    if(this.props.passwordInfo.status == 200){
      const { dispatch } = this.props;
      dispatch(actions.clearForgotPasswordReducer);
    }
    
    return (
      <Scrollbars className='scroller-main-wrapper login-scroller' style={{ height: 'calc(100vh - 49px)' }}>
        <Row className='wrapper-container'>
          <Row type='flex' justify='center' className='login-main-container'>
            <Col style={{ backgroundColor: 'white' }} lg={12} xl={12} className='forgot-container'>
              {this.props.passwordInfo.status === 200 ? this.getResetEmailSentView() : this.getForgotSectionView()}
            </Col>
            <Col style={{ boxShadow: '0 0 8px lightgray' }} lg={12} xl={12} className='feature_container feature_container_background'>
              {this.getFeatureSectionViewForForgotPassword()}
            </Col>
          </Row>
        </Row>
      </Scrollbars>
    );
  }
}

const mapStateToProps = state => {
  return {
    passwordInfo: state.forgotPasswordReducer.passwordInfo || ''
  };
};

export default connect(mapStateToProps)(Form.create({ name: 'forgot_password' })(ForgotPassword));
