import * as actionTypes from './builderActionTypes';
import interactionType from '../../helpers/enums/interactionTypes';
import _ from 'lodash';
import { getRandomString } from '../../helpers/utils/getRandomString';

const getGlobalSettings = (data) => {
  const globalSettings = JSON.parse(data);
  if (globalSettings && globalSettings.panoramaData && globalSettings.panoramaData.hotspots && globalSettings.panoramaData.hotspots.length > 0) {
    const hotspots = globalSettings.panoramaData.hotspots.map((hotspot) => {
      if (hotspot.id) {
        return hotspot;
      } else {
        return {
          ...hotspot,
          id: getRandomString(),
        };
      }
    });
    return {
      ...globalSettings,
      panoramaData: {
        ...globalSettings.panoramaData,
        hotspots,
      },
    };
  }
  return globalSettings;
};

const builderReducer = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.FETCH_DEFAULT_BUILDER_SETTING_ERROR:
      return { ...state, status: action.payload.data };
    case actionTypes.FETCH_DEFAULT_BUILDER_SETTING:
      return { ...state, builderDefaultSetting: action.payload };
    case actionTypes.EDIT_USER_ASSET_SUCCESS:
      if('data' in action.payload && 'assetindex' in action.payload.data){
      state.userAsset[action.payload.data.assetindex].alternatetext = action.payload.data.altText;
      state.userAsset[action.payload.data.assetindex].index = action.payload.data.index;
      if (action.payload.data.updatedTranscriptUrl) {
        state.userAsset[action.payload.data.assetindex].transcripturl = action.payload.data.updatedTranscriptUrl;
      }

      if (action.payload.data.updatedCaptionUrl) {
        state.userAsset[action.payload.data.assetindex].captionurl = action.payload.data.updatedCaptionUrl;
      }

        state.slidesData.forEach((element) => {
          if (element.videoUrl === action.payload.data.assetpath) {
            if (action.payload.data.updatedTranscriptUrl) {
              element.transcriptUrl = action.payload.data.updatedTranscriptUrl;
            }
            if (action.payload.data.updatedCaptionUrl) {
              element.captionUrl = action.payload.data.updatedCaptionUrl;
            }
          }
          if (element.imageUrl === action.payload.data.assetpath) {
            element.altText = action.payload.data.altText;
          }
        });
        return {
          ...state,
          userAsset: [...state.userAsset],
          unSavedChanges: true,
        };
      }
      
      let layoutSettingUpdate = { ...state.layoutSetting };;
      let slideSetting = [...state.slidesData];
      let introductionSlideUpdate = null;
      let backgroundSettingUpdate = { ...state.backgroundSetting }
      let clickAndRevealDataUpdate = null;
      let panaromaDataUpdate = null;
      let topicsUpdate = [];
      let topic_settingUpdate = {}; 
      let resultScreenUpdate = null;

      if(state.globalSetting.introductionSlides)
          introductionSlideUpdate = [...state.globalSetting.introductionSlides]
            if(state.globalSetting.clickAndRevealData)
              clickAndRevealDataUpdate = { ...state.globalSetting.clickAndRevealData };
                if(state.globalSetting.panoramaData)
                  panaromaDataUpdate = { ...state.globalSetting.panoramaData };
                    if(state.globalSetting.topic_setting){
                      topic_settingUpdate = { ...state.globalSetting.topic_setting }
                      topicsUpdate = [...state.globalSetting.topic_setting.topics]
                    }
                      if(state.globalSetting.resultScreen)
                        resultScreenUpdate = {...state.globalSetting.resultScreen}

      if('image' === action.payload.asset){
        const { path } = action.payload;
        const isBackgroundSettingEnabled = state.menuSetting.menu.find(b => b.id === 'background');
        const isHotSpotEnabled = state.menuSetting.menu.find(h => h.id === 'hotspot');
        const setDefaultSlideBackground = () => {
          layoutSettingUpdate.slides.forEach((v, i) => {
            if(state.interactionId === "23" || state.interactionId === "5" || state.interactionId === "7"){
              if(v.imageUrl !== state.defaultPlaceholder.imagePlaceholder && v.imageUrl === path ){
                  v.imageUrl = state.defaultPlaceholder.imagePlaceholder
                }
            }else if('defaultSlideUrl' in v && slideSetting[i].imageUrl !== v.defaultSlideUrl
              && slideSetting[i].imageUrl === path){
              slideSetting[i].imageUrl = v.defaultSlideUrl;
            }else if('defaultSlideUrl' in v === !1 && v.imageUrl !== slideSetting[i].imageUrl
            && v.imageUrl === path ){
              v.imageUrl = slideSetting[i].imageUrl
            }else if('defaultSlideUrl' in v === !1 && v.imageUrl !== slideSetting[i].imageUrl
            && slideSetting[i].imageUrl === path){
              slideSetting[i].imageUrl = v.imageUrl;
            }else if(v.imageUrl !== state.defaultPlaceholder.imagePlaceholder && v.imageUrl === path &&
              slideSetting[i].imageUrl === path){
              v.imageUrl = state.defaultPlaceholder.imagePlaceholder
              slideSetting[i].imageUrl = state.defaultPlaceholder.imagePlaceholder;
            }
          })
        }
 
        const setDefaultIntroAndLogoImage = () => { 
          // Set the default logo image
          introductionSlideUpdate[0].logoImage !== state.globalSetting.defaultIntroductionPlaceholder &&
          introductionSlideUpdate[0].logoImage === path && (introductionSlideUpdate[0].logoImage = state.globalSetting.defaultIntroductionPlaceholder)
         
          // Set the default introduction slide background
          if(state.interactionId !== "2" && state.interactionId !== "6" && state.interactionId !== "4"){
            introductionSlideUpdate[0].imageUrl !== state.globalSetting.defaultInteractionPlaceholder &&
            introductionSlideUpdate[0].imageUrl === path && (introductionSlideUpdate[0].imageUrl = state.globalSetting.defaultInteractionPlaceholder)
          }else 
            introductionSlideUpdate[0].imageUrl = state.globalSetting.defaultIntroductionPlaceholder
        }
        
        if(isBackgroundSettingEnabled ){
          if('homeSlide' in backgroundSettingUpdate && 'topicSlide' in backgroundSettingUpdate){
            const { homeSlide, topicSlide: { slides } } = backgroundSettingUpdate;
            homeSlide.bg_image !== state.defaultPlaceholder.imagePlaceholder && homeSlide.bg_image === path &&
            (homeSlide.bg_image = state.defaultPlaceholder.imagePlaceholder)
            slides.forEach(s => {
              if(s.bg_image !== s.defaultSlideUrl && s.bg_image === path)
               s.bg_image = s.defaultSlideUrl;
            })
          }else if(backgroundSettingUpdate.bg_image !== backgroundSettingUpdate.default_image &&
              backgroundSettingUpdate.bg_image === path)
              backgroundSettingUpdate.bg_image = backgroundSettingUpdate.default_image;
      } 
      if(isHotSpotEnabled){
        clickAndRevealDataUpdate && clickAndRevealDataUpdate.hotspots.forEach(c => {
          if(c.modalImageUrl !== state.defaultPlaceholder.imagePlaceholder && c.modalImageUrl === path){
            c.modalImageUrl = state.defaultPlaceholder.imagePlaceholder;
          }
          if(c.selectedImage !== state.defaultPlaceholder.imagePlaceholder && c.selectedImage === path){
            c.selectedImage = state.defaultPlaceholder.imagePlaceholder
          }
          if(clickAndRevealDataUpdate.mascotData.mastcotStandingImage === path){
            clickAndRevealDataUpdate.mascotData.mastcotStandingImage = state.defaultPlaceholder.imagePlaceholder;
          }
        })
        panaromaDataUpdate && panaromaDataUpdate.hotspots.forEach(p => {
          if(p.modalImageUrl !== state.defaultPlaceholder.imagePlaceholder && p.modalImageUrl === path){
          p.modalImageUrl = state.defaultPlaceholder.imagePlaceholder;
          }
          if(p.selectedImage !== state.defaultPlaceholder.imagePlaceholder && p.selectedImage === path){
            p.selectedImage = state.defaultPlaceholder.imagePlaceholder
          }
        })
      }

      if('resultScreenLogo' in resultScreenUpdate){
        if(resultScreenUpdate.resultScreenLogo === path){
          resultScreenUpdate.resultScreenLogo = state.defaultPlaceholder.imagePlaceholder;
        }
      }

        if(introductionSlideUpdate && introductionSlideUpdate[0].toggleFlag && !slideSetting[0].imageUrl){
          setDefaultIntroAndLogoImage();
        }else if('topicSlide' in layoutSettingUpdate){
          layoutSettingUpdate.topicSlide.slides.forEach((item, idx) => {
            if(slideSetting[idx].imageUrl !== state.globalSetting.defaultInteractionPlaceholder &&
              slideSetting[idx].imageUrl === path){
                slideSetting[idx].imageUrl = state.defaultPlaceholder.imagePlaceholder;
              }
          })
        }else if(introductionSlideUpdate && introductionSlideUpdate[0].toggleFlag && slideSetting[0].imageUrl){
          setDefaultIntroAndLogoImage();
          setDefaultSlideBackground()
        }else{
          setDefaultSlideBackground();
      }
      // const isBackgroundSettingEnabled = state.menuSetting.menu.find(b => b.id === 'background');
      if(isBackgroundSettingEnabled){
      topicsUpdate.forEach((v, i) => {
        if(state.defaultPlaceholder.imagePlaceholder !== v.imageUrl
          && v.imageUrl === path){
          v.imageUrl = state.defaultPlaceholder.imagePlaceholder;
        }
      })
      }
    }
    if('pdf'=== action.payload.asset){
      const { path } = action.payload;
           layoutSettingUpdate.slides.forEach((v, i) => {
            if(v.pdfUrl === path && v.pdfUrl !== state.defaultPlaceholder.pdfPlaceholder){
              v.pdfUrl = state.defaultPlaceholder.pdfPlaceholder
            }
          })
      }
    if('video'=== action.payload.asset){
      const { path } = action.payload;
      const isHotSpotEnabled = state.menuSetting.menu.find(h => h.id === 'hotspot');
      if(isHotSpotEnabled){
        clickAndRevealDataUpdate && clickAndRevealDataUpdate.hotspots.forEach(c => {
          if(c.modalVideoUrl !== state.defaultPlaceholder.videoPlaceholder &&c.modalVideoUrl === path){
            c.modalVideoUrl = state.defaultPlaceholder.videoPlaceholder;
          }
        })  
        panaromaDataUpdate && panaromaDataUpdate.hotspots.forEach((p) => {
          if(p.modalVideoUrl !== state.defaultPlaceholder.videoPlaceholder && p.modalVideoUrl === path){
          p.modalVideoUrl = state.defaultPlaceholder.videoPlaceholder;
          }
        })
      } 
      if('slides' in layoutSettingUpdate){
           layoutSettingUpdate.slides.forEach((v, i) => {
            if(v.videoUrl === path && v.videoUrl !== state.defaultPlaceholder.videoPlaceholder){
              v.videoUrl = state.defaultPlaceholder.videoPlaceholder
            }
            if(slideSetting[i].videoUrl !== state.defaultPlaceholder.videoPlaceholder && slideSetting[i].videoUrl === path){
              slideSetting[i].videoUrl = state.defaultPlaceholder.videoPlaceholder;
            }
          })
        }
        if('topicSlide' in layoutSettingUpdate){
        layoutSettingUpdate.topicSlide.slides.forEach((v, i) => {
          if(v.videoUrl === path && v.videoUrl !== state.defaultPlaceholder.videoPlaceholder){
            v.videoUrl = state.defaultPlaceholder.videoPlaceholder;
          }
          if(slideSetting[i].videoUrl !== state.defaultPlaceholder.videoPlaceholder && slideSetting[i].videoUrl === path){
            slideSetting[i].videoUrl = state.defaultPlaceholder.videoPlaceholder;
          }      
        })
        }
        else{
          slideSetting.forEach((v, i) => {
            if(v.videoUrl === path && v.videoUrl !== state.defaultPlaceholder.videoPlaceholder){
              v.videoUrl = state.defaultPlaceholder.videoPlaceholder;
            }
            if(slideSetting[i].videoUrl !== state.defaultPlaceholder.videoPlaceholder && slideSetting[i].videoUrl === path){
                slideSetting[i].videoUrl = state.defaultPlaceholder.videoPlaceholder;
            }
          })
        }
    }
      return {
        ...state,
        userAsset: action.payload.userAsset,
        layoutSetting: layoutSettingUpdate,
        slidesData: slideSetting,
        backgroundSetting: backgroundSettingUpdate,
        globalSetting: {
          ...state.globalSetting,
          introductionSlides: introductionSlideUpdate,
          clickAndRevealData:clickAndRevealDataUpdate,
          panoramaData: panaromaDataUpdate,
          resultScreen:resultScreenUpdate,
          topic_setting:
          { ...topic_settingUpdate, 
            topics : topicsUpdate }
        },
        unSavedChanges: true,
      };
    case actionTypes.FETCH_DEFAULT_BUILDER_SETTING_SUCCESS:
      const { data } = action.payload;
      let slidesData = [];
      let introClosureData = [];
      let simpleInteractionIntroductionSlide = {};
      let _backgroundSetting = JSON.parse(data.backgroundSetting)
      if (action.payload.data.interactionId == interactionType.SIMPLE) {
        JSON.parse(action.payload.data.layoutSetting).topicSlide &&
          JSON.parse(action.payload.data.layoutSetting).topicSlide.slides.forEach((slide, index) => {
            slidesData[index] = {
              imageUrl: JSON.parse(action.payload.data.layoutSetting).topicSlide.slides[index].imageUrl,
              videoUrl: JSON.parse(action.payload.data.layoutSetting).topicSlide.slides[index].videoUrl,
              textContent: JSON.parse(action.payload.data.layoutSetting).topicSlide.slides[index].textContent,
              slideTitle: JSON.parse(action.payload.data.layoutSetting).topicSlide.slides[index].slideTitle,
              menuTitle: JSON.parse(action.payload.data.layoutSetting).topicSlide.slides[index].menuTitle,
              captionUrl: JSON.parse(action.payload.data.layoutSetting).topicSlide.slides[index].captionUrl,
              isExternalVideo: JSON.parse(action.payload.data.layoutSetting).topicSlide.slides[index].isExternalVideo,
              altText: JSON.parse(action.payload.data.layoutSetting).topicSlide.slides[index].altText,
            };
          });
      } else if(action.payload.data.interactionId == interactionType.PINIT){
         let parsedSlides = JSON.parse(action.payload.data.layoutSetting);
         parsedSlides.slides && parsedSlides.slides.forEach((slide, index) => {
            slidesData[index] = {
              imageUrl: slide.questionData.imageUrl,
              videoUrl: null,
              textContent: slide.questionData.questionContent,
              slideTitle: null,
              menuTitle: null,
              captionUrl: null,
              isExternalVideo: null,
              altText: null,
            }
         })
      } else if(action.payload.data.interactionId == interactionType.PARALLAX || 
        action.payload.data.interactionId == interactionType.GAMEBASED) {
        let parallaxSlide = JSON.parse(action.payload.data.layoutSetting);
        parallaxSlide.slides &&
        parallaxSlide.slides.forEach((slide, index) => {
          slidesData[index] = {
            imageUrl: slide.imageUrl,
            bgImgUrl: slide.bgImgUrl || _backgroundSetting.default_image, 
            videoUrl: slide.videoUrl,
            textContent: slide.textContent,
            slideTitle: slide.slideTitle,
            menuTitle: slide.menuTitle,
            captionUrl: slide.captionUrl,
            isExternalVideo: slide.isExternalVideo,
            altText: slide.altText,
          };
        })
      }else{
        JSON.parse(action.payload.data.layoutSetting).slides &&
          JSON.parse(action.payload.data.layoutSetting).slides.forEach((slide, index) => {
            slidesData[index] = {
              imageUrl: slide.imageUrl,
              videoUrl: slide.videoUrl,
              textContent: slide.textContent,
              slideTitle: slide.slideTitle,
              menuTitle: slide.menuTitle,
              captionUrl: slide.captionUrl,
              isExternalVideo: slide.isExternalVideo,
              altText: slide.altText,
            }
         })
      }
      switch (parseInt(action.payload.data.interactionId)) {
        case interactionType.PARALLAX:
        case interactionType.TIMELINE:
        case interactionType.CLICK_AND_REVEAL:
        case interactionType.PANORAMA:
        case interactionType.GAMEBASED:
        case interactionType.OPEN_BOOK_CHALLENGE:
        case interactionType.INTERACTIVE_VIDEO:
          introClosureData[0] = {
            imageUrl: JSON.parse(action.payload.data.globalSetting).introductionSlides[0].imageUrl,
            videoUrl: JSON.parse(action.payload.data.globalSetting).introductionSlides[0].videoUrl,
            textContent: JSON.parse(action.payload.data.globalSetting).introductionSlides[0].textContent,
            slideTitle: JSON.parse(action.payload.data.globalSetting).introductionSlides[0].slideTitle,
            menuTitle: JSON.parse(action.payload.data.globalSetting).introductionSlides[0].menuTitle,
            toggleFlag: JSON.parse(action.payload.data.globalSetting).introductionSlides[0].toggleFlag,
            captionUrl: JSON.parse(action.payload.data.globalSetting).introductionSlides[0].captionUrl,
            isExternalVideo: JSON.parse(action.payload.data.globalSetting).introductionSlides[0].isExternalVideo,
          };

          introClosureData[1] = {
            imageUrl: JSON.parse(action.payload.data.globalSetting).introductionSlides[1].imageUrl,
            videoUrl: JSON.parse(action.payload.data.globalSetting).introductionSlides[1].videoUrl,
            textContent: JSON.parse(action.payload.data.globalSetting).introductionSlides[1].textContent,
            slideTitle: JSON.parse(action.payload.data.globalSetting).introductionSlides[1].slideTitle,
            menuTitle: JSON.parse(action.payload.data.globalSetting).introductionSlides[1].menuTitle,
            toggleFlag: JSON.parse(action.payload.data.globalSetting).introductionSlides[1].toggleFlag,
            captionUrl: JSON.parse(action.payload.data.globalSetting).introductionSlides[1].captionUrl,
            isExternalVideo: JSON.parse(action.payload.data.globalSetting).introductionSlides[1].isExternalVideo,
          };
          break;

        case interactionType.CLICK_AND_REVEAL_ASSESSMENT:
        case interactionType.KNIFE_HIT:
        case interactionType.BALLOON_HIT:
        case interactionType.BOWLING:
        case interactionType.CHEES_AND_MOUSE:
        case interactionType.MOUNTAIN_CLIMB:
        case interactionType.HORSE_RIDING:
        case interactionType.PIZZA:
        case interactionType.ROULETTE:
        case interactionType.PARKING_LOT:
        case interactionType.FLIPCARD:
        case interactionType.PINIT:
        case interactionType.READ_OR_ANSWER:
          case interactionType.SUPER_GOLDIO:
          case interactionType.SPIN_THE_WHEEL:
            case interactionType.SPIN_THE_WHEEL_NEW:  
          introClosureData[0] = {
            imageUrl: JSON.parse(action.payload.data.globalSetting).introductionSlides[0].imageUrl,
            videoUrl: JSON.parse(action.payload.data.globalSetting).introductionSlides[0].videoUrl,
            textContent: JSON.parse(action.payload.data.globalSetting).introductionSlides[0].textContent,
            slideTitle: JSON.parse(action.payload.data.globalSetting).introductionSlides[0].slideTitle,
            menuTitle: JSON.parse(action.payload.data.globalSetting).introductionSlides[0].menuTitle,
            toggleFlag: JSON.parse(action.payload.data.globalSetting).introductionSlides[0].toggleFlag,
            captionUrl: JSON.parse(action.payload.data.globalSetting).introductionSlides[0].captionUrl,
            isExternalVideo: JSON.parse(action.payload.data.globalSetting).introductionSlides[0].isExternalVideo,
          };
          break;

        default:
          break;
      }

      if (action.payload.data.interactionId == interactionType.SIMPLE) {
        simpleInteractionIntroductionSlide = {
          interactionTitle: JSON.parse(action.payload.data.layoutSetting).homeSlide.simpleInteractionIntroductionSlide.interactionTitle,
          interactionDescription: JSON.parse(action.payload.data.layoutSetting).homeSlide.simpleInteractionIntroductionSlide.interactionDescription,
          interactionOverlayColor: JSON.parse(action.payload.data.layoutSetting).homeSlide.simpleInteractionIntroductionSlide.interactionOverlayColor,
        };
      }

      if (data.interactionId == interactionType.CLICK_AND_REVEAL || 
        data.interactionId == interactionType.CLICK_AND_REVEAL_ASSESSMENT) {
       const { bg_image }  = _backgroundSetting;
       if (bg_image && bg_image.includes('Vertical+Parallaxbg')) {
         _backgroundSetting.bg_image = bg_image.replace('Vertical+Parallaxbg', 'Vertical-Parallaxbg')
       }
     }

      const globalSettings = getGlobalSettings(action.payload.data.globalSetting);
      return {
        ...state,
        demovideourl: action.payload.data.demovideourl,
        demovideourlforvc: action.payload.data.demovideourlforvc,
        textConstant: JSON.parse(action.payload.data.textConstant),
        status: action.payload.data.status,
        defaultPlaceholder: action.payload.data.defaultPlaceholder,
        defaultAltText: action.payload.data.defaultAltText,
        interactionId: action.payload.data.interactionId,
        userInteractionId: action.payload.data.userInteractionId,
        layoutSetting: JSON.parse(action.payload.data.layoutSetting),
        backgroundSetting: _backgroundSetting,
        menuSetting: JSON.parse(action.payload.data.menuSetting),
        multiSetting: payload.data.multiSetting,
        currentInteractionSetting:
          payload.data.multiSetting && payload.data.multiSetting.layoutSetting ? payload.data.multiSetting.layoutSetting.current : null,
        globalSetting: globalSettings,
        defaultAsset: action.payload.data.defaultAsset,
        userAsset: action.payload.data.userAsset,
        assessment: JSON.parse(action.payload.data.assessment ? action.payload.data.assessment : null),
        //currentSlide: action.payload.data.interactionId == interactionType.SIMPLE ? 'wrapper' : 0,
        currentSlide:
          globalSettings.introductionSlides && globalSettings.introductionSlides[0].toggleFlag
            ? 'intro'
            : JSON.parse(action.payload.data.layoutSetting) &&
              JSON.parse(action.payload.data.layoutSetting).homeSlide &&
              JSON.parse(action.payload.data.layoutSetting).homeSlide.slides
              ? 'wrapper'
              : 0,
        //currentSlide: globalSettings.introductionSlides && globalSettings.introductionSlides[0].toggleFlag ? 'intro' : 0,
        slidesData,
        interactionTitle: action.payload.data.interactionTitle,
        fontCSSUrl: action.payload.data.fontCSSUrl,
        usedFontList: [],
        introClosureData,
        unSavedChanges: false,
        simpleInteractionIntroductionSlide,

        interactionName: action.payload.data.interactionName
          ? action.payload.data.interactionName
          : JSON.parse(action.payload.data.layoutSetting).interactionName,
      };
    case actionTypes.UPDATE_LAYOUT_SETTING:
      return { ...state, layoutSetting: { ...state.layoutSetting, ...payload }, unSavedChanges: true };
    case actionTypes.UPDATE_BACKGROUND_SETTING:
      return { ...state, backgroundSetting: { ...state.backgroundSetting, default_image: state.backgroundSetting.bg_image, ...payload }, unSavedChanges: true };
    case actionTypes.UPDATE_GLOBAL_SETTING:
      return { ...state, globalSetting: { ...state.globalSetting, ...payload }, unSavedChanges: true };
    case actionTypes.UPDATE_DEFAULT_ASSET:
      return { ...state, defaultAsset: action.payload };
    case actionTypes.UPDATE_USER_ASSET:
      const { userAsset } = action.payload.data;
      if('videoUrl' in state.layoutSetting){
        const url = userAsset.assettypeid === 2 ?
                    (userAsset.path === '' ?
                    userAsset.youtubeurl : userAsset.path) :
                    state.layoutSetting.videoUrl;
        return {
          ...state,
          userAsset: [userAsset, ...state.userAsset],
          layoutSetting: { ...state.layoutSetting, videoUrl: url },
          fontCSSUrl: action.payload.data.fontCSSUrl
        }
      }else{
        return { ...state, userAsset: [userAsset, ...state.userAsset], fontCSSUrl: action.payload.data.fontCSSUrl };
      }
    case actionTypes.SAVE_BUILDER_SUCCESS:
      return { ...state, userInteractionId: action.payload.data.userInteractionId, unSavedChanges: false };
    case actionTypes.UPDATE_BG_COLOR:
      return { ...state, bg_color: action.payload, unSavedChanges: true };
    case actionTypes.UPDATE_SIMPLE_INTRODUCTION_SLIDE:
      return { ...state, simpleInteractionIntroductionSlide: action.payload, unSavedChanges: true };
    case actionTypes.UPDATE_BG_IMAGE:
      return { ...state, bg_image: action.payload, unSavedChanges: true };
    case actionTypes.SET_CURRENT_SLIDE:
      return { ...state, currentSlide: action.payload };
    case actionTypes.UPDATE_SLIDE_VIDEO:
      return { ...state, slideVideoInfo: action.payload, unSavedChanges: true };
    case actionTypes.UPDATE_SLIDE_ITEM:
      return { ...state, slideItem: action.payload, unSavedChanges: true };
    case actionTypes.UPDATE_SLIDES_DATA:
      return { ...state, slidesData: action.payload, unSavedChanges: true };
    case actionTypes.UPDATE_INTRODUCTION_CLOSURE_DATA:
      return { ...state, introClosureData: { ...state.introClosureData, ...payload }, unSavedChanges: true };
    case actionTypes.UPDATE_INTERACTION_TITLE:
      return { ...state, interactionTitle: action.payload, unSavedChanges: true };
    case actionTypes.UPDATE_SELECTED_AUDIO:
      if (isNaN(action.payload.selectedSlideId)) {
        state.globalSetting.audioData[action.payload.selectedSlideId].src = action.payload.audioAsset.path;
        state.globalSetting.audioData[action.payload.selectedSlideId].name = action.payload.audioAsset.name;
        state.globalSetting.audioData[action.payload.selectedSlideId].duration = action.payload.audioAsset.duration;
        state.globalSetting.audioData[action.payload.selectedSlideId].size = action.payload.audioAsset.size;
      } else {
        state.globalSetting.audioData.audioSlideData.map((updatedAudio, index) => {
          if (index == parseInt(action.payload.selectedSlideId)) {
            updatedAudio.src = action.payload.audioAsset.path;
            updatedAudio.name = action.payload.audioAsset.name;
            updatedAudio.duration = action.payload.audioAsset.duration;
            updatedAudio.size = action.payload.audioAsset.size;
            return updatedAudio;
          }
          return updatedAudio;
        });
      }
      return {
        ...state,
        globalSetting: { ...state.globalSetting },
        unSavedChanges: true,
      };
    case actionTypes.REMOVE_SELECTED_AUDIO:
      if (isNaN(action.payload.selectedSlideId)) {
        state.globalSetting.audioData[action.payload.selectedSlideId].src = '';
        state.globalSetting.audioData[action.payload.selectedSlideId].name = '';
        state.globalSetting.audioData[action.payload.selectedSlideId].duration = '';
        state.globalSetting.audioData[action.payload.selectedSlideId].size = '';
      } else {
        state.globalSetting.audioData.audioSlideData.map((updatedAudio, index) => {
          if (index == parseInt(action.payload.selectedSlideId)) {
            updatedAudio.src = '';
            updatedAudio.name = '';
            updatedAudio.duration = '';
            updatedAudio.size = '';
            return updatedAudio;
          }
          return updatedAudio;
        });
      }
      return {
        ...state,
        globalSetting: { ...state.globalSetting },
        unSavedChanges: true,
      };
    case actionTypes.UPDATE_AUDIO_MODE:
      if (isNaN(action.payload.selectedSlideId)) {
        state.globalSetting.audioData[action.payload.selectedSlideId].mode = action.payload.mode;
      } else {
        state.globalSetting.audioData.audioSlideData.map((updatedAudio, index) => {
          if (index == parseInt(action.payload.selectedSlideId)) {
            updatedAudio.mode = action.payload.mode;
            return updatedAudio;
          }
          return updatedAudio;
        });
      }
      return {
        ...state,
        globalSetting: { ...state.globalSetting },
        unSavedChanges: true,
      };
    case actionTypes.UPDATE_ACROSS_ALL_AUDIO_FLAG:
      state.globalSetting.audioData.acrossAllSlides.checked = action.payload.acrossAllFlag;
      return {
        ...state,
        globalSetting: { ...state.globalSetting },
        unSavedChanges: true,
      };
    case actionTypes.UPDATE_TIME_INTERVAL_FONT_SETTING:
      return { ...state, globalSetting: action.payload, unSavedChanges: true };

    case actionTypes.UPDATE_INTRODUCTION_SETTING:
      return { ...state, introductionSetting: action.payload, unSavedChanges: true };
    case actionTypes.UPDATE_CLOSURE_SETTING:
      return { ...state, closureSetting: action.payload, unSavedChanges: true };
    case actionTypes.UPDATE_TOGGLE_FLAG_SETTING:
      return { ...state, toggleFlagSetting: action.payload, unSavedChanges: true };
    case actionTypes.UPDATE_USER_FONTLIST:
      return { ...state, userAsset: action.payload };
    case actionTypes.UPDATE_USED_FONT_LIST:
      return !state.usedFontList.includes(action.payload) ? { ...state, usedFontList: [...state.usedFontList, action.payload] } : { ...state };
    case actionTypes.UPDATE_HOTSPOT_DATA:
      return {
        ...state,
        globalSetting: {
          ...state.globalSetting,
          clickAndRevealData: payload.hotspot,
        },
        unSavedChanges: payload.firstLoad ? false : true,
      };
    case actionTypes.UPDATE_PANORAMA_HOTSPOT_DATA:
      return {
        ...state,
        globalSetting: {
          ...state.globalSetting,
          panoramaData: payload.hotspot,
        },
        unSavedChanges: payload.firstLoad ? false : true,
      };
    case actionTypes.CLEAR_BUILDER_SETTING:
      return action.payload;
    case actionTypes.RESET_UNSAVED_CHANGES:
      return { ...state, unSavedChanges: false };
    case actionTypes.SET_UNSAVED_CHANGES:
      return { ...state, unSavedChanges: action.payload };
    case actionTypes.ADD_NEW_TOPICS:
      return {
        ...state,
        globalSetting: {
          ...state.globalSetting,
          topic_setting: { ...state.globalSetting.topic_setting, topics: [...state.globalSetting.topic_setting.topics, action.payload] },
        },
        unSavedChanges: true,
      };

    case actionTypes.ADD_NEW_TOPICS_LAYOUT:
      return {
        ...state,
        layoutSetting: {
          ...state.layoutSetting,
          topicSlide: { ...state.layoutSetting.topicSlide, slides: [...state.layoutSetting.topicSlide.slides, action.payload] },
        },
        unSavedChanges: true,
      };

    case actionTypes.ADD_NEW_TOPIC_BACKGROUND:
      return {
        ...state,
        backgroundSetting: {
          ...state.backgroundSetting,
          topicSlide: { ...state.backgroundSetting.topicSlide, slides: [...state.backgroundSetting.topicSlide.slides, action.payload] },
        },
        unSavedChanges: true,
      };

    case actionTypes.ADD_NEW_SLIDE_DATA:
      return {
        ...state,
        slidesData: [...state.slidesData, action.payload],
        unSavedChanges: true,
      };

    case actionTypes.UPDATE_CURRENT_HOTSPOT_DATA:
      return { ...state, globalSetting: { ...state.globalSetting, ...payload }, unSavedChanges: true };

    case actionTypes.UPDATE_GAMEBASED_FLAG_TOGGLE:
      return { ...state, layoutSetting: action.payload, unSavedChanges: true };

    case actionTypes.CHANGE_TO_CURRENT_INTERACTION_SETTING:
      let multiInteractionSetting = state.multiSetting;
      let { layoutSetting, menuSetting, globalSetting, backgroundSetting } = multiInteractionSetting;

      return {
        ...state,
        layoutSetting: { ...layoutSetting[payload.currentSettingInteraction] },
        menuSetting: { ...menuSetting[payload.currentSettingInteraction] },
        globalSetting: { ...globalSetting[payload.currentSettingInteraction] },
        backgroundSetting: { ...backgroundSetting },
        currentInteractionSetting: payload.currentSettingInteraction,
      };

    case actionTypes.UPDATE_MULTI_INTERACTION_SETTING:
      let multiSetting = _.cloneDeep(state.multiSetting);

      multiSetting.layoutSetting[payload.currentSettingInteraction] = payload.updatedSetting.layoutSetting;
      multiSetting.menuSetting[payload.currentSettingInteraction] = payload.updatedSetting.menuSetting;
      multiSetting.globalSetting[payload.currentSettingInteraction] = payload.updatedSetting.globalSetting;
      multiSetting.backgroundSetting = payload.updatedSetting.backgroundSetting;
      return {
        ...state,
        multiSetting: { ...state.multiSetting, ...multiSetting },
      };
    default:
      return state;
  }
};

export default builderReducer;
