import React from 'react';

export default props => {
  return (
    <>
      <div id='spinner' className='spinner' hidden>
        <div className='containLoader'>
          <div className='circleGroup circle-1'></div>
          <div className='circleGroup circle-2'></div>
          <div className='circleGroup circle-3'></div>
          <div className='circleGroup circle-4'></div>
          <div className='circleGroup circle-5'></div>
          <div className='circleGroup circle-6'></div>
          <div className='circleGroup circle-7'></div>
          <div className='circleGroup circle-8'></div>
        </div>
      </div>{' '}
    </>
  );
};
