import * as actionTypes from './myWorkActionTypes';

const myWorkReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INTERACTIONS_SETTING_SUCCESS:
      let {userInteractions = []} = state;

       return {
          ...state,
          userInteractions: userInteractions.concat(action.payload.data.interactions),
          startIndex: action.payload.data.startIndex,
          countOfInteraction: action.payload.data.countOfInteraction
        
      };

    case actionTypes.UPDATE_XLIFF_CONTENT_SUCCESS:
      return {
        ...state,
        userInteractions: action.payload.data.userInteractions,
        startIndex: action.payload.data.startIndex,
        countOfInteraction: action.payload.data.countOfInteraction
      };

    case actionTypes.CLEAR_USER_INTERACTION_SETTING:
      return { ...state, userInteractions: [], startIndex: 0, countOfInteraction: 0 };

      case actionTypes.DELETE_INTERACTION_ERROR:
        return {  ...state,
          isDeleted: action.payload.data.isdeleted
        };

        case actionTypes.DELETE_INTERACTION_SUCCESS:
          return {  ...state,
            userInteractions: action.payload.data.userInteractions,
            startIndex: action.payload.data.startIndex,
            countOfInteraction: action.payload.data.countOfInteraction,
            isDeleted: action.payload.data.isdeleted,
        
          };
    default:
      return state;
  }
};
export default myWorkReducer;
