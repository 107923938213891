import { Route, Switch } from "react-router-dom";
import React from "react";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import asyncComponent from "./components/AsyncComponent";
import Login from "./containers/login/Login";
import Registration from "./containers/registration/Registration";

import EmailVerification from "./containers/emailVerification/EmailVerification";

import ProRegistration from "./containers/proRegistration/ProRegistration";
import ForgotPassword from "./containers/forgotPassword/ForgotPassword";
import ResetPassword from "./containers/forgotPassword/resetPassword/ResetPassword";
import Preview from "./containers/preview/Preview";

const AsyncInteractions = asyncComponent(() =>
  import("./containers/interactions/Interactions")
);
const AsyncExperience = asyncComponent(() =>
  import("./containers/experience/Experience")
);
const AsyncMyProfileForm = asyncComponent(() =>
  import("./containers/myProfile/MyProfileForm")
);
const AsyncMyWork = asyncComponent(() => import("./containers/mywork/MyWork"));
const AsyncMyAccountDetails = asyncComponent(() =>
  import("./containers/myAccount/MyAccountDetails")
);
const AsyncFaq = asyncComponent(() => import("./containers/FAQ/Faq"));
const AsyncLms = asyncComponent(() => import("./components/Lms/Lms"));
const AsyncBuilder = asyncComponent(() =>
  import("./containers/builder/Builder")
);
const AsyncPreview = asyncComponent(() =>
  import("./containers/preview/Preview")
);

const location = window;

export default ({ childProps }) => (
  <Switch>
    <AuthenticatedRoute
      path="/interactions"
      exact
      component={AsyncInteractions}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/experience"
      exact
      component={AsyncExperience}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/mywork"
      exact
      component={AsyncMyWork}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/myprofile"
      exact
      component={AsyncMyProfileForm}
      props={childProps}
    />

    <AuthenticatedRoute
      path={[
        "/builder/:interactionId/",
        "/builder/:interactionId/:userInteractionId",
      ]}
      exact
      component={AsyncBuilder}
      props={childProps}
    />
    <Route
      exact
      path="/default/preview/:interactionId"
      render={(props) => <Preview {...childProps} key={location.pathname} />}
    />

    <AuthenticatedRoute
      path="/preview/rvc/:interactionId"
      exact
      component={AsyncPreview}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/preview/r2020/:interactionId"
      exact
      component={AsyncPreview}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/myaccount"
      exact
      component={AsyncMyAccountDetails}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/faq"
      props={childProps}
      exact
      component={AsyncFaq}
    />
    <AuthenticatedRoute
      path="/lms"
      props={childProps}
      exact
      component={AsyncLms}
    />
    <Route
      exact
      path="/"
      render={(props) => <Login {...childProps} key={location.pathname} />}
    />

    <Route
      exact
      path="/login/:token/:email/:completeProfileFlag/:encryptedId"
      render={(props) => <Login {...childProps} key={location.pathname} />}
    />

    <Route
      exact
      path="/login/:email"
      render={(props) => <Login {...childProps} key={location.pathname} />}
    />

    <Route
      exact
      path="/login"
      render={(props) => <Login {...childProps} key={location.pathname} />}
    />

    <Route
      exact
      path="/registration"
      render={(props) => <Registration key={location.pathname} />}
    />

    <Route
      exact
      path="/proregistration"
      render={(props) => <ProRegistration key={location.pathname} />}
    />

    <Route
      exact
      path="/emailverification"
      render={(props) => <EmailVerification key={location.pathname} />}
    />

    <Route
      exact
      path="/forgotpassword"
      render={(props) => <ForgotPassword key={location.pathname} />}
    />

    <Route
      exact
      path="/resetpassword"
      render={(props) => <ResetPassword key={location.pathname} />}
    />
  </Switch>
);
