import * as actionTypes from './proRegisterActionTypes';

import messageType from '../../helpers/enums/messageTypes';
import util from '../../helpers/utils/util';
import httpVerb from '../../helpers/enums/httpVerbs';
let apiParams = {};

export function EmployeeRegistration(user) {
  return function (dispatch) {
    let headers = {
      'Content-Type': 'application/json',
    };
    util.toggleSpinner(false);

    apiParams = { user };

    return util
      .axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/proRegistration`, apiParams, { headers })
      .then((response) => {
        if (response.data.error === undefined) {
          sessionStorage.setItem('email', response.data.email);
          dispatch({
            type: actionTypes.PRO_REGISTRATION_SUCCESS,
            payload: response.data,
          });

          util.displayMessage(messageType.SUCCESS, response.data.message);
          util.toggleSpinner(true);
        } else {
          dispatch({
            type: actionTypes.PRO_REGISTRATION_ERROR,
            payload: response.data,
          });

          util.displayMessage(messageType.WARNING, response.data.message);
          util.toggleSpinner(true);
        }
        return response.data;
      })
      .catch((error) => {
        util.displayMessage(messageType.ERROR, error);
        util.toggleSpinner(true);
      });
  };
}
