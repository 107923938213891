/* eslint-disable no-restricted-globals */

import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import SocialLogin from "./socialLogin/SocialLogin";
import Features from "../../components/Features";
import { getAllUserInfo } from "./../myProfile/profileActions";
import { UserLogin, getDecryptedData } from "./loginActions";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { UITextMessages } from "../../helpers/constants/uiTextMessages";
import storageType from "../../helpers/enums/storageTypes";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from 'react';

import { Row, Col, Form, Icon, Input, Button, Checkbox, Divider } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
//Less Import
import "./login.less";
import ReactGA from "react-ga";

//Image import
import { ReactComponent as Lock } from "../../assets/images/pass.svg";
import Logo from "./../../assets/images/Raptivity-logo.png";
import { ReactComponent as OrLogo } from "../../assets/images/or.svg";
import { ReactComponent as Mail } from "../../assets/images/mail.svg";
import { ReactComponent as LogoNew } from "../../assets/images/logo_new.svg";
import util from "../../helpers/utils/util";
import messageType from "../../helpers/enums/messageTypes";

import paypalFormType from "../../helpers/enums/paypalFormTypes";
import { rgb } from "d3";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      rememberMe: false,
      recaptchaValue:""
    };
    this.recaptchaRef = React.createRef(); 
  }

  componentDidMount() {
    const { dispatch } = this.props;
    ReactGA.initialize("UA-1052826-1");
    ReactGA.pageview(window.location.pathname);

    let token = "";
    let encId = "";
    let queryString = window.location.href;
    if (queryString.includes("email")) {
      this.setState({ username: window.location.href.split("email=")[1] });
    } else if (queryString.includes("unauthorized")) {
      let socialMediaEmail = util.getParameterByName("unauthorized");
      let userTypeId = util.getParameterByName("unauthorizedUserTypeId");
      encId = util.getParameterByName("encryptedId");
      localStorage.setItem("encId", encId);
      sessionStorage.setItem("encId", encId);
      dispatch(getDecryptedData(socialMediaEmail, userTypeId));
    } else if (queryString.includes("source=google")) {
      let signupWithGoogle = document.getElementById("google-signup");
      signupWithGoogle.click();
    } else if (queryString.includes("source=fb")) {
      let signupWithFacebook = document.getElementById("facebook-signup");
      signupWithFacebook.click();
    }
    if (this.props.isAuthenticated) {
      this.props.history.push(
        `/interactions?${sessionStorage.getItem("encId")}`
      );
      // this.props.history.push('/interactions')
    } else {
      token = this.props.match.params.token;
      encId = this.props.match.params.encryptedId;
      if (token) {
        localStorage.setItem("token", token);
        localStorage.setItem("email", this.props.match.params.email);
        localStorage.setItem("encId", encId);
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("email", this.props.match.params.email);
        sessionStorage.setItem("encId", encId);
        this.props.userHasAuthenticated(true);

        dispatch(getAllUserInfo()).then(
          (data) =>
            data &&
            this.props.setUserTypeContext(
              data.user.userTypeId,
              data.user.accountDetails.currentPlan
            )
        );
      } else {
        if (localStorage.getItem("email") && localStorage.getItem("token")) {
          this.props.allStorage(storageType.LOCALSTORAGE, localStorage);

          this.props.userHasAuthenticated(true);
        }
      }
    }
  }

  toggleRememberMe = () => {
    this.setState({
      rememberMe: !this.state.rememberMe,
    });
  };

  
  handleSubmit = (e) => {
    e.preventDefault();
    const state = this.state;
    const { dispatch, form } = this.props;
  
    form.validateFields((err, formValues) => {
      if (err) {
        console.error(err);
        return;
      }
  
      if (!state.recaptchaValue) {
        // Handle reCAPTCHA not completed error
        alert("reCAPTCHA not completed");
        console.log("reCAPTCHA not completed");
        return;
      }
  
      dispatch(UserLogin({ ...formValues, rememberMe: state.rememberMe ,'g-recaptcha-response':state.recaptchaValue }));
  
      // Reset the reCAPTCHA value after dispatching the login action
      if (this.recaptchaRef.current) {
        this.recaptchaRef.current.reset();
      }
    });
  };
  

  onChange =(value)=>{
   console.log(value);
   this.setState({ recaptchaValue: value });
  }

  getLoginSectionView = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row className="main-container">
        <Row justify="space-around" align="middle" className="login-form">
          <Row className="login-logo" align="middle">
            <img
              src={Logo}
              alt="logo"
              className="login-logo-image"
              style={{ height: "95px", width: "184px" }}
            />
            {/* <LogoNew /> */}
          </Row>
          <Row>
            <label style={{ color: rgb(63, 58, 58) }}>
              You will be logged in to the Raptivity version you have signed up
              for.
            </label>
          </Row>
          <Row className="login-inputs">
            <Form>
              <Form.Item>
                {getFieldDecorator("email", {
                  initialValue: this.state.username,
                  rules: [
                    {
                      type: "email",
                      message: UITextMessages.message.INVALID_EMAIL_ADDRESS,
                    },
                    {
                      required: true,
                      message: UITextMessages.message.ENTER_EMAIL_ADDRESS,
                    },
                  ],
                })(
                  <Input
                    onPressEnter={this.handleSubmit}
                    prefix={<Icon component={Mail} />}
                    placeholder={UITextMessages.label.EMAIL_ADDRESS}
                    className="login-email"
                  />
                )}
              </Form.Item>
              <Form.Item style={{ marginBottom: "0" }}>
                {getFieldDecorator("password", {
                  rules: [
                    {
                      // pattern: new RegExp('(?!^[0-9]*$)^([a-zA-Z0-9~!@#$%^&*()_\\[+=,\\]\\{\\}\\\\.?|<>/;:\\\'\\`"-]{6,})$'),
                      pattern: new RegExp(
                        "(?!^[0-9~!@#$%^&*()_\\[+=,\\]\\{\\}\\\\.?|<>/;:\\'\\`\"-]*$)^([a-zA-Z0-9~!@#$%^&*()_\\[+=,\\]\\{\\}\\\\.?|<>/;:\\'\\`\"-]{6,})$"
                      ),
                      message: UITextMessages.message.INVALID_PASSWORD,
                    },
                    {
                      required: true,
                      message: UITextMessages.message.ENTER_PASSWORD,
                    },
                  ],
                })(
                  <Input.Password
                    prefix={<Icon component={Lock} />}
                    type="password"
                    placeholder={UITextMessages.label.PASSWORD}
                    onPressEnter={this.handleSubmit}
                    className="login-password"
                  />
                )}
              </Form.Item>
            </Form>
          </Row>
          <Row className="login-buttons">
            <Form.Item style={{ marginBottom: "5%", marginTop: "4%" }}>
              <Row style={{ marginBottom: "5%" }}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Checkbox onClick={this.toggleRememberMe}>
                    {UITextMessages.label.REMEMBER_ME}
                  </Checkbox>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Link to="/forgotpassword" className="login-form-forgot">
                    {UITextMessages.label.FORGOT_PASSWORD}
                  </Link>
                </Col>
              </Row>
              <Row className ="captchaLogo">
              <ReCAPTCHA 
              ref={this.recaptchaRef} 
              sitekey="6LfJPaEpAAAAAGSf8O-x5swfqRDq5eupQRZsCnhA" 
              onChange={this.onChange} 
              />
              </Row>
              <Row>
                <Col align="middle">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    onClick={this.handleSubmit}
                    size="large"
                  >
                    {UITextMessages.label.LOG_IN}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item style={{ marginBottom: "5px" }}>
              <Row>
                <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                  <Divider dashed />
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={2} align="middle">
                  <OrLogo />
                </Col>
                <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                  <Divider dashed />
                </Col>
              </Row>
              <Row gutter={16} style={{ marginBottom: "2.1%" }}>
                <SocialLogin />
              </Row>
              <Row className="createAccount">
                <span>{UITextMessages.label.DONT_HAVE_AN_ACCOUNT} </span>
                <NavLink className="createAccLink" to="/registration">
                  {UITextMessages.label.CREATE_AN_ACCOUNT}
                </NavLink>
              </Row>
            </Form.Item>
          </Row>
        </Row>
      </Row>
    );
  };

  getFeatureSectionView = () => {
    return (
      <div className="feature-inner-container">
        <Features />
      </div>
    );
  };

  render() {
    let { country, occupation, organizationName, state, city } =
      this.props.loginInfo;
    let redirectToProfile = false;
    if (
      this.props.isAuthenticated &&
      (country === "" ||
        country === null ||
        occupation === "" ||
        occupation === null ||
        organizationName === "" ||
        organizationName === null ||
        state === "" ||
        state === null ||
        city === "" ||
        city === null)
    ) {
      return <Redirect to={`/myprofile`} />;
    }
    if (sessionStorage.getItem("token")) {
      !this.props.isAuthenticated && this.props.userHasAuthenticated(true);
    }
    return !this.props.isAuthenticated ? (
      <Scrollbars
        className="scroller-main-wrapper login-scroller"
        style={{ height: "calc(100vh - 49px)" }}
      >
        <Row className="wrapper-container">
          <Row type="flex" justify="center" className="login-main-container">
            <Col
              style={{ backgroundColor: "white" }}
              lg={12}
              xl={12}
              className="login-container"
            >
              {this.getLoginSectionView()}
            </Col>
            {
              <Col className="feature_container">
                {/* {this.getFeatureSectionView()} */}
                <div className="login-image" />
              </Col>
            }
          </Row>
        </Row>
      </Scrollbars>
    ) : (
      sessionStorage.getItem("encId") != null && this.props.isAuthenticated && (
        <Redirect to={`/interactions?u=${sessionStorage.getItem("encId")}`} />
      )
    );
  }
}

const mapStateToProps = (state) => {
  let loginInfo = {};
  if (state.loginReducer.loginInfo !== undefined) {
    loginInfo = state.loginReducer;
    return loginInfo;
  } else {
    return { loginInfo };
  }
};

export default connect(mapStateToProps)(
  Form.create({ name: "login" })(withRouter(Login))
);
