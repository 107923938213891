import React from 'react';
import { UITextMessages } from '../helpers/constants/uiTextMessages';
import { ReactComponent as PortraitBlocker } from '../assets/images/screen-rotation-button.svg';
export default props => {
  const hasPreview = window.location.pathname.includes('preview');
  return (
    <>
      <div id='spinner' className={`portrait-blocker ${hasPreview ? 'preview' : ''}`}>
        <PortraitBlocker className='tablet-block' />
        <h2 className='tablet-block'>{UITextMessages.label.PORTRAIT_BLOCKER_TEXT}</h2>
        <h2 className='mobile-block'>{UITextMessages.label.PORTRAIT_MOB_BLOCKER_TEXT}</h2>
      </div>{' '}
    </>
  );
};
