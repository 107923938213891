import * as actionTypes from './forgotPasswordActionTypes';
import messageType from '../../helpers/enums/messageTypes';
import util from '../../helpers/utils/util';
import httpVerb from '../../helpers/enums/httpVerbs';
let apiParams = {};

export function ResetPassword(user) {
  return function(dispatch) {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'true'
    };
    util.toggleSpinner(false);
    apiParams = { user };

    return util
      .axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/password/email`, apiParams, { headers })
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: actionTypes.FORGOT_PASSWORD_ERROR,
            payload: response.data
          });

          util.displayMessage(messageType.ERROR, response.data.message);
          util.toggleSpinner(true);
        } else {
          dispatch({
            type: actionTypes.FORGOT_PASSWORD_SUCCESS,
            payload: response.data
          });

          // util.displayMessage(messageType.SUCCESS, response.data.message);
          util.toggleSpinner(true);
        }
      })
      .catch(error => {
        util.displayMessage(messageType.ERROR, error);
        util.toggleSpinner(true);
      });
  };
}

export function clearForgotPasswordReducer() {
  return function(dispatch) {
    dispatch({
      type: actionTypes.CLEAR_FORGOT_PASSWORD_REDUCER,
      payload: {}
    });
  };
}
