import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from './emailVerificationActions';
import Features from '../../components/Features';

import { UITextMessages } from '../../helpers/constants/uiTextMessages';
//Image import

import { ReactComponent as RegisteredSuccess } from '../../assets/images/register-sucessful.svg';

import VerifyEmail from '../../assets/images/verify-email.png';

import util from '../../helpers/utils/util';
import messageType from '../../helpers/enums/messageTypes';
import { Scrollbars } from 'react-custom-scrollbars';
//Less Import
import './emailVerification.less';

class PremiumRegistration extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    let queryString = window.location.href;
    let verificationCode = '';
    if (queryString.includes('verificationexpired')) {
      util.displayMessage(messageType.ERROR, UITextMessages.message.VERIFICATION_LINK_EXPIRED);
      let emailId = queryString.split('email=')[1];
      sessionStorage.setItem('email', emailId);
    } else if (queryString.includes('timestamp=')) {
      verificationCode = queryString.split('timestamp=')[1];
      dispatch(actions.timestampVerification(verificationCode));
    }
  }
  constructor(props) {
    super(props);
  }
  getFeatureSectionView = () => {
    return (
      <div className='feature-inner-container'>
        <Features />
      </div>
    );
  };
  handleEmailverification = () => {
    const user = {
      email: sessionStorage.getItem('email')
    };
    if (user.email) {
      const { dispatch } = this.props;
      dispatch(actions.EmailVerification(user));
    } else {
      window.location = `${window.location.origin}/login`;
    }
  };

  getRegistrationUpdatedSection = () => {
    return (
      <Row align='middle' className='registration-success-container'>
        <Row className='password-image'>
          <RegisteredSuccess />
        </Row>
        <Row className='password-update-label'>{UITextMessages.message.THANKS_FOR_REGISTERING_WITH_US}</Row>
        <Row className='password-update-sub-label'>{UITextMessages.message.VERIFICATION_THANK_YOU_MESSAGE}</Row>
        <Row className='back-to-login-label'>
          <span>{UITextMessages.label.BACK_TO} </span>
          <Link
            to='/'
            className='login-link'
            onClick={() => {
              this.props.verificationInfo.status = 0;
            }}
          >
            {UITextMessages.label.LOG_IN}
          </Link>
        </Row>
      </Row>
    );
  };
  getEmailVerificationSection = () => {
    return (
      <div className='email-verification'>
        <img src={VerifyEmail} />
        <span
          dangerouslySetInnerHTML={{
            __html: `${UITextMessages.message.VERIFY_EMAIL_ADDRESS[0]}`
          }}
        ></span>
        <span
          onClick={this.handleEmailverification}
          dangerouslySetInnerHTML={{
            __html: `${UITextMessages.message.VERIFY_EMAIL_ADDRESS[1]}`
          }}
        ></span>
        <span
          dangerouslySetInnerHTML={{
            __html: ` ${UITextMessages.message.VERIFY_EMAIL_ADDRESS[2]} </span>`
          }}
        ></span>
      </div>
    );
  };

  render() {
    return (
      <Scrollbars className='scroller-main-wrapper login-scroller verification-scroller' style={{ height: 'calc(100vh - 49px)' }}>
        <Row className='wrapper-container'>
          <Row type='flex' justify='center' className='login-main-container'>
            <Col style={{ backgroundColor: 'white' }} lg={12} xl={12} className='register-container'>
              {this.props.verificationInfo.status === 200 ? this.getRegistrationUpdatedSection() : this.getEmailVerificationSection()}
            </Col>
            <Col lg={12} xl={12} className='feature_container feature_container_background'>
              {this.getFeatureSectionView()}
            </Col>
          </Row>
        </Row>
      </Scrollbars>
    );
  }
}
const mapStateToProps = state => {
  let verificationInfo = '';
  if (state.emailVerificationReducer.verificationInfo !== undefined) {
    verificationInfo = state.emailVerificationReducer;
    return verificationInfo;
  } else {
    return { verificationInfo };
  }
};
export default connect(mapStateToProps)(PremiumRegistration);
