import * as actionTypes from './publishActionTypes';
import messageType from '../../helpers/enums/messageTypes';
import util from '../../helpers/utils/util';
import httpVerb from '../../helpers/enums/httpVerbs';
import { TOKEN_ALREADY_EXISTS } from '../tokenAuthentication/tokenAuthenticationActionTypes';

let apiParams = {};

export function publishInteraction(interactionId, title, description, format, lrsDetails) {
  return function(dispatch) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + sessionStorage.getItem('token'),
      Username: sessionStorage.getItem('email')
    };

    dispatch({
      type: actionTypes.PUBLISH_IN_PROGRESS,
      payload: {}
    });
    apiParams = { interactionId, title, description, format, lrsDetails };

    return util
      .axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/publish/interaction`, apiParams, { headers })
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: actionTypes.PUBLISH_ERROR,
            payload: response.data
          });

          util.displayMessage(messageType.ERROR, response.data.error);
        } else {
          dispatch({
            type: actionTypes.PUBLISH_SUCCESS,
            payload: response.data
          });
          // util.displayMessage(messageType.SUCCESS, response.data.message);
        }
      })
      .catch(error => {
        const errorMessage = error.message;
        if (error.response && error.response.data && error.response.data.alreadyLoggedIn) {
          dispatch({
            type: TOKEN_ALREADY_EXISTS,
            payload: { tokenExists: error.response.data.alreadyLoggedIn }
          });
        } else {
          dispatch({
            type: actionTypes.PUBLISH_ERROR,
            payload: {error : errorMessage}
          });
        }
      });
  };
}

export function downloadPublishedInteraction(publishedInteractionId, publishedInteractionName) {
  return function(dispatch) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + sessionStorage.getItem('token'),
      Username: sessionStorage.getItem('email')
    };

    dispatch({
      type: actionTypes.DOWNLOAD_IN_PROGRESS,
      payload: {}
    });

    util.displayMessage(messageType.LOADING, 'Zipping...', 0);

    return util
      .axios(
        httpVerb.GET,
        `${process.env.REACT_APP_API_URL}/api/publish/download/${sessionStorage.getItem('email')}/${publishedInteractionId}`,
        {},
        {
          headers: headers,
          responseType: 'arraybuffer'
        }
      )
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: actionTypes.DOWNLOAD_ERROR,
            payload: response.data
          });

          util.displayMessage(messageType.ERROR, response.data.error);
        } else {
          dispatch({
            type: actionTypes.DOWNLOAD_SUCCESS,
            payload: response.data
          });

          util.displayMessage(messageType.SUCCESS, 'Zipped...');

          util.saveFileUsingBlob(response.data, 'application/octet-stream', `${publishedInteractionName}.zip`);
        }
      })
      .catch(error => {
        const errorMessage = error.message;
        if (error.response && error.response.data && error.response.data.alreadyLoggedIn) {
          dispatch({
            type: TOKEN_ALREADY_EXISTS,
            payload: { tokenExists: error.response.data.alreadyLoggedIn }
          });
        } else {
          dispatch({
            type: actionTypes.DOWNLOAD_ERROR,
            payload: {error : errorMessage}
          });
        }
        //util.displayMessage(messageType.ERROR, response.data.error);
      });
  };
}

export function resetStatus() {
  return function(dispatch) {
    dispatch({
      type: actionTypes.RESET_STATUS,
      payload: {}
    });
  };
}

// export function reset() {
//   return function(dispatch) {
//     dispatch({
//       type: actionTypes.PUBLISH_RESET,
//       payload: {}
//     });
//   };
// }
