import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Modal } from 'antd';
import { UITextMessages } from '../helpers/constants/uiTextMessages';

export default ({ videoUrl, visible, handleOnCancel }) => {
  const [playingVideo, setPlayingVideo] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  const handleOnModalCancel = () => {
    setPlayingVideo(false);
    handleOnCancel();
  };

  const getSpinnerView = () =>
    showSpinner ? (
      <div id='spinner' className='spinner'>
        <div className='spinner-inner'>
          <div className='containLoader'>
            <div className='circleGroup circle-1'></div>
            <div className='circleGroup circle-2'></div>
            <div className='circleGroup circle-3'></div>
            <div className='circleGroup circle-4'></div>
            <div className='circleGroup circle-5'></div>
            <div className='circleGroup circle-6'></div>
            <div className='circleGroup circle-7'></div>
            <div className='circleGroup circle-8'></div>
          </div>
        </div>
      </div>
    ) : null;

  return (
    <Modal
      visible={visible}
      title={UITextMessages.label.HOW_TO_CUSTOMIZE}
      onCancel={handleOnModalCancel}
      zIndex={'9999'}
      centered={true}
      footer={false}
    >
      {videoUrl ? (
        <>
          {getSpinnerView()}
          <ReactPlayer
            onPlay={() => setPlayingVideo(true)}
            onReady={() => setShowSpinner(false)}
            className='iframe-player customize-video'
            url={videoUrl}
            playing={playingVideo}
            controls={true}
          />
        </>
      ) : (
        UITextMessages.label.VIDEO_NOT_AVAILABLE
      )}
    </Modal>
  );
};
