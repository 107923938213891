import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './previewActions';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { UITextMessages } from '../../helpers/constants/uiTextMessages';
import { Empty, Row } from 'antd';
import { ReactComponent as MyWorkImg } from '../../assets/images/my-work.svg';
import userTypes from '../../helpers/enums/userTypes';
import UserTypeContext from '../../context/userTypeContext';
import BuilderImage from '../builder/BuildeImage';

//Import Less
import './preview.less';
import { clearRegistrationReducer } from '../registration/registrationActions';
import interactionType from '../../helpers/enums/interactionTypes';

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: true,
      isListSlideClose: true,
      showSetting: '',
      globalSetting: false,
      currentViewMode: props.viewMode,
      viewModeChanged: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let updatedState = { viewModeChanged: false };

    updatedState = props.viewMode !== state.currentViewMode ? { viewModeChanged: true } : updatedState;

    return { ...updatedState, currentViewMode: props.viewMode };
  }

  componentDidMount() {
    this.props.setViewModeNav('preview');
    const { dispatch } = this.props;
    if (this.props.isDefaultPreview) {
      let interactionId = window.location.pathname.split('preview/')[1];
      dispatch(actions.getPreviewData(interactionId, null, true));
    } else {
      dispatch(actions.getPreviewData(this.props.match.params.interactionId, sessionStorage.getItem('email'), false));
    }
  }

  noInteractionSection = () => {
    let hidePublish = document.getElementsByClassName('preview-nav-group');
    hidePublish[0].style.display = 'none';

    return (
      <BuilderImage />
    );
  };

  handleOnViewModeChange = (viewMode) => {
    switch (viewMode) {
      case 1:
        return (
          <div className='preview-content' id='iframe-resizable' style={{ textAlign: 'center' }}>
            <iframe
              id='iframe-container'
              style={{ width: '1024px', height: '768px', minWidth: null }}
              className='ipad-landscape'
              src={this.props.previewInfo.url}
            />
          </div>
        );
        break;
      case 2:
        return (
          <div className='preview-content' id='iframe-resizable' style={{ textAlign: 'center' }}>
            <iframe
              id='iframe-container'
              style={{ width: '768px', height: '1024px', minWidth: null }}
              className='ipad-potrait'
              src={this.props.previewInfo.url}
            />
          </div>
        );
        break;
      case 3:
        return (
          <div className='preview-content' id='iframe-resizable' style={{ textAlign: 'center' }}>
            <iframe
              id='iframe-container'
              style={{ width: '812px', height: '375px', minWidth: null }}
              className='iphone-landscape'
              src={this.props.previewInfo.url}
            />
          </div>
        );
        break;
      case 4:
        return (
          <div className='preview-content' id='iframe-resizable' style={{ textAlign: 'center' }}>
            <iframe
              id='iframe-container'
              style={{ width: '375px', height: '812px', minWidth: null }}
              className='iphone-potrait'
              src={this.props.previewInfo.url}
            />
          </div>
        );
        break;
      default:
        return (
          <div className='preview-content' style={{ textAlign: 'center' }}>
            <iframe
              id='desktop-view'
              style={{ width: '0', height: 'calc(100vh - 52px)', minWidth: '100%' }}
              className='desktop-view'
              src={this.props.previewInfo.url}
            />
          </div>
        );
        break;
    }
  };

  adjustScrollOnViewModeChange = () => {
    const { viewModeChanged, currentViewMode } = this.state;

    if (viewModeChanged) {
      if (currentViewMode > 0) {
        document.getElementById('iframe-container').style.display = 'block';
        let y = document.getElementById('iframe-container').offsetHeight;
        let x = document.getElementById('iframe-container').offsetWidth;
        this.change_iframe_size(x, y);
        window.addEventListener('resize', () => {
          this.change_iframe_size(x, y);
        });
        document.getElementById('iframe-container').src += '';
      } else {
        switch (this.props.previewInfo.interactionTemplateId) {
          case interactionType.KNIFE_HIT:
          case interactionType.BALLOON_HIT:
          case interactionType.CHEES_AND_MOUSE:
          case interactionType.BOWLING:
          case interactionType.HORSE_RIDING:
          case interactionType.MOUNTAIN_CLIMB:
          case interactionType.PIZZA:
            document.getElementById('desktop-view').src += '';
            break;
        }
        document.getElementById('desktop-view').style.transform = 'scale(1)';
        document.getElementById('desktop-view').style.left = 0;
        document.getElementById('desktop-view').style.top = 0;
        document.getElementById('desktop-view').style.position = 'static';
        document.getElementById('desktop-view').style.margin = '0px auto';
      }
    }
  };

  change_iframe_size = (xWidth, xHeight) => {
    //Set iFrame Width and Height
    document.getElementById('iframe-container').style.width = xWidth;
    document.getElementById('iframe-container').style.height = xHeight;
    //document.getElementById('iframe-container').children[0].style.height = xHeight;

    let workArea = document.getElementById('iframe-resizable').offsetWidth;
    let workHeight = document.getElementById('iframe-resizable').offsetHeight;
    let mainWidth = 0;
    let positionDisplay = false;
    let scaleByHeight = false;

    if (xWidth < workArea) {
      mainWidth = xWidth;
    } else {
      mainWidth = workArea;
      positionDisplay = true;
    }

    let scaleFactor = mainWidth / xWidth;

    let newHeight = xHeight * scaleFactor;

    if (newHeight > workHeight) {
      scaleFactor = workHeight / xHeight;
      mainWidth = xWidth * scaleFactor;
      positionDisplay = true;
      scaleByHeight = true;
    }

    let leftPosition = 0;
    if (scaleByHeight) {
      leftPosition = (xWidth - workArea) / 2;
    } else {
      leftPosition = (xWidth - mainWidth) / 2;
    }

    let scalableHeight = xHeight * scaleFactor;
    let topPosition = (xHeight - scalableHeight) / 2 + 1;
    if (positionDisplay) {
      document.getElementById('iframe-container').style.transform = 'scale(' + scaleFactor + ')';
      document.getElementById('iframe-container').style.left = -1 * leftPosition + 'px';
      document.getElementById('iframe-container').style.top = -1 * topPosition + 'px';
      document.getElementById('iframe-container').style.position = 'absolute';
      document.getElementById('iframe-container').style.margin = 0;
    } else {
      document.getElementById('iframe-container').style.transform = 'scale(1)';
      document.getElementById('iframe-container').style.left = 0;
      document.getElementById('iframe-container').style.top = 0;
      document.getElementById('iframe-container').style.position = 'static';
      document.getElementById('iframe-container').style.margin = '0px auto';
    }
  };

  componentDidUpdate(prevProps, prevState) {
    this.adjustScrollOnViewModeChange();
  }

  render() {
    return (
      <div>
        {this.props.previewInfo.status === 404 ? (
          this.noInteractionSection()
        ) : (
          <Scrollbars className='scroller-main-wrapper' style={{ height: 'calc(100vh - 52px)' }}>
            {this.handleOnViewModeChange(this.props.viewMode)}
            {/* {setTimeout(document.getElementById('youriframe') != null && document.getElementById('youriframe').src != null && (document.getElementById('youriframe').src = document.getElementById('youriframe').src), 0)} */}
          </Scrollbars>
        )}
      </div>
    );
  }
}

Preview.propTypes = {
  previewInfo: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.loginReducer.loginInfo || {},
    previewInfo: state.previewReducer.previewInfo || {},
    viewMode: state.previewReducer.viewMode || 0,
  };
};

export default connect(mapStateToProps)(Preview);
