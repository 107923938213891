import * as actionTypes from './loginActionTypes';

import messageType from '../../helpers/enums/messageTypes';
import httpVerb from '../../helpers/enums/httpVerbs';

import util from '../../helpers/utils/util';
import paypalFormType from '../../helpers/enums/paypalFormTypes';
import { UITextMessages } from '../../helpers/constants/uiTextMessages';
import DeviceDetector from 'device-detector-js';
import userType from '../../helpers/enums/userTypes';

let apiParams = {};
export function UserLogin(user) {
  return function (dispatch) {
    const headers = {
      'Content-Type': 'application/json',
    };
    const deviceDetector = new DeviceDetector();
    const deviceInfo = deviceDetector.parse(navigator.userAgent);

    dispatch({ type: actionTypes.LOGIN_INPROGRESS, payload: {} });

    apiParams = { user , deviceInfo };
    // console.log("rememberme ======>",user.rememberMe);
    util.toggleSpinner(false);
    return util
      .axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/auth/login`, apiParams, { headers })
      .then((response) => {
        if (response.data.authenticated) {
          //maintain this sequence for storing as it affects storage event listener
          // if (user.rememberMe === true) {
          localStorage.setItem('email', response.data.email);
          localStorage.setItem('token', response.data.token.token);
          sessionStorage.setItem('token', response.data.token.token);
          sessionStorage.setItem('email', response.data.email);
         
          // }
          if (response.data.accountInfo) {
            localStorage.setItem('expiryDate', response.data.accountInfo.expiryDate);
            localStorage.setItem('currentPlan', response.data.accountInfo.currentPlan);
            localStorage.setItem('userCount', response.data.accountInfo.userCount);
            localStorage.setItem('usedStorageSpaceInBytes', response.data.accountInfo.usedStorageSpaceInBytes);
            localStorage.setItem('storageSpaceInBytes', response.data.accountInfo.storageSpaceInBytes);
            sessionStorage.setItem('encId', response.data.accountInfo.encId);
            sessionStorage.setItem('expiryDate', response.data.accountInfo.expiryDate);
            sessionStorage.setItem('currentPlan', response.data.accountInfo.currentPlan);
            sessionStorage.setItem('userCount', response.data.accountInfo.userCount);
            sessionStorage.setItem('usedStorageSpaceInBytes', response.data.accountInfo.usedStorageSpaceInBytes);
            sessionStorage.setItem('storageSpaceInBytes', response.data.accountInfo.storageSpaceInBytes);
            sessionStorage.setItem('encId', response.data.accountInfo.encId);
          }

          dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: response.data });
          util.toggleSpinner(true);
        } else if (response.data.message.includes('NotVerified')) {
          sessionStorage.setItem('email', response.data.email);

          window.location = `${window.location.origin}/emailverification`;
        } else if (response.data.deactivated) {
          dispatch({ type: actionTypes.LOGIN_ERROR, payload: response.data });

          util.displayNotification(
            messageType.INFO,
            'Subscription Notification',
            UITextMessages.message.ACCOUNT_DEACTIVATED_MESSAGE,
            0,
            'DeactivationNotification',
            '',
            'topRight',
            parseInt(userType.VC) === parseInt(response.data.userTypeId)
              ? util.paypalForm(paypalFormType.VCPREMIUM, response.data.email)
              : util.paypalForm(paypalFormType.PREMIUM, response.data.email)
          );
          util.toggleSpinner(true);
        } else {
          dispatch({ type: actionTypes.LOGIN_ERROR, payload: response.data });
          util.displayMessage(messageType.ERROR, response.data.message);
          util.toggleSpinner(true);
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.LOGIN_ERROR, payload: { error } });

        util.displayMessage(messageType.ERROR, error);
        util.toggleSpinner(true);
      });
  };
}

export function getDecryptedData(email, userTypeId) {
  return function (dispatch) {
    const headers = {
      'Content-Type': 'application/json',
    };

    dispatch({ type: actionTypes.LOGIN_INPROGRESS, payload: {} });
    util.toggleSpinner(false);
    apiParams = { email, userTypeId };

    return util
      .axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/auth/getDecryptedData`, apiParams, { headers })
      .then((response) => {
        util.displayNotification(
          messageType.INFO,
          'Subscription Notification',
          UITextMessages.message.ACCOUNT_DEACTIVATED_MESSAGE,
          0,
          'DeactivationNotification',
          '',
          'topRight',
          parseInt(userType.VC) === parseInt(response.data.userTypeId)
            ? util.paypalForm(paypalFormType.VCPREMIUM, response.data.email)
            : util.paypalForm(paypalFormType.PREMIUM, response.data.email)
        );

        util.toggleSpinner(true);
      })
      .catch((error) => {
        dispatch({ type: actionTypes.LOGIN_ERROR, payload: { error } });

        util.displayMessage(messageType.ERROR, error);
        util.toggleSpinner(true);
      });
  };
}
