import React,  { Component } from 'react'
import { notification} from 'antd'
import * as actionTypes from './interactionsActionTypes';
import { TOKEN_ALREADY_EXISTS } from '../tokenAuthentication/tokenAuthenticationActionTypes';

import util from '../../helpers/utils/util';
import httpVerb from '../../helpers/enums/httpVerbs';
let apiParams = {};

export function getInteractionList(noOfCards, startIndex) {
  return function (dispatch) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + sessionStorage.getItem('token'),
      Username: sessionStorage.getItem('email'),
    };

    util.toggleSpinner(false);
    const user = {
      email: sessionStorage.getItem('email'),
    };

    dispatch({
      type: actionTypes.FETCH_INTERACTIONS_SETTING_IN_PROGRESS,
      payload: {},
    });

    apiParams = { user };

    return util
      .axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/getInteractions/${noOfCards}/${startIndex}`, apiParams, { headers })
      .then((res) => {
        dispatch({
          type: actionTypes.FETCH_INTERACTIONS_SETTING_SUCCESS,
          payload: res.data,
        });

        util.toggleSpinner(true);
      })
      .catch((err) => {
        let errorMessage = err.message;
        if (err.response && err.response.data && err.response.data.alreadyLoggedIn) {
          dispatch({
            type: TOKEN_ALREADY_EXISTS,
            payload: { tokenExists: err.response.data.alreadyLoggedIn },
          });
        } else {
          dispatch({
            type: actionTypes.FETCH_INTERACTIONS_SETTING_ERROR,
            payload: {error : errorMessage},
          });
        }
        util.toggleSpinner(true);
      });
  };
}

export function getInteractivityNoteData() {
  return function (dispatch) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + sessionStorage.getItem('token'),
      Username: sessionStorage.getItem('email'),
    };

    util.toggleSpinner(false);

    dispatch({
      type: actionTypes.FETCH_INTERACTIONS_SETTING_IN_PROGRESS,
      payload: {},
    });

    return util
      .axios(httpVerb.GET, `${process.env.REACT_APP_API_URL}/api/getInteractivityNoteData`, apiParams, { headers })
      .then((res) => {
        dispatch({
          type: actionTypes.FETCH_INTERACTIONS_NOTE_SUCCESS,
          payload: res.data,
        });
        util.interactionNote(res.data.publishedInteractions);

        util.toggleSpinner(true);
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.alreadyLoggedIn) {
          dispatch({
            type: TOKEN_ALREADY_EXISTS,
            payload: { tokenExists: err.response.data.alreadyLoggedIn },
          });
        } else {
          dispatch({
            type: actionTypes.FETCH_INTERACTIONS_NOTE_ERROR,
            payload: err,
          });
        }
        util.toggleSpinner(true);
      });
  };
}

export function getUnpublishedInteractions() {
  
  return function (dispatch) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + sessionStorage.getItem('token'),
      Username: sessionStorage.getItem('email'),
    };

    util.toggleSpinner(false);

    dispatch({
      type: actionTypes.FETCH_INTERACTIONS_SETTING_IN_PROGRESS,
      payload: {},
    });

    return util.axios(httpVerb.GET, `${process.env.REACT_APP_API_URL}/api/getUnpublishedInteractions`, apiParams, { headers })
      .then((res) => {
        dispatch({
          type: actionTypes.FETCH_INTERACTIONS_NOTE_SUCCESS,
          payload: res.data,
        });
     const acctype =  JSON.stringify(res.data.acctype.contextid)
      let interactionNoteData = `It seems that you have not published all activities. Consider publishing your remaining ${res.data.unpublishedInteractions.length} activities.\r\n\r\n`;
     console.log('interactionNoteData is',interactionNoteData)
      if( acctype == 3 && (res.data.publishedInter.length  < res.data.unpublishedInteractions.length))
      notification.open({
        message: 'Unpublished Activities!',
        description:`${interactionNoteData}`,
        duration: 10,
      });
      util.toggleSpinner(true);
      })
     
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.alreadyLoggedIn) {
          dispatch({
            type: TOKEN_ALREADY_EXISTS,
            payload: { tokenExists: err.response.data.alreadyLoggedIn },
          });
        } else {
          dispatch({
            type: actionTypes.FETCH_INTERACTIONS_NOTE_ERROR,
            payload: err,
          });
        }
    
        util.toggleSpinner(true);
      });
  };
 
}


export function resetInteractionList() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CLEAR_INTERACTION_SETTING,
    });
  };
}

export function addAndRemoveFavourites(data){
  return async function(dispatch){
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + sessionStorage.getItem('token'),
      'Username': sessionStorage.getItem('email'),
    }
    try{
        const res = await util.axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/${data.action}/favourites`, data, { headers });
        dispatch({
          type: actionTypes.ADD_REMOVE_INTERACTION_FAVOURITES,
          payload: res.data
        })
    }catch(err){
      dispatch({
        type: actionTypes.ADD_REMOVE_INTERACTION_FAVOURITES_ERROR,
        payload: err
      })
    }
  }
}

export function getFavouriteList(){
  return async function(dispatch){
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + sessionStorage.getItem('token'),
      'Username': sessionStorage.getItem('email'),
    }
    try{
      const res = await util.axios(httpVerb.GET, `${process.env.REACT_APP_API_URL}/api/interaction/favourites`, {}, { headers });
      dispatch({
        type: actionTypes.FETCH_INTERACTIONS_FAVOURITES_LIST,
        payload: res.data
      })
    }catch(err){
      console.log(err);
    }
  }
}