import * as actionTypes from './builderActionTypes';
import messageType from '../../helpers/enums/messageTypes';
import util from '../../helpers/utils/util';
import { UITextMessages } from '../../helpers/constants/uiTextMessages';
import httpVerb from '../../helpers/enums/httpVerbs';
import { TOKEN_ALREADY_EXISTS } from '../tokenAuthentication/tokenAuthenticationActionTypes';
import userTypes from '../../helpers/enums/userTypes';
import assetType from '../../helpers/enums/assetTypes';

let apiParams = {};

export function saveUserInteraction(
  builderSetting,
  interactionJSON,
  userEmail,
  interactionTemplateId,
  backgroundSetting,
  globalSetting,
  menuSetting,
  userInteractionId = -1,
  interactionName,
  preview,
  textConstant = null
) {
  return function (dispatch) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + sessionStorage.getItem('token'),
      Username: sessionStorage.getItem('email'),
    };

    let usedStorageSpaceInBytes = parseInt(sessionStorage.getItem('usedStorageSpaceInBytes'));
    let storageSpaceInBytes = parseInt(sessionStorage.getItem('storageSpaceInBytes'));

    dispatch({
      type: actionTypes.FETCH_PREVIEW_SETTING_INPROGRESS,
      payload: {},
    });

    if (usedStorageSpaceInBytes && usedStorageSpaceInBytes > storageSpaceInBytes) {
      util.displayMessage(messageType.ERROR, UITextMessages.message.STORAGE_SPACE_EXCEEDED);
    } else {
      // dispatch({
      //   type: actionTypes.FETCH_PREVIEW_SETTING_INPROGRESS,
      //   payload: {}
      // });
      apiParams = {
        builderSetting,
        interactionJSON,
        userEmail,
        interactionTemplateId,
        backgroundSetting,
        globalSetting,
        menuSetting,
        userInteractionId,
        interactionName,
        textConstant,
      };

      return util
        .axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/builder`, apiParams, { headers })
        .then((response) => {
          if (response.data.error) {
            dispatch({
              type: actionTypes.SAVE_BUILDER_ERROR,
              payload: response.data,
            });

            util.displayMessage(messageType.ERROR, response.data.error);
          } else {
            dispatch({
              type: actionTypes.SAVE_BUILDER_SUCCESS,
              payload: response.data,
            });
            util.displayMessage(messageType.SUCCESS, response.data.message);

            //Preview link separated for hotjar feedback purpose. Support team needed only R2020 users Preview feedback
            if(parseInt(interactionJSON.userTypeId) == parseInt(userTypes.VC))
            {
            preview && window.open(`${window.location.origin}/preview/rvc/${response.data.data.userInteractionId}`, '_blank');
            } else {
              preview && window.open(`${window.location.origin}/preview/r2020/${response.data.data.userInteractionId}`, '_blank');
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.alreadyLoggedIn) {
            dispatch({
              type: TOKEN_ALREADY_EXISTS,
              payload: { tokenExists: error.response.data.alreadyLoggedIn },
            });
          } else {
            util.displayMessage(messageType.ERROR, error);
          }
        });
    }
  };
}

/**
 * This function stores userAsset (uploaded image) from Builder.js
 *
 * @export
 * @param {*} image
 * @param {*} name
 * @param {*} email
 * @returns
 */
export function setUserAssetSettings(file, captionFile, name, assetType, size, duration, youTubeLink, transcriptFile) {
  util.displayMessage(messageType.LOADING, UITextMessages.message.UPLOAD_INPROGRESS);

  return function (dispatch) {
    const data = new FormData();
    let headers = {};
    if (captionFile.name === undefined) {
      if (file.name === undefined) {
        if (transcriptFile.name === undefined) {
          data.append('youTubeLink', youTubeLink);
          data.append('altText', name);
        } else {
          file && data.append('transcriptFile', transcriptFile, transcriptFile.name);
          data.append('youTubeLink', youTubeLink);
          data.append('altText', name);
        }
      } else {
        file && data.append('asset', file, file.name);
        data.append('altText', name);
        data.append('youTubeLink', youTubeLink);
      }
      headers = {
        'Content-Type': `multipart/form-data`,
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        Username: sessionStorage.getItem('email'),
      };
    } else {
      if (transcriptFile.name === undefined) {
        file && data.append('asset', file, file.name);
        file && data.append('captionfile', captionFile, captionFile.name);
        data.append('altText', name);
        data.append('youTubeLink', youTubeLink);
        headers = {
          'Content-Type': `multipart/form-data`,
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          Username: sessionStorage.getItem('email'),
        };
      } else {
        file && data.append('asset', file, file.name);
        file && data.append('captionfile', captionFile, captionFile.name);
        file && data.append('transcriptFile', transcriptFile, transcriptFile.name);
        data.append('altText', name);
        data.append('youTubeLink', youTubeLink);
        headers = {
          'Content-Type': `multipart/form-data`,
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          Username: sessionStorage.getItem('email'),
        };
      }
    }

    data.append('altText', name);

    const user = {
      email: sessionStorage.getItem('email'),
    };
    apiParams = data;

    return util
      .axios(
        httpVerb.POST,
        `${process.env.REACT_APP_API_URL}/api/updateUserAsset?email=${user.email}&assetType=${assetType}&size=${size}&duration=${duration}&youTubeLink=${youTubeLink}`,
        apiParams,
        { headers }
      )
      .then((response) => {
        if (response.data.error) {
          util.displayMessage(messageType.WARNING, response.data.message);
        } else {
          if (parseInt(response.data.usedStorageSpaceInBytes) > 0) {
            localStorage.setItem('usedStorageSpaceInBytes', response.data.data.usedStorageSpaceInBytes);

            sessionStorage.setItem('usedStorageSpaceInBytes', response.data.data.usedStorageSpaceInBytes);
          }

          dispatch({
            type: actionTypes.UPDATE_USER_ASSET,
            payload: response.data,
          });

          util.displayMessage(messageType.SUCCESS, UITextMessages.message.UPLOAD_SUCSSESS_TEXT);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.alreadyLoggedIn) {
          dispatch({
            type: TOKEN_ALREADY_EXISTS,
            payload: { tokenExists: error.response.data.alreadyLoggedIn },
          });
        }
      });
  };
}
/**
 *
 *This function updates image alttext and caption/transcript file.
 * @export
 * @param {*} userid
 * @param {*} assettype
 * @param {*} assetpath
 * @param {*} assetindex
 * @param {*} newalttext
 * @param {*} [youtubeurl=null]
 * @param {*} [captionfile=null]
 * @param {*} [transcriptfile=null]
 * @returns
 */
export function editUserAsset(userid, assettype, assetpath, assetindex, newalttext, youtubeurl = null, captionfile = null, transcriptfile = null) {
  if (assettype == 2) {
    util.displayMessage(messageType.LOADING, UITextMessages.message.UPLOAD_INPROGRESS);
  } else {
    util.displayMessage(messageType.LOADING, UITextMessages.message.UPLOAD_INPROGRESS_ALTTEXT);
  }

  return function (dispatch) {
    let headers = {};
    const data = new FormData();

    data.append('userid', userid);
    data.append('assettype', assettype);

    data.append('assetpath', assetpath);
    data.append('assetindex', assetindex);
    data.append('alttext', newalttext);
    youtubeurl && data.append('youtubeurl', youtubeurl);
    transcriptfile.name && data.append('transcriptfile', transcriptfile, transcriptfile.name);
    captionfile.name && data.append('captionfile', captionfile, captionfile.name);

    headers = {
      'Content-Type': `multipart/form-data`,
      Authorization: 'bearer ' + sessionStorage.getItem('token'),
      Username: sessionStorage.getItem('email'),
    };
    apiParams = data;

    return util
      .axios(httpVerb.POST, `${process.env.REACT_APP_API_URL}/api/editUserAsset?userid=${userid}`, apiParams, { headers })
      .then((response) => {
        if (response.data.error) {
          util.displayMessage(messageType.WARNING, UITextMessages.message.ERROR_SAVING_CHANGES);
        } else {
          if (parseInt(response.data.usedStorageSpaceInBytes) > 0) {
            localStorage.setItem('usedStorageSpaceInBytes', response.data.data.usedStorageSpaceInBytes);
            sessionStorage.setItem('usedStorageSpaceInBytes', response.data.data.usedStorageSpaceInBytes);
          }
          dispatch({
            type: actionTypes.EDIT_USER_ASSET_SUCCESS,
            payload: response.data,
          });
          if (assettype == 2) {
            util.displayMessage(messageType.SUCCESS, UITextMessages.message.UPLOAD_SUCSSESS_TEXT);
          } else {
            util.displayMessage(messageType.SUCCESS, UITextMessages.message.ALTERNATE_TEXT_UPDATED_SUCCESSFULLY);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.data && error.response.data.alreadyLoggedIn) {
          dispatch({
            type: TOKEN_ALREADY_EXISTS,
            payload: { tokenExists: error.response.data.alreadyLoggedIn },
          });
        }
      });
  };
}

/**
 * This function get All Builder settings from database
 *
 * @export
 * @param {*} interactionId
 * @param {*} userEmail
 * @returns
 */
export function getBuidlerSettings(interactionId, userEmail, userInteractionId = -1) {
  let expiryDate = new Date(sessionStorage.getItem('expiryDate'));
  const currentDate = new Date(Date.now());
  if (expiryDate && expiryDate != 'Invalid Date' && currentDate <= expiryDate) {
    let userId = sessionStorage.getItem('userId');
    return function (dispatch) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        Username: sessionStorage.getItem('email'),
      };

      util.toggleSpinner(false);

      dispatch({
        type: actionTypes.FETCH_DEFAULT_BUILDER_SETTING_INPROGRESS,
        payload: {},
      });

      return util
        .axios(
          httpVerb.GET,
          `${process.env.REACT_APP_API_URL}/api/builder/${interactionId}/${userEmail}/${userInteractionId}/${userId}`,
          {},
          { headers }
        )
        .then((res) => {
          if (res.data.error) {
            dispatch({
              type: actionTypes.FETCH_DEFAULT_BUILDER_SETTING_ERROR,
              payload: res.data,
            });

            // util.displayMessage(messageType.ERROR, response.data.error);
          } else {
            let fontCSS = document.getElementById('aws-font-css');
            fontCSS.setAttribute('href', res.data.data.fontCSSUrl);

            let layoutSetting = JSON.parse(res.data.data.layoutSetting);
            let multiSetting = {};
            let globalSetting = JSON.parse(res.data.data.globalSetting); //current ? JSON.stringify(JSON.parse(res.data.data.globalSetting)[current]) : res.data.data.globalSetting;
            let backgroundSetting = JSON.parse(res.data.data.backgroundSetting); // current
            // ? JSON.stringify(JSON.parse(res.data.data.backgroundSetting)[current])
            // : res.data.data.backgroundSetting;
            let menuSetting = JSON.parse(res.data.data.menuSetting); // = current ? JSON.stringify(JSON.parse(res.data.data.menuSetting)[current]) : res.data.data.menuSetting;
            let current = layoutSetting.current;
            let builderSetting = null;
            let interactionName;
            if (current) {
              multiSetting = { layoutSetting, globalSetting, backgroundSetting, menuSetting };
              globalSetting = JSON.stringify(globalSetting[current]);
              backgroundSetting = JSON.stringify(backgroundSetting);
              menuSetting = JSON.stringify(menuSetting[current]);
              layoutSetting = JSON.stringify(layoutSetting[current]);
              interactionName = JSON.parse(res.data.data.layoutSetting).interactionName;
              let demovideourl = JSON.stringify(res.data.demovideourl);
              let demovideourlforvc = JSON.stringify(res.data.demovideourlforvc);

              builderSetting = {
                ...res.data,
                data: {
                  ...res.data.data,
                  layoutSetting,
                  menuSetting,
                  backgroundSetting,
                  globalSetting,
                  multiSetting,
                  interactionName,
                  demovideourl,
                  demovideourlforvc,
                },
              };
            }

            dispatch({
              type: actionTypes.FETCH_DEFAULT_BUILDER_SETTING_SUCCESS,
              payload: builderSetting ? builderSetting : res.data,
            });
          }
          util.toggleSpinner(true);
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.alreadyLoggedIn) {
            dispatch({
              type: TOKEN_ALREADY_EXISTS,
              payload: { tokenExists: err.response.data.alreadyLoggedIn },
            });
          } else {
            dispatch({
              type: actionTypes.FETCH_DEFAULT_BUILDER_SETTING_ERROR,
              payload: err,
            });
          }

          util.toggleSpinner(true);
        });
    };
  } else {
    window.location.href = `${window.location.origin}/interactions`;
  }
}

export function updateLayoutSetting(updatedLayoutSetting) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_LAYOUT_SETTING,
      payload: updatedLayoutSetting,
    });
  };
}

export function updateMultiInteractionSetting(updatedSetting, currentSettingInteraction) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_MULTI_INTERACTION_SETTING,
      payload: { updatedSetting, currentSettingInteraction },
    });
  };
}

export function changeToAnotherInteractionSetting(currentSettingInteraction) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CHANGE_TO_CURRENT_INTERACTION_SETTING,
      payload: { currentSettingInteraction },
    });
  };
}

export function updateGlobalSetting(updatedGlobalSetting) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_GLOBAL_SETTING,
      payload: updatedGlobalSetting,
    });
  };
}

//INTRODUCTIONSETTING
export function updateIntroductionSetting(updatedIntroductionSetting) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_INTRODUCTION_SETTING,
      payload: updatedIntroductionSetting,
    });
  };
}
//closureSettings
export function updateClosureSetting(updateClosureSetting) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_CLOSURE_SETTING,
      payload: updateClosureSetting,
    });
  };
}
//toggleflagsettings
export function updateApplyToggleFlagSetting(updateApplyToggleFlagSetting) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_TOGGLE_FLAG_SETTING,
      payload: updateApplyToggleFlagSetting,
    });
  };
}

export function updateFontSetting(updatedFontSetting) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_GLOBAL_SETTING,
      payload: updatedFontSetting,
    });
  };
}

export function addNewTopic(newTopic) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.ADD_NEW_TOPICS,
      payload: newTopic,
    });
  };
}

export function addNewTopicLayoutSetting(newTopicLayoutSetting) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.ADD_NEW_TOPICS_LAYOUT,
      payload: newTopicLayoutSetting,
    });
  };
}

export function addNewTopicBackgroundSetting(newBackgroundSetting) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.ADD_NEW_TOPIC_BACKGROUND,
      payload: newBackgroundSetting,
    });
  };
}

/**
 * Set the current slide which is selected
 *
 * @export
 * @param {*} currentSlideId
 * @returns
 */
export function setCurrentSlide(currentSlideId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.SET_CURRENT_SLIDE,
      payload: currentSlideId,
    });
  };
}

export function updateSlideVideo(video) {
  return function (dispatch) {
    return dispatch({
      type: actionTypes.UPDATE_SLIDE_VIDEO,
      payload: video,
    });
  };
}

export function addNewSlideData(addNewSlide) {
  return function (dispatch) {
    return dispatch({
      type: actionTypes.ADD_NEW_SLIDE_DATA,
      payload: addNewSlide,
    });
  };
}

export function updateBackgroundSetting(updatedBackgroundSetting) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_BACKGROUND_SETTING,
      payload: updatedBackgroundSetting,
    });
  };
}

export function updateInteractionData(data) {
  return function (dispatch) {
    return dispatch({
      type: actionTypes.UPDATE_SLIDES_DATA,
      payload: data,
    });
  };
}

export function updateIntroductionSlideData(data) {
  return function (dispatch) {
    return dispatch({
      type: actionTypes.UPDATE_INTRODUCTION_CLOSURE_DATA,
      payload: data,
    });
  };
}

export function updateInteractionTitle(data) {
  return function (dispatch) {
    return dispatch({
      type: actionTypes.UPDATE_INTERACTION_TITLE,
      payload: data,
    });
  };
}

export function updateSimpleInteractionSlideData(data) {
  return function (dispatch) {
    return dispatch({
      type: actionTypes.UPDATE_SIMPLE_INTRODUCTION_SLIDE,
      payload: data,
    });
  };
}

export function updateSelectedAudio(audioAsset, selectedSlideId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_SELECTED_AUDIO,
      payload: { audioAsset, selectedSlideId },
    });
  };
}

export function updateUserFontList(updatedfontslist) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_USER_FONTLIST,
      payload: updatedfontslist,
    });
  };
}

export function removeSelectedAudio(selectedSlideId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.REMOVE_SELECTED_AUDIO,
      payload: { selectedSlideId },
    });
  };
}

export function updateAudioMode(mode, selectedSlideId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_AUDIO_MODE,
      payload: { mode, selectedSlideId },
    });
  };
}

export function updateAcrossAllAudioFlag(acrossAllFlag) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_ACROSS_ALL_AUDIO_FLAG,
      payload: { acrossAllFlag },
    });
  };
}

export function updateUsedFontList(fontName) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_USED_FONT_LIST,
      payload: fontName,
    });
  };
}

// Hotspot actions

//Click and reveal
export function updateHotspotData(hotspot, firstLoad) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_HOTSPOT_DATA,
      payload: { hotspot, firstLoad },
    });
  };
}

//Panorama
// ToDo: Refactor this to make single hotspot action for all types of hotspot.
export function updatePanoramaHotspotData(hotspot, firstLoad) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_PANORAMA_HOTSPOT_DATA,
      payload: { hotspot, firstLoad },
    });
  };
}

export function updateCurrentHotspotData(hotspot) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_CURRENT_HOTSPOT_DATA,
      payload: hotspot,
    });
  };
}

export function resetUnSavedChangesFlag() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.RESET_UNSAVED_CHANGES,
      payload: false,
    });
  };
}

export function setUnSavedChangesFlag(value) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.SET_UNSAVED_CHANGES,
      payload: value,
    });
  };
}

export function clearBuilderSetting() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CLEAR_BUILDER_SETTING,
      payload: {},
    });
  };
}

export function updateStartAssessmentFlag(updateToggleFlag) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_GAMEBASED_FLAG_TOGGLE,
      payload: updateToggleFlag,
    });
  };
}

export function deleteMediaByName(assetName, assetId, userAsset){
  localStorage.setItem('assetName', assetName);

  return function (dispatch) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'bearer ' + sessionStorage.getItem('token'),
    Username: sessionStorage.getItem('email'),
  };
  const params = {
    assetName:assetName,
  };
  const data = new FormData();
  data.append('user', JSON.stringify(params));
  return util
  .axios(httpVerb.GET, `${process.env.REACT_APP_API_URL}/api/deleteMedia/`+assetName+`?assetId=${assetId}`, null, {
    headers: headers,
  })
  .then((res) => {        
    util.toggleSpinner(false);
    if (res.data.error) {
      // util.displayMessage(messageType.ERROR, response.data.error);
    }
    // dispatch({
    //   type: actionTypes.FETCH_DEFAULT_BUILDER_SETTING_SUCCESS,
    //   payload: res.data,
    // });
    
    let filteredAsset = userAsset.filter((item) => item.id !== parseInt(assetId, 10))
    let removeAsset = userAsset.find(item => item.id === Number(assetId))
    let asset = 'image';
    if(removeAsset.assettypeid === Number(assetType.FONT)){
      asset = 'font'
    }
    if(removeAsset.assettypeid === Number(assetType.VIDEO)){
      asset = 'video'
    }
    if(removeAsset.assettypeid === Number(assetType.PDF)){
      asset = 'pdf'
    }
    if(removeAsset.assettypeid === Number(assetType.AUDIO)){
      asset = 'audio'
    }
   
    dispatch({
      type: actionTypes.EDIT_USER_ASSET_SUCCESS,
      payload: {
        userAsset : filteredAsset,
        asset,
        path: removeAsset.path
      },
    });

    document.getElementById('spinner').hidden = true;
    return res;
  })
  .catch((error) => {
    util.toggleSpinner(false);
    if (error.response && error.response.data && error.response.data.alreadyLoggedIn) {
      // util.displayMessage(messageType.ERROR, error.response.data);
    } else {
      // util.displayMessage(messageType.ERROR, error);
    }
  });
}
}