import React, { Component } from "react";
import { Form, Icon, Input, Button, Row, Col, Select, Modal } from "antd";
import csc from "country-state-city";
import {
  getAllUserInfo,
  saveUserInfo,
} from "../containers/myProfile/profileActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NavigationPrompt from "react-router-navigation-prompt";
import SavePrompt from "../components/SavePrompt";
import { UITextMessages } from "../helpers/constants/uiTextMessages";
import messageType from "../helpers/enums/messageTypes";
import { hideDisplayPopup } from "../components/ExpiryPopup";

//Less Import
import "../containers/myProfile/myProfile.less";

// Images
import { ReactComponent as EarthGlobe } from "../assets/images/earth-globe.svg";
import { ReactComponent as Occupation } from "../assets/images/occupation.svg";

const { Option } = Select;

class UpdateUserDetailsModal extends Component {
  state = {
    countryChanged: false,
    countryId: "",
    invalidFieldsCount: 0,
    unsavedChanges: false,
    occupationChanged: false,
    occupation: "Select",
    occupationVisibility: true,
    occupationOtherChanged: "",
    occupationOther: "",
    userTypeId: 0,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAllUserInfo());
    this.state.userTypeId = parseInt(sessionStorage.getItem("userTypeId"));
    if (sessionStorage.getItem("expiryPopupSet") === null) {
      hideDisplayPopup(messageType.INFO);
      localStorage.setItem("expiryPopupSet", "Set");
      sessionStorage.setItem("expiryPopupSet", "Set");
    }
  }

  handleOnModalCancel = () => {
    this.props.handleOnCancel();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { dispatch } = this.props;
        if (
          values.occupation !== "undefined" &&
          values.country !== "undefined" &&
          values.occupation.trim().length > 1
        ) {
          values.email = sessionStorage.getItem("email");

          const params = {
            occupation:
              values.occupation === "Other"
                ? values.occupationOther
                : values.occupation,
            country: values.country,
            email: values.email,
          };

          dispatch(saveUserInfo(params, this.state.file));
          this.setState({ unsavedChanges: false });
          this.props.hideUpdateUserInfoPopup();
          this.props.saveUserInteraction(true);
        }
      }
    });
  };

  handleFormDataChanged = () => {
    this.setState({ unsavedChanges: true });
  };

  handleOccupationOtherChanged = (e) => {
    this.setState({ occupationOther: e });
  };

  getPromptMessage = () => {
    return (
      <>
        <NavigationPrompt when={this.state.unsavedChanges}>
          {({ onConfirm, onCancel }) => (
            <SavePrompt
              title={UITextMessages.message.UNSAVE_PROMPT_TITLE}
              message={
                UITextMessages.message.PROMPT_MESSAGE_TO_SAVE_PROFILE_DATA
              }
              when={true}
              handleSave={this.handleSubmit}
              onCancel={onCancel}
              onConfirm={onConfirm}
            />
          )}
        </NavigationPrompt>
      </>
    );
  };

  onCountrySelect = (countryName, countryId) => {
    this.setState({
      country: countryName,
      countryId: countryId,
      countryChanged: true,
    });
  };

  onOccupationSelect = (occupationName, occupationId) => {
    this.setState({
      occupation: occupationName,
      occupationId: occupationId,
      occupationChanged: true,
    });

    if (occupationId.toLowerCase() === "other") {
      this.setState({
        occupationVisibility: false,
      });
    } else {
      this.setState({
        occupationVisibility: true,
      });
    }
  };

  getCurrentCountryId = () => {
    let currentCountry = [];
    if (this.props.userInfo.user && this.props.userInfo.user.country) {
      currentCountry = csc
        .getAllCountries()
        .filter((country) => country.name === this.props.userInfo.user.country);
      if (currentCountry[0] === undefined) {
        return 0;
      } else {
        return currentCountry[0].id;
      }
    }
  };

  getoccupationList = () => {
    let occupationList = [
      { id: "Teacher", name: "Teacher" },
      { id: "Instructor", name: "Instructor" },
      { id: "Professor", name: "Professor" },
      { id: "Lecturer", name: "Lecturer" },
      { id: "Trainer", name: "Trainer" },
      { id: "EducationConsultant", name: "Education Consultant" },
      { id: "Author", name: "Author" },
      { id: "CourseManager", name: "Course Manager" },
      { id: "eLearningDesigner", name: "eLearning Designer" },
      { id: "CorporateTrainer", name: "Corporate Trainer" },
      { id: "InstructionalDesigner", name: "Instructional Designer" },
      { id: "SubjectMatterExpert", name: "Subject Matter Expert" },
      { id: "Student", name: "Student" },
      { id: "Other", name: "Other" },
    ];

    if (this.state.userTypeId == 3) {
      occupationList.unshift(
        { id: "K-12Teacher", name: "K-12 Teacher" },
        { id: "HigherEdProfessor", name: "Higher Ed Professor" },
        { id: "CorporateTrainer", name: "Corporate Trainer" },
        { id: "Private Tutor", name: "Private Tutor" }
      );
    }

    return occupationList;
  };

  updateUserProfileview = () => {
    let countries = [];
    let occupationList = [];
    let currentCountryId;

    const { getFieldDecorator } = this.props.form;

    occupationList = this.getoccupationList().map((occupation) => {
      return (
        <Option
          onClick={this.onOccupationSelect.bind(
            this,
            occupation.name,
            occupation.id
          )}
          key={occupation.name}
        >
          {occupation.name}
        </Option>
      );
    });

    currentCountryId = this.getCurrentCountryId();
    countries = csc.getAllCountries().map((country) => {
      return (
        <Option
          onClick={this.onCountrySelect.bind(this, country.name, country.id)}
          key={country.name}
        >
          {country.name}
        </Option>
      );
    });

    return (
      <Modal
        visible={this.props.visible}
        title={UITextMessages.label.UPDATE_USER_INFORMATION}
        zIndex={"777"}
        onCancel={this.handleOnModalCancel}
        centered={true}
        footer={false}
      >
        <Row>
          <Form className="login-form" onSubmit={this.handleSubmit}>
            {this.getPromptMessage()}

            <Form.Item className="select-option">
              <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1} className="icon-prefix">
                  <Icon component={EarthGlobe} />
                </Col>
                <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                  {getFieldDecorator("country", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <span style={{ marginLeft: 20 }}>
                            {UITextMessages.label.COUNTRY_REQUIRED}
                          </span>
                        ),
                      },
                    ],
                    initialValue:
                      this.props.userInfo.user &&
                      this.props.userInfo.user.country
                        ? this.props.userInfo.user.country
                        : undefined,
                  })(
                    <Select
                      size="large"
                      showSearch
                      trigger={["click"]}
                      className="dropdown"
                      placeholder={UITextMessages.label.SELECT_COUNTRY}
                      onChange={this.handleFormDataChanged}
                    >
                      {countries}
                    </Select>
                  )}
                </Col>
              </Row>
            </Form.Item>

            <Form.Item className="select-option">
              <Col xs={1} sm={1} md={1} lg={1} xl={1} className="icon-prefix">
                <Icon component={Occupation} />
              </Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                {getFieldDecorator("occupation", {
                  rules: [
                    {
                      pattern: new RegExp("^[a-zA-Z][a-zA-Z ]+"),
                      message: UITextMessages.message.ENTER_VALID_OCCUPATION,
                    },
                    {
                      required: true,
                      message: (
                        <span style={{ marginLeft: 20 }}>
                          {UITextMessages.label.OCCUPATION_REQUIRED}
                        </span>
                      ),
                    },
                  ],
                  initialValue: this.state.occupationChanged
                    ? ""
                    : this.props.userInfo.user &&
                      this.props.userInfo.user.occupation
                    ? this.props.userInfo.user.occupation
                    : undefined,
                })(
                  <Select
                    id="occupationSelect"
                    size="large"
                    showSearch
                    trigger={["click"]}
                    className="dropdown"
                    placeholder={UITextMessages.label.OCCUPATION}
                    onChange={this.handleFormDataChanged}
                  >
                    {occupationList}
                  </Select>
                )}
              </Col>
            </Form.Item>

            {!this.state.occupationVisibility && (
              <Form.Item>
                <Col xs={1} sm={1} md={1} lg={1} xl={1} className="icon-prefix">
                  <Icon component={Occupation} />
                </Col>
                <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                  {getFieldDecorator("occupationOther", {
                    rules: [
                      {
                        pattern: new RegExp("^[a-zA-Z][a-zA-Z ]+"),
                        message: (
                          <span style={{ marginLeft: 20 }}>
                            {UITextMessages.label.OCCUPATION_INVALID}
                          </span>
                        ),
                      },
                      {
                        required: true,
                        message: (
                          <span style={{ marginLeft: 20 }}>
                            {UITextMessages.label.OCCUPATION_REQUIRED}
                          </span>
                        ),
                      },
                    ],
                    initialValue: this.state.occupationOtherChanged,
                  })(
                    <Input
                      id="occupationInput"
                      type="text"
                      size="large"
                      value={this.state.occupationOther}
                      placeholder={UITextMessages.label.OCCUPATION_OTHER}
                      onChange={this.handleFormDataChanged}
                    />
                  )}
                </Col>
              </Form.Item>
            )}

            <Row
              type="flex"
              justify="end"
              style={{ marginRight: "73px" }}
              className="button-group"
            >
              <Col xs={12} sm={12} md={4} lg={5} xl={4} xxl={4}>
                <div>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    disabled={!this.state.unsavedChanges}
                  >
                    Proceed
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Row>
      </Modal>
    );
  };

  render() {
    return this.updateUserProfileview();
  }
}

const mapStateToProps = (state) => {
  let userInfo = {};
  if (state.profileReducer.userInfo !== undefined) {
    userInfo = state.profileReducer;
    return userInfo;
  } else {
    return { userInfo };
  }
};

export default connect(mapStateToProps)(
  Form.create({ name: "update_profile" })(withRouter(UpdateUserDetailsModal))
);
