import React, { useState } from "react";
import { UITextMessages } from "../helpers/constants/uiTextMessages";
import util from "../helpers/utils/util";
import messageType from "../helpers/enums/messageTypes";
import { Button, Row, Col, Tooltip } from "antd";
import copy from "copy-to-clipboard";
import { NavLink } from "react-router-dom";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import userTypes from "../helpers/enums/userTypes";
import UserTypeContext from "../context/userTypeContext";

//Image import
import { ReactComponent as CopyIcon } from "../assets/images/copy.svg";
import thumbIcon from "../assets/images/thumb.png";
import downloadIcon from "../assets/images/download.png";
import copyIcon from "../assets/images/copy.png";
import pdfIcon from "../assets/images/pdf.png";
import recommendedPdf from "../assets/images/TimeTable.pdf";
export default (props) => {
   var packageName = "";
  
  const iconSize = 32;
  const { url, name, showPublishSuccessMessage } = props;
  let currentUserPlan = sessionStorage.getItem('currentPlan');

  let stringUrl="";
  stringUrl=props.url;

    if (stringUrl==undefined) {
        stringUrl=""
    } else {
      stringUrl=url.split("/");
      {
        stringUrl.map( (data) => packageName=(data=="interaction_lms.html"?" (SCORM 1.2) ":data=="interaction_tincan.html"?" (xAPI) ":""));
      }
    }
 
  const handleCopyUrl = () => {
    copy(url);

    util.displayMessage(messageType.SUCCESS, UITextMessages.message.COPIED);
  };
  const handleclick = () => {
    alert("got it");
  };

  return (
    <>
      <div className="first-row">
        <img className="thumb-icon" src={thumbIcon} alt="thumbIcon" />
        <h2>{UITextMessages.label.AWESOME}</h2>
      </div>

      {showPublishSuccessMessage ? (
        <Row>
          <div className="second-row">
            <span>
              {
                <UserTypeContext.Consumer>
                  {(context) =>
                    parseInt(context.contextId) === parseInt(userTypes.VC)
                      ? UITextMessages.message
                          .VC_INTERACTION_PUBLISHED_SUCCESSFULLY
                      : UITextMessages.message
                          .INTERACTION_PUBLISHED_SUCCESSFULLY
                  }
                </UserTypeContext.Consumer>
              }
            </span>
          </div>
        </Row>
      ) : null}

      <Row style={{ marginTop: "20px" }}>
        <span className="third-row">
          {
            <UserTypeContext.Consumer>
              {(context) =>
                parseInt(context.contextId) === parseInt(userTypes.EL) ? (
                  <Button
                    type="link"
                    onClick={props.handleDownloadClick}
                    className="download-link"
                  >
                    <img src={downloadIcon} className="download-icon" />
                    {UITextMessages.label.CLICK_HERE}
                    {packageName}
                    {UITextMessages.label.CLICK_HERE_PACKAGE}
                  </Button>
                ) : (
                  ``
                )
              }
            </UserTypeContext.Consumer>
          }
        </span>
        {/* <span>
          {
            <UserTypeContext.Consumer>
              {(context) =>
                parseInt(context.contextId) === parseInt(userTypes.EL)
                  ? UITextMessages.label.TO_DOWNLOAD_INTERACTION
                  : ``
              }
            </UserTypeContext.Consumer>
          }
        </span> */}
      </Row>

      <Row>
        <span className="fourth-row">
          {UITextMessages.label.PUBLISHED_LINK}
        </span>

        <div className="copy-link">
          <a
            onClick={() => {
              window.open(url).opener = null;
            }}
          >
            {url}
          </a>
          <div className="copy">
            <Button onClick={handleCopyUrl}>
              {/* <CopyIcon /> */}
              <Tooltip title="copy" className="toolTip-test">
                <img src={copyIcon} />
              </Tooltip>
              {/* <img src={copyIcon} /> */}
            </Button>
          </div>
        </div>
      </Row>

      <span></span>

      <Row style={{ padding: "10px" }}>
        <span className="pdfLink">
         
          <a href="/lms" target="#">
            <span className="integrate-link">{UITextMessages.label.LMS_MESSAGE}</span>
          </a>
         
        </span>
      </Row>
      <Row  className="six-row">
        <span>
          <div className="feedbackText">
            <p>
            {currentUserPlan == "Free Trial"
                ? ""
                : UITextMessages.message.FEEDBACKTEXT_1}
              {"  "}
              {UITextMessages.message.FEEDBACKTEXT_2}
              {"  "}
              <a href={`mailto:Support@Raptivity.com`}>
                <u style={{ margin: "0 5px" }}>
                  {" "}
                  {UITextMessages.message.FEEDBACKTEXT_3}{" "}
                </u>
              </a>
              {"  "}
              {UITextMessages.message.FEEDBACKTEXT_4}
            </p>
          </div>
        </span>
      </Row>
    </>
  );
};
