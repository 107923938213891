import React from 'react';
import { UITextMessages } from '../helpers/constants/uiTextMessages';
import { Modal } from 'antd';

import util from '../helpers/utils/util';
import UserTypeContext from '../context/userTypeContext';
import paypalFormTypes from '../helpers/enums/paypalFormTypes';
import userTypes from '../helpers/enums/userTypes';

export default (props) => (
  <UserTypeContext.Consumer>
    {(context) => {
      return (
        <Modal
          visible={props.showModal}
          title={UITextMessages.label.SUBSCRIBE_NOW}
          onCancel={props.handleCancel}
          className="subscribe-popup"
          centered={true}
          footer={
            parseInt(context.contextId) === parseInt(userTypes.VC)
              ? [util.paypalForm(paypalFormTypes.VCPREMIUM)]
              : <div className='subscribed-btn'>{[util.paypalForm(paypalFormTypes.PREMIUM)]}</div>
          }
        >
          <p className='subscribe-content'>{UITextMessages.message.SUBSCRIBE_TO_USE_FEATURE}</p>
        </Modal>
      );
    }}
  </UserTypeContext.Consumer>
);
