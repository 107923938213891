const contentType = {
	IMAGE_TEXT: 'IT',
	IMAGE_VIDEO: 'IV',
	TEXT_VIDEO: 'TV',
	IMAGE: 'I',
	TEXT: 'T',
	VIDEO: 'V',
	OT: 'OT',
	PDF: 'P'
};

export default contentType;
