import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { UITextMessages } from "../helpers/constants/uiTextMessages";
import {
  Affix,
  Layout,
  Avatar,
  Popover,
  Button,
  Icon,
  Input,
  Select,
  Form,
  Modal,
  Typography,
} from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NavigationPrompt from "react-router-navigation-prompt";
import * as builderActions from "../containers/builder/builderActions";
import * as previewActions from "../containers/preview/previewActions";
import * as interactionActions from "../containers/interactions/interactionsAction";
import * as tokenAuthenticationActions from "../containers/tokenAuthentication/tokenAuthenticationAction";

import contentType from "../helpers/enums/contentTypes";
import backgroundType from "../helpers/enums/backgroundTypes";
import SavePrompt from "../components/SavePrompt";
import PublishModal from "../containers/publish/Publish";
import {
  getAllUserInfo,
  storeReferalCode,
  saveUserInfo
} from "../containers/myProfile/profileActions";
import util from "../helpers/utils/util";
import httpVerb from "../helpers/enums/httpVerbs";
import messageType from "../helpers/enums/messageTypes";
import SubscribeModal from "../components/SubscribeModal";
import WriteToUs from "../components/WriteToUs";
import "../assets/less/createReferralCode.less";

import paypalFormTypes from "../helpers/enums/paypalFormTypes";
import _, { result } from "lodash";
import customizeVideo from "../assets/images/customize-video-white.svg";
// import * as publishActions from '../containers/publish/publishActions';

//Image Import
import { ReactComponent as Feedback } from "../assets/images/feedback.svg";
import { ReactComponent as File } from "../assets/images/PreviewFile.svg";
import { ReactComponent as Close } from "../assets/images/close-builder.svg";
import { ReactComponent as Help } from "../assets/images/help.svg";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { ReactComponent as Logo_VC } from "../assets/images/logo_vc.svg";
import { ReactComponent as SmallDown } from "../assets/images/small-down.svg";
import { ReactComponent as SaveIcon } from "../assets/images/save.svg";
import { ReactComponent as Preview_Logo } from "../assets/images/Raptivity.svg";
import { ReactComponent as RVC_Logo } from "../assets/images/Raptivity-Virtual-Classroom-new.svg";

// New Image Import
import { ReactComponent as Edit } from "../assets/images/edit.svg";
import { ReactComponent as Desktop } from "../assets/images/desktop.svg";
import { ReactComponent as iPadLandscape } from "../assets/images/ipad-landscape.svg";
import { ReactComponent as iPadPortrait } from "../assets/images/ipad-potrait.svg";
import { ReactComponent as MobilePortrait } from "../assets/images/mobile.svg";
import { ReactComponent as MobileLandscape } from "../assets/images/mobile-landscape.svg";
import { ReactComponent as RedoIcon } from "../assets/images/redo.svg";
import { ReactComponent as UndoIcon } from "../assets/images/undo.svg";
import interactionType from "../helpers/enums/interactionTypes";
import textPositionType from "../helpers/enums/textPosition";
import UserTypeContext from "../context/userTypeContext";
import userType from "../helpers/enums/userTypes";
import HowToCustomizeVideoModal from "./HowToCustomizeVideoModal";
import userTypes from "../helpers/enums/userTypes";
import SpecialOffer from "../assets/images/year-special-offer.png";
import PersonalInfoPopup from "./PersonalinfoPopup";
import logoData from "../Interactions.json";
import UpdateUserDetailsModal from "./UpdateUserDetailsModal";
import CreateReferralCode from "./CreateReferralCode";
const timer = { id: false };
const { Header: AntDHeader } = Layout;
const { Text } = Typography;

class Header extends Component {
  constructor(props) {
 
    super(props);

    this.state = {
      isModalVisible: false,
      showMYWork: false,
      ShowInteractions: true,
      showPublishModal: false,
      showActivityModal: false,
      editableMode: false,
      interactionName: this.props.interactionName,
      showSubscriptionModal: false,
      interactonId: this.props.previewInfo.interactionTemplateId,
      visibleHowToCustomizeModal: false,
      visibleUpdateUserInfoModal: false,
      visibleReferralCodeModal: false,
      visible: false,
      interactionvideo:
        parseInt(this.props.userTypeId) === parseInt(userType.EL)
          ? this.props.demovideourl
            ? this.props.demovideourl
            : ""
          : this.props.demovideourlforvc
          ? this.props.demovideourlforvc
          : "",
    };
    this.setModalVisible = this.setModalVisible.bind(this);
    this.defualtInteractionName = "";
    this.autoSave = this.autoSave.bind(this);
  }
  setModalVisible(visible) {
    this.setState({ isModalVisible: visible });
  }

  // apply active border for my work and interaction
  filterInteraction(filterName) {
   
    if (filterName == "My_Work") {
      this.setState({
        showMYWork: true,
        ShowInteractions: false,
      });
      
    }
    if (filterName == "Interactions") {
      this.setState({
        ShowInteractions: true,
        showMYWork: false,
      });
      
    }
    if (filterName == "UserPic") {
      this.setState({
        ShowInteractions: false,
        showMYWork: false,
      });
    }
  }
  

  
  componentDidMount() {
    const { dispatch, isAuthenticated, tokenExists, location } = this.props;
    // Add event listener for logout across all tabs
    window.addEventListener("storage", this.handleLogoutAcrossAllTabs);
  
    // Load external script asynchronously
    const script = document.createElement("script");
    script.src = "https://cdn.popt.in/pixel.js?id=675d31fd923bb";
    script.async = true;
    document.head.appendChild(script);
  
    // Fetch user information and handle response
    dispatch(getAllUserInfo()).then((data) => {
      console.log("data from function",data);
      if (data) {
        this.props.setUserTypeContext(
          data.user.userTypeId,
          data.user.accountDetails.currentPlan
        );
  
        if (data.user.occupation) {
          this.setState({ isModalVisible: false });
        }else{
          this.setState({ isModalVisible: true });
        }
      }
    }).catch((error) => {
      console.error('Error fetching user info:', error);
    });
  
    // Set up token authentication check if user is authenticated
    if (isAuthenticated) {
      setInterval(async () => {
        if (!tokenExists.tokenExists && sessionStorage.getItem("email")) {
          dispatch(tokenAuthenticationActions.checkToken());
        }
      }, 10000);
    }
  
    // Handle path-specific interaction filtering
    if (location.pathname === "/mywork") {
      this.filterInteraction("My_Work");
    } else if (location.pathname === "/interactions") {
      this.filterInteraction("Interactions");
    }
  
    // Check profile completion
    // this.checkProfileCompletion();
  }

  // checkProfileCompletion = () => {
  //   console.log("this.props.accountInfo :",this.props.accountInfo);
  //   console.log("this.props :",this.props.accountInfo);
  //   if (this.props.accountInfo.length === 0) {
  //     this.setState({ isModalVisible: true });
  //   }
  // };

  // handleOk = () => {
  //   this.setState({ isModalVisible: false });
  //   this.props.history.push('/myprofile');
  // };

  // handleCancel = () => {
  //   this.setState({ isModalVisible: false });
  //   this.props.history.push('/myprofile');
  // };

  handleLogoutAcrossAllTabs = async () => {
    const { dispatch } = this.props;
    if (localStorage.getItem("email") === null) {
      window.removeEventListener("storage", this.handleLogoutAcrossAllTabs);
      if (sessionStorage.getItem("email")) {
        await this.logoutCurrentSession();
      }
      dispatch(tokenAuthenticationActions.tokenExists(false));
      this.props.handleLogout();
    } else if (
      sessionStorage.getItem("updateToken") &&
      this.props.tokenExists.tokenExists
    ) {
      dispatch(tokenAuthenticationActions.tokenExists(false));
      if (
        !this.props.builderReducer.globalSetting &&
        this.props.isAuthenticated
      )
        window.location.reload(false);
    }
  };

  handleLogoutClick = () => {
    this.filterInteraction("UserPic")
    this.setState({ showActivityModal: true });
  };

  handleRedirection = (e) => {
    this.filterInteraction("UserPic")
    const { href } = e.currentTarget.dataset;
    const { history } = this.props;
    history.push(href);
    this.setState({
      visible: !1,
    });
  };

  handleTogglePopover = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  logoutCurrentSession = () => {
    const { dispatch } = this.props;
    dispatch(tokenAuthenticationActions.tokenExists(false));
    let username = sessionStorage.getItem("email");
    let token = sessionStorage.getItem("token");

    dispatch(
      tokenAuthenticationActions.logoutCurrentSession(username, token)
    ).then(this.props.handleLogout());
  };

  autoSave = () => {
    const { dispatch } = this.props;
    this.saveUserInteraction(false);
    timer.id = false;
  };

  getInteractionName = () => {
    return this.state.interactionName
      ? this.state.interactionName
      : this.props.interactionName;
  };
  handleParallaxUserInteraction = (interactionId, preview) => {
    let interactionData = [];
    let interactionName = "";
    let audio = [];
    let userLayoutSetting = {};
    let interactionJSON = {};
    let layoutSetting = {};
    let backgroundSetting = this.props.backgroundSetting;
    let slidesData = [];
    let globalSetting = {};
    let xliffContent = [];
    let textConstant = this.props.textConstant;

    const { dispatch } = this.props;
    xliffContent = _.values(textConstant.AltText);
    // This reads selected color and image from Redux-store and bind in Background setting object
    backgroundSetting.bg_color = this.props.backgroundSetting.bg_color;
    backgroundSetting.bg_image = this.props.backgroundSetting.bg_image;
    backgroundSetting.bg_alt_text = this.props.backgroundSetting.bg_alt_text;
    xliffContent.push(this.props.backgroundSetting.bg_alt_text);
    //Need to add JSON creation code for Image and Text only content types

    slidesData = this.props.slidesData;
    audio = this.props.globalSetting.audioData.audioSlideData;
    (() => {
      this.props.slidesData.forEach((slide, index) => {
        this.props.layoutSetting.slides[index].imageUrl = slide.imageUrl;
        this.props.layoutSetting.slides[index].videoUrl = slide.videoUrl;
        this.props.layoutSetting.slides[index].textContent = slide.textContent;
        this.props.layoutSetting.slides[index].slideTitle = slide.slideTitle;
        this.props.layoutSetting.slides[index].menuTitle = slide.menuTitle;
        this.props.layoutSetting.slides[index].captionUrl = slide.captionUrl;
        this.props.layoutSetting.slides[index].bgImgUrl = slide.bgImgUrl;
        this.props.layoutSetting.slides[index].isExternalVideo =
          slide.isExternalVideo;
        this.props.layoutSetting.slides[index].altText = slide.altText;
        xliffContent.push(slide.altText);
        xliffContent.push(slide.textContent);
        xliffContent.push(slide.slideTitle);
      });
    })();
    this.props.layoutSetting.slides.forEach((slide, index) => {
      userLayoutSetting = {};
      switch (slide.appliedContentType) {
        case contentType.IMAGE_TEXT:
          if (slide.appliedSwapContentType) {
            userLayoutSetting.leftPanel = {
              type: "text",
              width: `${slide.appliedSliderValue}%`,
              content: slidesData[index].textContent
                ? slidesData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
            };
            userLayoutSetting.rightPanel = {
              type: "image",
              width: `${100 - slide.appliedSliderValue}%`,
              altText: slide.altText,
              content: slidesData[index].imageUrl
                ? slidesData[index].imageUrl
                : "images/taj-img.png",
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
            };
            xliffContent.push(slide.altText ? slide.altText : "");
            xliffContent.push(
              slidesData[index].textContent
                ? slidesData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT
            );
          } else {
            userLayoutSetting.leftPanel = {
              type: "image",
              width: `${slide.appliedSliderValue}%`,
              altText: slide.altText,
              content: slidesData[index].imageUrl
                ? slidesData[index].imageUrl
                : "images/taj-img.png",
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
            };
            userLayoutSetting.rightPanel = {
              type: "text",
              width: `${100 - slide.appliedSliderValue}%`,
              content: slidesData[index].textContent
                ? slidesData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
            };
            xliffContent.push(slide.altText ? slide.altText : "");
            xliffContent.push(
              slidesData[index].textContent
                ? slidesData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT
            );
          }
          break;

        case contentType.IMAGE_VIDEO:
          if (slide.appliedSwapContentType) {
            userLayoutSetting.leftPanel = {
              type: "video",
              width: `${slide.appliedSliderValue}%`,
              content: slidesData[index].videoUrl
                ? slidesData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
              isExternalVideo: slidesData[index].isExternalVideo,
              transcript: slidesData[index].transcriptUrl,
            };
            userLayoutSetting.rightPanel = {
              type: "image",
              width: `${100 - slide.appliedSliderValue}%`,
              altText: slide.altText,
              content: slidesData[index].imageUrl
                ? slidesData[index].imageUrl
                : "images/taj-img.png",
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
            };
          } else {
            userLayoutSetting.leftPanel = {
              type: "image",
              width: `${slide.appliedSliderValue}%`,
              altText: slide.altText,
              content: slidesData[index].imageUrl
                ? slidesData[index].imageUrl
                : "images/taj-img.png",
            };
            userLayoutSetting.rightPanel = {
              type: "video",
              width: `${100 - slide.appliedSliderValue}%`,
              content: slidesData[index].videoUrl
                ? slidesData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
              isExternalVideo: slidesData[index].isExternalVideo,
              transcript: slidesData[index].transcriptUrl,
            };
          }
          break;

        case contentType.TEXT_VIDEO:
          if (slide.appliedSwapContentType) {
            userLayoutSetting.leftPanel = {
              type: "video",
              width: `${slide.appliedSliderValue}%`,
              content: slidesData[index].videoUrl
                ? slidesData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
              isExternalVideo: slidesData[index].isExternalVideo,
              transcript: slidesData[index].transcriptUrl,
            };
            userLayoutSetting.rightPanel = {
              type: "text",
              width: `${100 - slide.appliedSliderValue}%`,
              content: slidesData[index].textContent
                ? slidesData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
            };
            xliffContent.push(
              slidesData[index].textContent
                ? slidesData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT
            );
          } else {
            userLayoutSetting.leftPanel = {
              type: "text",
              width: `${slide.appliedSliderValue}%`,
              content: slidesData[index].textContent
                ? slidesData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
            };
            userLayoutSetting.rightPanel = {
              type: "video",
              width: `${100 - slide.appliedSliderValue}%`,
              content: slidesData[index].videoUrl
                ? slidesData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
              isExternalVideo: slidesData[index].isExternalVideo,
              transcript: slidesData[index].transcriptUrl,
            };
            xliffContent.push(
              slidesData[index].textContent
                ? slidesData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT
            );
          }
          break;
        case contentType.IMAGE:
          userLayoutSetting.leftPanel = {
            type: "image",
            width: `100%`,
            altText: slide.altText,
            content: slidesData[index].imageUrl
              ? slidesData[index].imageUrl
              : "images/taj-img.png",
            captionUrl: slidesData[index].captionUrl
              ? slidesData[index].captionUrl
              : null,
          };
          userLayoutSetting.rightPanel = {
            type: "singleContent",
          };
          xliffContent.push(slide.altText ? slide.altText : "");
          break;
        case contentType.TEXT:
          userLayoutSetting.leftPanel = {
            type: "text",
            width: `100%`,
            content: slidesData[index].textContent
              ? slidesData[index].textContent
              : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            captionUrl: slidesData[index].captionUrl
              ? slidesData[index].captionUrl
              : null,
          };
          userLayoutSetting.rightPanel = {
            type: "singleContent",
          };
          xliffContent.push(
            slidesData[index].textContent
              ? slidesData[index].textContent
              : UITextMessages.label.SLIDE_DEFAULT_TEXT
          );
          break;

        case contentType.VIDEO:
          userLayoutSetting.leftPanel = {
            type: "video",
            width: `100%`,
            content: slidesData[index].videoUrl
              ? slidesData[index].videoUrl
              : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
            captionUrl: slidesData[index].captionUrl
              ? slidesData[index].captionUrl
              : null,
            isExternalVideo: slidesData[index].isExternalVideo,
            transcript: slidesData[index].transcriptUrl,
          };
          userLayoutSetting.rightPanel = {
            type: "singleContent",
          };
          break;

        default:
          break;
      }
      if (audio[index]) {
        userLayoutSetting.audioContent = audio[index].src;
        userLayoutSetting.slideLevelAudioMode = audio[index].mode;
      } else {
        userLayoutSetting.audioContent = null;
        userLayoutSetting.slideLevelAudioMode = null;
      }
      userLayoutSetting.backgroundImage = slidesData[index].bgImgUrl;
      interactionData.push(userLayoutSetting);
    });

    xliffContent.push(this.props.interactionTitle);

    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();
    xliffContent.push(this.props.layoutSetting.interactionTitle);
    xliffContent.push(this.props.backgroundSetting.bg_alt_text);

    xliffContent = xliffContent.filter(function (content, id, array) {
      return content && content.trim() !== "" && array.indexOf(content) == id;
    });

    xliffContent = xliffContent.sort(function (a, b) {
      return b.length - a.length;
    });

    interactionJSON = {
      xliffContent,
      userType: this.props.accountInfo.currentPlan,
      acrossAllAudioChecked: globalSetting.audioData.acrossAllSlides.checked,
      interactionTitle: this.props.layoutSetting.interactionTitle,
      interactionBackgroundColor: this.props.backgroundSetting.bg_color,
      interactionBackgroundImage: this.props.backgroundSetting.bg_image,
      backgroundImageAltText: this.props.backgroundSetting.bg_alt_text,
      interactionOverlayColor: this.props.backgroundSetting.bg_overlay_color,
      interactionAudio: this.props.globalSetting.audioData.acrossAllSlides.src,
      audioModeType: this.props.globalSetting.audioData.acrossAllSlides.mode,
      textConstant,
      interactionData,
      userTypeId: this.props.userTypeId,
      expiryDate: this.props.accountInfo.expiryDate,
    };
    dispatch(
      builderActions.saveUserInteraction(
        { layoutSetting },
        interactionJSON,
        sessionStorage.getItem("email"),
        interactionId,
        backgroundSetting,
        globalSetting,
        this.props.menuSetting,
        this.props.userInteractionId,
        interactionName,
        preview,
        textConstant
      )
    );
  };

  handleTimelineUserInteraction = (interactionId, preview) => {
    let interactionData = [];
    let interactionName = "";
    let userLayoutSetting = {};
    let interactionJSON = {};
    let layoutSetting = {};
    let backgroundSetting = this.props.backgroundSetting;
    let slidesData = [];
    let introClosureSlideData = [];
    let audio = [];
    let globalSetting = {};
    let introClosureJsonData = [];
    let textConstant = this.props.textConstant;
    const { dispatch } = this.props;
    let xliffContent = [];
    xliffContent = _.values(textConstant.AltText);
    // This reads selected color and image from Redux-store and bind in Background setting object
    backgroundSetting.bg_color = this.props.backgroundSetting.bg_color;
    backgroundSetting.bg_image = this.props.backgroundSetting.bg_image;
    backgroundSetting.bg_alt_text = this.props.backgroundSetting.bg_alt_text;
    xliffContent.push(this.props.backgroundSetting.bg_alt_text);
    //Need to add JSON creation code for Image and Text only content types

    slidesData = this.props.slidesData;
    audio = this.props.globalSetting.audioData.audioSlideData;
    introClosureSlideData = this.props.introClosureData;

    (() => {
      this.props.slidesData.forEach((slide, index) => {
        this.props.layoutSetting.slides[index].imageUrl = slide.imageUrl;
        this.props.layoutSetting.slides[index].videoUrl = slide.videoUrl;
        this.props.layoutSetting.slides[index].textContent = slide.textContent;
        this.props.layoutSetting.slides[index].slideTitle = slide.slideTitle;
        this.props.layoutSetting.slides[index].menuTitle = slide.menuTitle;
        this.props.layoutSetting.slides[index].captionUrl = slide.captionUrl;
        this.props.layoutSetting.slides[index].isExternalVideo =
          slide.isExternalVideo;
        this.props.layoutSetting.slides[index].altText = slide.altText;
        xliffContent.push(slide.textContent);
        xliffContent.push(slide.slideTitle);
        xliffContent.push(slide.menuTitle);
        xliffContent.push(slide.altText);
      });
    })();

    this.props.globalSetting.introductionSlides.forEach((slide, index) => {
      userLayoutSetting = {};
      // TODO: This code will be use in NEXT phase Commit ID  - b62e2b020235fce1820f8ddd10b69319ebc0bb2e

      userLayoutSetting.toggleFlag = slide.toggleFlag;
      userLayoutSetting.appliedContentType = slide.appliedContentType;
      userLayoutSetting.slideTitle = slide.slideTitle;
      userLayoutSetting.backgroundColor = slide.backgroundColor;
      userLayoutSetting.textContent = slide.textContent;
      userLayoutSetting.imageUrl = slide.imageUrl;
      userLayoutSetting.menuTitle = slide.menuTitle;
      userLayoutSetting.altText = slide.altText;
      xliffContent.push(slide.altText);
      userLayoutSetting.audioContent =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.src
          : this.props.globalSetting.audioData.closureSlide.src;
      userLayoutSetting.slideLevelAudioMode =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.mode
          : this.props.globalSetting.audioData.closureSlide.mode;
      userLayoutSetting.introClosureBackgroundColor = slide.backgroundColor;
      userLayoutSetting.menuTitle = slide.menuTitle;
      xliffContent.push(slide.menuTitle);
      if (slide.toggleFlag) xliffContent.push(slide.textContent);
      introClosureJsonData.push(userLayoutSetting);
    });

    this.props.layoutSetting.slides.forEach((slide, index) => {
      userLayoutSetting = {};
      switch (slide.appliedContentType) {
        case contentType.IMAGE_TEXT:
          userLayoutSetting = {
            menuTitle: slidesData[index].menuTitle
              ? slidesData[index].menuTitle
              : "",
            slideHeader: slidesData[index].slideTitle
              ? slidesData[index].slideTitle
              : "",
            slideContent: slidesData[index].textContent
              ? slidesData[index].textContent
              : "",
            slideContentPosition: slide.selectedTextPosition,
            slideContentOverlayColor: slide.selectedOverlayColor,
            backgroundImage: slidesData[index].imageUrl
              ? slidesData[index].imageUrl
              : "",
            altText: slidesData[index].altText ? slidesData[index].altText : "",
            audio: audio[index].src,
            appliedSwapContentType: slide.appliedSwapContentType,
          };
          break;

        case contentType.TEXT_VIDEO:
          userLayoutSetting = {
            menuTitle: slidesData[index].menuTitle
              ? slidesData[index].menuTitle
              : "",
            slideHeader: slidesData[index].slideTitle
              ? slidesData[index].slideTitle
              : "",
            slideContent: slidesData[index].textContent
              ? slidesData[index].textContent
              : "",
            backgroundImage: slidesData[index].imageUrl
              ? slidesData[index].imageUrl
              : "",
            slideContentPosition: slide.selectedTextPosition,
            slideContentOverlayColor: slide.selectedOverlayColor,
            audio: audio[index].src,
            video: slidesData[index].videoUrl
              ? slidesData[index].videoUrl
              : null,
            isExternalVideo: slidesData[index].isExternalVideo,
            captionUrl: slidesData[index].captionUrl,
            transcript: slidesData[index].transcriptUrl,
            appliedSwapContentType: slide.appliedSwapContentType,
          };
          break;

        case contentType.IMAGE:
          userLayoutSetting = {
            menuTitle: slidesData[index].menuTitle
              ? slidesData[index].menuTitle
              : "",
            slideHeader: slidesData[index].slideTitle
              ? slidesData[index].slideTitle
              : "",
            slideContentPosition: slide.selectedTextPosition,
            audio: audio[index].src,
            backgroundImage: slidesData[index].imageUrl
              ? slidesData[index].imageUrl
              : "",
            altText: slidesData[index].altText ? slidesData[index].altText : "",
            appliedSwapContentType: slide.appliedSwapContentType,
          };
          break;

        case contentType.VIDEO:
          userLayoutSetting = {
            menuTitle: slidesData[index].menuTitle
              ? slidesData[index].menuTitle
              : "",
            slideHeader: slidesData[index].slideTitle
              ? slidesData[index].slideTitle
              : "",
            slideContentPosition: slide.selectedTextPosition,
            audio: audio[index].src,
            video: slidesData[index].videoUrl
              ? slidesData[index].videoUrl
              : null,
            backgroundImage: slidesData[index].imageUrl
              ? slidesData[index].imageUrl
              : "",
            isExternalVideo: slidesData[index].isExternalVideo,
            captionUrl: slidesData[index].captionUrl,
            transcript: slidesData[index].transcriptUrl,
            appliedSwapContentType: slide.appliedSwapContentType,
          };
          break;

        default:
          break;
      }

      userLayoutSetting.slideLevelAudioMode = audio[index].mode;
      interactionData.push(userLayoutSetting);
    });

    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();

    xliffContent = xliffContent.filter(function (content, id, array) {
      return content && content.trim() !== "" && array.indexOf(content) == id;
    });

    xliffContent = xliffContent.sort(function (a, b) {
      return b.length - a.length;
    });

    interactionJSON = {
      xliffContent,
      userType: this.props.accountInfo.currentPlan,
      acrossAllAudioChecked: globalSetting.audioData.acrossAllSlides.checked,
      interactionTitle: introClosureSlideData[0].slideTitle,
      interactionBackgroundColor: this.props.backgroundSetting.bg_color,
      //interactionBackgroundImage: this.props.backgroundSetting.bg_image,
      interactionAudio: this.props.globalSetting.audioData.acrossAllSlides.src,
      audioModeType: this.props.globalSetting.audioData.acrossAllSlides.mode,
      interactionMenuPosition: this.props.layoutSetting.defaultLayout,
      interactionData,
      introClosureJsonData,
      introductionScene:
        this.props.globalSetting.introductionSlides[0].toggleFlag,
      closureScene: this.props.globalSetting.introductionSlides[1].toggleFlag,
      selectedFontFamily:
        this.props.timeIntervalFontSetting.time_interval_font
          .selectedFontFamily,
      selectedFontStyle:
        this.props.timeIntervalFontSetting.time_interval_font.selectedFontStyle,
      listedFontFamily:
        this.props.timeIntervalFontSetting.time_interval_font.listedFontFamily,
      listedFontStyle:
        this.props.timeIntervalFontSetting.time_interval_font.listedFontStyle,
      seletedFontTextColor:
        this.props.timeIntervalFontSetting.time_interval_font
          .seletedFontTextColor,
      seletedFontBackgroundColor:
        this.props.timeIntervalFontSetting.time_interval_font
          .seletedFontBackgroundColor,
      listedFontTextColor:
        this.props.timeIntervalFontSetting.time_interval_font
          .listedFontTextColor,
      listedFontBackgroundColor:
        this.props.timeIntervalFontSetting.time_interval_font
          .listedFontBackgroundColor,
      selectedFontSize:
        this.props.timeIntervalFontSetting.time_interval_font.selectedFontSize,
      listedFontSize:
        this.props.timeIntervalFontSetting.time_interval_font.listedFontSize,
      textConstant,
      userTypeId: this.props.userTypeId,
    };

    dispatch(
      builderActions.saveUserInteraction(
        { layoutSetting },
        interactionJSON,
        sessionStorage.getItem("email"),
        interactionId,
        backgroundSetting,
        globalSetting,
        this.props.menuSetting,
        this.props.userInteractionId,
        interactionName,
        preview,
        textConstant
      )
    );
  };

  handleGameBasedInteraction = (interactionId, preview) => {
    let interactionData = [];
    let interactionName = "";
    let audio = [];
    let userLayoutSetting = {};
    let interactionJSON = {};
    let layoutSetting = {};
    let backgroundSetting = this.props.backgroundSetting;
    let globalSetting = {};
    let menuSetting = {};
    let xliffContent = [];
    let tempLayoutSetting = [];
    let introClosureSlideData = [];
    let introClosureJsonData = [];
    let textConstant = _.cloneDeep(this.props.textConstant);

    xliffContent = _.union(
      _.values(textConstant.IntroductionSlide),
      _.values(textConstant.ResultScreen),
      _.values(textConstant.ClosureSlide),
      _.values(textConstant.Slides),
      _.values(textConstant.AltText)
    );
    const { dispatch } = this.props;

    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();

    audio = this.props.globalSetting.audioData.audioSlideData;

    userLayoutSetting = {
      ...this.props.layoutSetting,
      ...this.props.globalSetting,
      ...this.props.backgroundSetting,
    };
    xliffContent.push(this.props.backgroundSetting.bg_alt_text);

    interactionData.push(userLayoutSetting);

    layoutSetting.slides.forEach((slide, index) => {
      xliffContent.push(slide.textContent);
      xliffContent.push(slide.questionData.questionContent);
      slide.questionData.options &&
        slide.questionData.options.forEach((option, id) => {
          xliffContent.push(option);
        });
      xliffContent.push(slide.altText ? slide.altText : "");
      xliffContent.push(
        slide.feedbackPanel.correct.altText
          ? slide.feedbackPanel.correct.altText
          : ""
      );
      xliffContent.push(
        slide.feedbackPanel.wrong.altText
          ? slide.feedbackPanel.wrong.altText
          : ""
      );
      xliffContent.push(slide.feedbackPanel.correct.textContent);
      xliffContent.push(slide.feedbackPanel.wrong.textContent);
    });

    introClosureSlideData = this.props.introClosureData;
    this.props.globalSetting.introductionSlides.forEach((slide, index) => {
      userLayoutSetting = {};
      switch (slide.appliedContentType) {
        case contentType.IMAGE_TEXT:
          if (slide.selectedTextPosition == textPositionType.TOP) {
            userLayoutSetting.leftPanel = {
              layout: "topBottom",
              type: "image",
              width: `100%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
            userLayoutSetting.rightPanel = {
              layout: "topBottom",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else if (slide.selectedTextPosition == textPositionType.BOTTOM) {
            userLayoutSetting.leftPanel = {
              layout: "bottomTop",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "bottomTop",
              type: "image",
              width: `100%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
          } else if (slide.selectedTextPosition == textPositionType.LEFT) {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "image",
              width: `60%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "image",
              width: `60%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
          }
          break;

        case contentType.TEXT_VIDEO:
          if (slide.selectedTextPosition == textPositionType.TOP) {
            userLayoutSetting.leftPanel = {
              layout: "topBottom",
              type: "video",
              width: `100%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
            userLayoutSetting.rightPanel = {
              layout: "topBottom",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else if (slide.selectedTextPosition == textPositionType.BOTTOM) {
            userLayoutSetting.leftPanel = {
              layout: "bottomTop",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "bottomTop",
              type: "video",
              width: `100%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
          } else if (slide.selectedTextPosition == textPositionType.LEFT) {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "video",
              width: `60%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "video",
              width: `60%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
          }
          break;

        case contentType.IMAGE:
          userLayoutSetting.leftPanel = {
            type: "topBottom",
            width: `100%`,
            altText: introClosureSlideData[index].altText
              ? introClosureSlideData[index].altText
              : "",
            content: introClosureSlideData[index].imageUrl
              ? introClosureSlideData[index].imageUrl
              : "images/taj-img.png",
          };
          break;

        case contentType.VIDEO:
          userLayoutSetting.leftPanel = {
            type: "topBottom",
            width: `100%`,
            content: introClosureSlideData[index].videoUrl
              ? introClosureSlideData[index].videoUrl
              : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
            caption: "",
            transcript: introClosureSlideData[index].transcriptUrl,
            isExternalVideo: introClosureSlideData[index].isExternalVideo,
          };
          break;

        default:
          break;
      }
      xliffContent.push(
        introClosureSlideData[index].altText
          ? introClosureSlideData[index].altText
          : ""
      );
      userLayoutSetting.audioContent =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.src
          : this.props.globalSetting.audioData.closureSlide.src;
      userLayoutSetting.slideLevelAudioMode =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.mode
          : this.props.globalSetting.audioData.closureSlide.mode;
      userLayoutSetting.interactionBackgroundColor =
        this.props.globalSetting.introductionSlides[index].backgroundColor;
      userLayoutSetting.slideTitle =
        this.props.globalSetting.introductionSlides[index].slideTitle;
      userLayoutSetting.menuTitle = introClosureSlideData[index].menuTitle;
      if (slide.toggleFlag) xliffContent.push(slide.textContent);
      introClosureJsonData.push(userLayoutSetting);
    });
    interactionName = this.getInteractionName();

    xliffContent = xliffContent.filter(function (content, id, array) {
      return content && content.trim() !== "" && array.indexOf(content) == id;
    });

    xliffContent = xliffContent.sort(function (a, b) {
      return b.length - a.length;
    });

    interactionJSON = {
      xliffContent,
      acrossAllAudioChecked: globalSetting.audioData.acrossAllSlides.checked,
      userType: this.props.accountInfo.currentPlan,
      interactionData,
      textConstant,
      userTypeId: this.props.userTypeId,
      expiryDate: this.props.accountInfo.expiryDate,
    };

    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();
    menuSetting = this.props.menuSetting;

    if (interactionData[0] && interactionData[0].setLevel) {
      let userglobalSettingForPreview1 = JSON.parse(
        JSON.stringify(interactionData[0].setLevel)
      );
      userglobalSettingForPreview1.sort((a, b) => a.val - b.val);
      interactionData[0].setLevel = userglobalSettingForPreview1;
    }

    dispatch(
      builderActions.saveUserInteraction(
        { layoutSetting },
        interactionJSON,
        sessionStorage.getItem("email"),
        interactionId,
        backgroundSetting,
        globalSetting,
        this.props.menuSetting,
        this.props.userInteractionId,
        interactionName,
        preview,
        textConstant
      )
    );
  };

  handleVideoBasedInteraction = (interactionId, preview) => {
    let interactionData = [];
    let interactionName = "";
    let audio = [];
    let userLayoutSetting = {};
    let interactionJSON = {};
    let layoutSetting = {};
    let backgroundSetting = this.props.backgroundSetting;
    let globalSetting = {};
    let menuSetting = {};
    let xliffContent = [];
    let tempLayoutSetting = [];
    let introClosureSlideData = [];
    let introClosureJsonData = [];
    let textConstant = _.cloneDeep(this.props.textConstant);

    const { dispatch } = this.props;
    xliffContent = _.union(
      _.values(textConstant.IntroductionSlide),
      _.values(textConstant.ResultScreen),
      _.values(textConstant.ClosureSlide),
      _.values(textConstant.Slides),
      _.values(textConstant.AltText)
    );

    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();
    userLayoutSetting = {
      ...this.props.layoutSetting,
      ...this.props.globalSetting,
      ...this.props.backgroundSetting,
    };
    xliffContent.push(this.props.backgroundSetting.bg_alt_text);
    interactionData.push(userLayoutSetting);

    layoutSetting.slides.forEach((slide, index) => {
      if (slide.name === "Question") {
        xliffContent.push(slide.textContent);
        slide.questions &&
          slide.questions.forEach((o) => {
            xliffContent.push(o.questionData.questionContent);
            o.questionData.options.forEach((opt) => xliffContent.push(opt));
            xliffContent.push(
              o.feedbackPanel.correct.altText
                ? o.feedbackPanel.correct.altText
                : ""
            );
            xliffContent.push(
              o.feedbackPanel.wrong.altText ? o.feedbackPanel.wrong.altText : ""
            );
            xliffContent.push(o.feedbackPanel.correct.textContent);
            xliffContent.push(o.feedbackPanel.wrong.textContent);
          });
        xliffContent.push(slide.altText ? slide.altText : "");
      } else if (slide.name === "Info") {
        xliffContent.push(slide.questionData.questionContent);
      } else if (slide.name === "Comment") {
        xliffContent.push(slide.commentText);
      } else if (slide.name === "Check-in") {
        xliffContent.push(slide.textQuestion);
        xliffContent.push(slide.appreciatingComment);
        xliffContent.push(slide.submitButton.type);
        xliffContent.push(slide.submitButton.label);
      }
    });

    introClosureSlideData = this.props.introClosureData;
    this.props.globalSetting.introductionSlides.forEach((slide, index) => {
      userLayoutSetting = {};
      switch (slide.appliedContentType) {
        case contentType.IMAGE_TEXT:
          if (slide.selectedTextPosition == textPositionType.TOP) {
            userLayoutSetting.leftPanel = {
              layout: "topBottom",
              type: "image",
              width: `100%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
            userLayoutSetting.rightPanel = {
              layout: "topBottom",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else if (slide.selectedTextPosition == textPositionType.BOTTOM) {
            userLayoutSetting.leftPanel = {
              layout: "bottomTop",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "bottomTop",
              type: "image",
              width: `100%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
          } else if (slide.selectedTextPosition == textPositionType.LEFT) {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "image",
              width: `60%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "image",
              width: `60%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
          }
          break;

        case contentType.TEXT_VIDEO:
          if (slide.selectedTextPosition == textPositionType.TOP) {
            userLayoutSetting.leftPanel = {
              layout: "topBottom",
              type: "video",
              width: `100%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
            userLayoutSetting.rightPanel = {
              layout: "topBottom",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else if (slide.selectedTextPosition == textPositionType.BOTTOM) {
            userLayoutSetting.leftPanel = {
              layout: "bottomTop",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "bottomTop",
              type: "video",
              width: `100%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
          } else if (slide.selectedTextPosition == textPositionType.LEFT) {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "video",
              width: `60%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "video",
              width: `60%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
          }
          break;

        case contentType.IMAGE:
          userLayoutSetting.leftPanel = {
            type: "topBottom",
            width: `100%`,
            altText: introClosureSlideData[index].altText
              ? introClosureSlideData[index].altText
              : "",
            content: introClosureSlideData[index].imageUrl
              ? introClosureSlideData[index].imageUrl
              : "images/taj-img.png",
          };
          break;

        case contentType.VIDEO:
          userLayoutSetting.leftPanel = {
            type: "topBottom",
            width: `100%`,
            content: introClosureSlideData[index].videoUrl
              ? introClosureSlideData[index].videoUrl
              : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
            caption: "",
            transcript: introClosureSlideData[index].transcriptUrl,
            isExternalVideo: introClosureSlideData[index].isExternalVideo,
          };
          break;

        default:
          break;
      }

      // xliffContent.push(introClosureSlideData[index].altText ? introClosureSlideData[index].altText : '');
      userLayoutSetting.audioContent =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.src
          : this.props.globalSetting.audioData.closureSlide.src;
      userLayoutSetting.slideLevelAudioMode =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.mode
          : this.props.globalSetting.audioData.closureSlide.mode;
      userLayoutSetting.interactionBackgroundColor =
        this.props.globalSetting.introductionSlides[index].backgroundColor;
      userLayoutSetting.slideTitle =
        this.props.globalSetting.introductionSlides[index].slideTitle;
      //userLayoutSetting.menuTitle = introClosureSlideData[index].menuTitle;
      if (slide.toggleFlag) xliffContent.push(slide.textContent);
      introClosureJsonData.push(userLayoutSetting);
    });

    xliffContent = xliffContent.filter(function (content, id, array) {
      return content && content.trim() !== "" && array.indexOf(content) == id;
    });

    xliffContent = xliffContent.sort(function (a, b) {
      return b.length - a.length;
    });

    interactionJSON = {
      xliffContent,
      acrossAllAudioChecked: globalSetting.audioData.acrossAllSlides.checked,
      userType: this.props.accountInfo.currentPlan,
      interactionData,
      textConstant,
      userTypeId: this.props.userTypeId,
      expiryDate: this.props.accountInfo.expiryDate,
    };

    interactionJSON.interactionData[0].slides =
      interactionJSON.interactionData[0].slides.filter(
        (s) => s.name === "Question" && (s.timeGet !== 0 || s.rangeTo !== 0)
      );

    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();
    menuSetting = this.props.menuSetting;

    dispatch(
      builderActions.saveUserInteraction(
        { layoutSetting },
        interactionJSON,
        sessionStorage.getItem("email"),
        interactionId,
        backgroundSetting,
        globalSetting,
        this.props.menuSetting,
        this.props.userInteractionId,
        interactionName,
        preview,
        textConstant
      )
    );
  };

  handleSimpleUserInteraction = (interactionId, preview) => {
    let interactionData = [];
    let interactionName = "";
    let audio = [];
    let userLayoutSetting = {};
    let interactionJSON = {};
    let layoutSetting = {};
    let backgroundSetting = this.props.backgroundSetting;
    let slidesData = [];
    let globalSetting = {};
    let menuSetting = {};
    let xliffContent = [];
    let textConstant = this.props.textConstant;

    const { dispatch } = this.props;

    xliffContent = _.values(textConstant.AltText);

    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();

    slidesData = this.props.slidesData;
    audio = this.props.globalSetting.audioData.audioSlideData;
    (() => {
      this.props.slidesData.forEach((slide, index) => {
        this.props.layoutSetting.topicSlide.slides[index].imageUrl =
          slide.imageUrl;
        this.props.layoutSetting.topicSlide.slides[index].videoUrl =
          slide.videoUrl;
        this.props.layoutSetting.topicSlide.slides[index].textContent =
          slide.textContent;
        this.props.layoutSetting.topicSlide.slides[index].slideTitle =
          slide.slideTitle;
        this.props.layoutSetting.topicSlide.slides[index].menuTitle =
          slide.menuTitle;
        this.props.layoutSetting.topicSlide.slides[index].captionUrl =
          slide.captionUrl;
        this.props.layoutSetting.topicSlide.slides[index].isExternalVideo =
          slide.isExternalVideo;
        this.props.layoutSetting.topicSlide.slides[index].altText =
          slide.altText ? slide.altText : "";

        xliffContent.push(slide.textContent);
        xliffContent.push(slide.slideTitle);
        xliffContent.push(
          this.props.layoutSetting.topicSlide.slides[index].altText
        );
      });
    })();
    this.props.layoutSetting.homeSlide.simpleInteractionIntroductionSlide.interactionTitle =
      this.props.simpleInteractionIntroductionSlide.interactionTitle;
    this.props.layoutSetting.homeSlide.simpleInteractionIntroductionSlide.interactionDescription =
      this.props.simpleInteractionIntroductionSlide.interactionDescription;
    xliffContent.push(
      this.props.simpleInteractionIntroductionSlide.interactionTitle
    );
    xliffContent.push(
      this.props.simpleInteractionIntroductionSlide.interactionDescription
    );
    this.props.layoutSetting.topicSlide.slides.forEach((slide, index) => {
      userLayoutSetting = {};
      switch (slide.appliedContentType) {
        case contentType.IMAGE_TEXT:
          if (slide.appliedSwapContentType) {
            userLayoutSetting.leftPanel = {
              type: "text",
              width: `${slide.appliedSliderValue}%`,
              content: slidesData[index].textContent
                ? slidesData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
            };
            userLayoutSetting.rightPanel = {
              type: "image",
              width: `${100 - slide.appliedSliderValue}%`,
              altText: slidesData[index].altText
                ? slidesData[index].altText
                : "",
              content: slidesData[index].imageUrl
                ? slidesData[index].imageUrl
                : "images/taj-img.png",
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
            };
          } else {
            userLayoutSetting.leftPanel = {
              type: "image",
              width: `${slide.appliedSliderValue}%`,
              altText: slidesData[index].altText
                ? slidesData[index].altText
                : "",
              content: slidesData[index].imageUrl
                ? slidesData[index].imageUrl
                : "images/taj-img.png",
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
            };
            userLayoutSetting.rightPanel = {
              type: "text",
              width: `${100 - slide.appliedSliderValue}%`,
              content: slidesData[index].textContent
                ? slidesData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
            };
          }
          break;

        case contentType.IMAGE_VIDEO:
          if (slide.appliedSwapContentType) {
            userLayoutSetting.leftPanel = {
              type: "video",
              width: `${slide.appliedSliderValue}%`,
              content: slidesData[index].videoUrl
                ? slidesData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
              isExternalVideo: slidesData[index].isExternalVideo,
              transcript: slidesData[index].transcriptUrl,
            };
            userLayoutSetting.rightPanel = {
              type: "image",
              width: `${100 - slide.appliedSliderValue}%`,
              altText: slidesData[index].altText
                ? slidesData[index].altText
                : "",
              content: slidesData[index].imageUrl
                ? slidesData[index].imageUrl
                : "images/taj-img.png",
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
            };
          } else {
            userLayoutSetting.leftPanel = {
              type: "image",
              width: `${slide.appliedSliderValue}%`,
              altText: slidesData[index].altText
                ? slidesData[index].altText
                : "",
              content: slidesData[index].imageUrl
                ? slidesData[index].imageUrl
                : "images/taj-img.png",
            };
            userLayoutSetting.rightPanel = {
              type: "video",
              width: `${100 - slide.appliedSliderValue}%`,
              content: slidesData[index].videoUrl
                ? slidesData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
              isExternalVideo: slidesData[index].isExternalVideo,
              transcript: slidesData[index].transcriptUrl,
            };
          }
          break;

        case contentType.TEXT_VIDEO:
          if (slide.appliedSwapContentType) {
            userLayoutSetting.leftPanel = {
              type: "video",
              width: `${slide.appliedSliderValue}%`,
              content: slidesData[index].videoUrl
                ? slidesData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
              isExternalVideo: slidesData[index].isExternalVideo,
              transcript: slidesData[index].transcriptUrl,
            };
            userLayoutSetting.rightPanel = {
              type: "text",
              width: `${100 - slide.appliedSliderValue}%`,
              content: slidesData[index].textContent
                ? slidesData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
            };
          } else {
            userLayoutSetting.leftPanel = {
              type: "text",
              width: `${slide.appliedSliderValue}%`,
              content: slidesData[index].textContent
                ? slidesData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
            };
            userLayoutSetting.rightPanel = {
              type: "video",
              width: `${100 - slide.appliedSliderValue}%`,
              content: slidesData[index].videoUrl
                ? slidesData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              captionUrl: slidesData[index].captionUrl
                ? slidesData[index].captionUrl
                : null,
              isExternalVideo: slidesData[index].isExternalVideo,
              transcript: slidesData[index].transcriptUrl,
            };
          }
          break;
        case contentType.IMAGE:
          userLayoutSetting.leftPanel = {
            layout: "singleContent",
            type: "image",
            width: `100%`,
            altText: slidesData[index].altText ? slidesData[index].altText : "",
            content: slidesData[index].imageUrl
              ? slidesData[index].imageUrl
              : "images/taj-img.png",
            captionUrl: slidesData[index].captionUrl
              ? slidesData[index].captionUrl
              : null,
          };
          userLayoutSetting.rightPanel = {
            layout: "singleContent",
          };

          break;
        case contentType.TEXT:
          userLayoutSetting.leftPanel = {
            layout: "singleContent",
            type: "text",
            width: `100%`,
            content: slidesData[index].textContent
              ? slidesData[index].textContent
              : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            captionUrl: slidesData[index].captionUrl
              ? slidesData[index].captionUrl
              : null,
          };
          userLayoutSetting.rightPanel = {
            layout: "singleContent",
          };
          break;

        case contentType.VIDEO:
          userLayoutSetting.leftPanel = {
            layout: "singleContent",
            type: "video",
            width: `100%`,
            content: slidesData[index].videoUrl
              ? slidesData[index].videoUrl
              : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
            captionUrl: slidesData[index].captionUrl
              ? slidesData[index].captionUrl
              : null,
            isExternalVideo: slidesData[index].isExternalVideo,
            transcript: slidesData[index].transcriptUrl,
          };
          userLayoutSetting.rightPanel = {
            layout: "singleContent",
          };
          break;

        default:
          break;
      }
      userLayoutSetting.topicTitle =
        globalSetting.topic_setting &&
        globalSetting.topic_setting.topics[index].name;
      xliffContent.push(userLayoutSetting.topicTitle);
      userLayoutSetting.topicType =
        globalSetting.topic_setting &&
        globalSetting.topic_setting.topics[index].colorSelected
          ? "color"
          : "image";
      userLayoutSetting.topicContent =
        globalSetting.topic_setting.topics &&
        globalSetting.topic_setting.topics[index].colorSelected
          ? globalSetting.topic_setting.topics[index].color
          : globalSetting.topic_setting.topics[index].imageUrl;

      userLayoutSetting.slideTitle =
        slidesData[index].slideTitle && slidesData[index].slideTitle;

      userLayoutSetting.slideOverlayColor =
        layoutSetting.topicSlide.slides[index].selectedOverlayColor &&
        layoutSetting.topicSlide.slides[index].selectedOverlayColor;

      userLayoutSetting.slideBackgroundType =
        backgroundSetting.topicSlide &&
        backgroundSetting.topicSlide.slides[index].colorSelected
          ? "backgroundColor"
          : "backgroundImage";

      userLayoutSetting.backgroundContent =
        backgroundSetting.topicSlide &&
        backgroundSetting.topicSlide.slides[index].colorSelected
          ? backgroundSetting.topicSlide.slides[index].bg_color
          : backgroundSetting.topicSlide.slides[index].bg_image;

      if (
        globalSetting.topic_setting &&
        globalSetting.topic_setting.topics[index].imageSelected
      ) {
        userLayoutSetting.topicSlideImageAltText =
          globalSetting.topic_setting.topics[index].altText;
        xliffContent.push(globalSetting.topic_setting.topics[index].altText);
      }
      if (
        backgroundSetting.topicSlide &&
        backgroundSetting.topicSlide.slides[index].bg_alt_text
      ) {
        userLayoutSetting.backgroundImageAltText =
          backgroundSetting.topicSlide.slides[index].bg_alt_text;
        xliffContent.push(
          backgroundSetting.topicSlide.slides[index].bg_alt_text
        );
      }

      userLayoutSetting.audioContent = audio[index].src;

      userLayoutSetting.slideLevelAudioMode = audio[index].mode;

      interactionData.push(userLayoutSetting);
    });
    if (this.props.backgroundSetting.homeSlide.imageSelected) {
      xliffContent.push(this.props.backgroundSetting.homeSlide.bg_alt_text);
    }

    xliffContent = xliffContent.filter(function (content, id, array) {
      return content && content.trim() !== "" && array.indexOf(content) == id;
    });
    xliffContent = xliffContent.sort(function (a, b) {
      return b.length - a.length;
    });
    interactionJSON = {
      xliffContent,
      userType: this.props.accountInfo.currentPlan,
      acrossAllAudioChecked: globalSetting.audioData.acrossAllSlides.checked,
      introductionTitle:
        this.props.simpleInteractionIntroductionSlide.interactionTitle,
      introductionBackgroundType: this.props.backgroundSetting.homeSlide
        .imageSelected
        ? "Image"
        : "Color",
      introDiscription:
        layoutSetting.homeSlide &&
        layoutSetting.homeSlide.slides.appliedContentType == "TOC"
          ? this.props.simpleInteractionIntroductionSlide.interactionDescription
          : "",
      introductionOverlayColor:
        this.props.layoutSetting.homeSlide.slides.selectedOverlayColor,
      topicShape: globalSetting.topic_setting.topic_shape,
      topicBackgroundImageAltText: this.props.backgroundSetting.homeSlide
        .imageSelected
        ? this.props.backgroundSetting.homeSlide.bg_alt_text
        : "",
      topicShapeBackgroundContent: backgroundSetting.homeSlide.colorSelected
        ? backgroundSetting.homeSlide.bg_color
        : backgroundSetting.homeSlide.bg_image,
      selectedFontFamily:
        this.props.globalSetting.time_interval_font.selectedFontFamily,
      selectedFontStyle:
        this.props.globalSetting.time_interval_font.selectedFontStyle,
      seletedFontTextColor:
        this.props.globalSetting.time_interval_font.seletedFontTextColor,
      seletedFontBackgroundColor:
        this.props.globalSetting.time_interval_font.seletedFontBackgroundColor,
      selectedFontSize:
        this.props.globalSetting.time_interval_font.selectedFontSize,

      interactionAudio: this.props.globalSetting.audioData.acrossAllSlides.src,
      audioModeType: this.props.globalSetting.audioData.acrossAllSlides.mode,
      interactionData,
      textConstant,
      userTypeId: this.props.userTypeId,
    };

    menuSetting = this.props.menuSetting;
    dispatch(
      builderActions.saveUserInteraction(
        { layoutSetting },
        interactionJSON,
        sessionStorage.getItem("email"),
        interactionId,
        backgroundSetting,
        globalSetting,
        menuSetting,
        this.props.userInteractionId,
        interactionName,
        preview,
        textConstant
      )
    );
  };

  handleClickAndRevealUserInteraction = (interactionId, preview) => {
    let interactionData = [];
    let interactionName = "";
    let userLayoutSetting = {};
    let clickAndRevealData = {};
    let interactionJSON = {};
    let layoutSetting = {};
    let backgroundSetting = this.props.backgroundSetting;
    let introClosureSlideData = [];
    let audio = [];
    let hotspotFont = {};
    let globalSetting = {};
    let menuSetting = {};
    let introClosureJsonData = [];
    let xliffContent = [];
    let textConstant = this.props.textConstant;
    const { dispatch } = this.props;

    xliffContent = _.values(textConstant.AltText);

    // This reads selected color and image from Redux-store and bind in Background setting object
    backgroundSetting.bg_image = this.props.backgroundSetting.bg_image;
    backgroundSetting.bg_alt_text = this.props.backgroundSetting.bg_alt_text
      ? this.props.backgroundSetting.bg_alt_text
      : "";
    xliffContent.push(backgroundSetting.bg_alt_text);
    //Need to add JSON creation code for Image and Text only content types
    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    audio = this.props.globalSetting.audioData.audioSlideData;
    hotspotFont = this.props.globalSetting.hotspotFont;
    clickAndRevealData = {
      ...this.props.globalSetting.clickAndRevealData,
      audio,
      hotspotFont,
    };
    interactionData.push(clickAndRevealData);

    clickAndRevealData.hotspots.forEach((hotspot, index) => {
      xliffContent.push(hotspot.hotspotTitle);
      xliffContent.push(hotspot.modalTitle);
      xliffContent.push(hotspot.modalText);
      xliffContent.push(hotspot.modalAltText ? hotspot.modalAltText : "");
      xliffContent.push(hotspot.selectedAltText ? hotspot.selectedAltText : "");
    });
    xliffContent.push(
      clickAndRevealData.mascotData.mastcotStandingImageAltText
        ? clickAndRevealData.mascotData.mastcotStandingImageAltText
        : ""
    );
    xliffContent.push(
      clickAndRevealData.mascotData.mascotWalkingImageAltText
        ? clickAndRevealData.mascotData.mascotWalkingImageAltText
        : ""
    );

    introClosureSlideData = this.props.introClosureData;

    this.props.globalSetting.introductionSlides.forEach((slide, index) => {
      userLayoutSetting = {};
      // TODO: This code will be use in NEXT phase Commit ID - b62e2b020235fce1820f8ddd10b69319ebc0bb2e

      userLayoutSetting.toggleFlag = slide.toggleFlag;
      userLayoutSetting.appliedContentType = slide.appliedContentType;
      userLayoutSetting.slideTitle = slide.slideTitle;
      userLayoutSetting.backgroundColor = slide.backgroundColor;
      userLayoutSetting.textContent = slide.textContent;
      userLayoutSetting.imageUrl = slide.imageUrl;
      userLayoutSetting.altText = slide.altText ? slide.altText : "";

      userLayoutSetting.audioContent =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.src
          : this.props.globalSetting.audioData.closureSlide.src;
      userLayoutSetting.slideLevelAudioMode =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.mode
          : this.props.globalSetting.audioData.closureSlide.mode;
      userLayoutSetting.interactionBackgroundColor =
        this.props.globalSetting.introductionSlides[index].backgroundColor;
      userLayoutSetting.slideTitle =
        this.props.globalSetting.introductionSlides[index].slideTitle;
      userLayoutSetting.menuTitle = introClosureSlideData[index].menuTitle;
      introClosureJsonData.push(userLayoutSetting);
      xliffContent.push(slide.slideTitle);
      xliffContent.push(userLayoutSetting.altText);
      if (slide.toggleFlag) xliffContent.push(slide.textContent);
    });

    xliffContent = xliffContent.filter(function (content, id, array) {
      return content && content.trim() !== "" && array.indexOf(content) == id;
    });
    xliffContent = xliffContent.sort(function (a, b) {
      return b.length - a.length;
    });
    interactionJSON = {
      xliffContent,
      userType: this.props.accountInfo.currentPlan,
      acrossAllAudioChecked:
        this.props.globalSetting.audioData.acrossAllSlides.checked,
      interactionBackgroundImage: this.props.backgroundSetting.bg_image,
      backgroundImageAltText: this.props.backgroundSetting.bg_alt_text,
      interactionAudio: this.props.globalSetting.audioData.acrossAllSlides.src,
      audioModeType: this.props.globalSetting.audioData.acrossAllSlides.mode,
      interactionData,
      introClosureJsonData,
      introductionScene:
        this.props.globalSetting.introductionSlides[0].toggleFlag,
      closureScene: this.props.globalSetting.introductionSlides[1].toggleFlag,
      textConstant,
      userTypeId: this.props.userTypeId,
      expiryDate: this.props.accountInfo.expiryDate,
    };

    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();
    menuSetting = this.props.menuSetting;

    dispatch(
      builderActions.saveUserInteraction(
        { layoutSetting },
        interactionJSON,
        sessionStorage.getItem("email"),
        interactionId,
        backgroundSetting,
        globalSetting,
        this.props.menuSetting,
        this.props.userInteractionId,
        interactionName,
        preview,
        textConstant
      )
    );
  };
  handleParkingLotInteraction = (interactionId, preview) => {
    let interactionData = [];
    let interactionName = "";
    let userLayoutSetting = {};
    let interactionJSON = {};
    let layoutSetting = {};
    let backgroundSetting = this.props.backgroundSetting;
    let globalSetting = {};
    let xliffContent = [];
    let introClosureSlideData = [];
    let introClosureJsonData = [];
    let textConstant = _.cloneDeep(this.props.textConstant);
    let audio = [];
    let menuSetting = {};

    xliffContent = _.union(
      _.values(textConstant.AltText),
      _.values(textConstant.IntroductionSlide),
      _.values(textConstant.ResultScreen),
      _.values(textConstant.ClosureSlide),
      _.values(textConstant.Slides)
    );
    const { dispatch } = this.props;

    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();

    audio = this.props.globalSetting.audioData.audioSlideData;

    userLayoutSetting = {
      ...this.props.layoutSetting,
      ...this.props.globalSetting,
      ...this.props.backgroundSetting,
    };

    interactionData.push(userLayoutSetting);

    layoutSetting.slides.forEach((slide, index) => {
      xliffContent.push(slide.questionContent);
    });

    introClosureSlideData = this.props.introClosureData;

    this.props.globalSetting.introductionSlides.forEach((slide, index) => {
      userLayoutSetting = {};
      switch (slide.appliedContentType) {
        case contentType.IMAGE_TEXT:
          if (slide.selectedTextPosition == textPositionType.TOP) {
            userLayoutSetting.leftPanel = {
              layout: "topBottom",
              type: "image",
              width: `100%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
            userLayoutSetting.rightPanel = {
              layout: "topBottom",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else if (slide.selectedTextPosition == textPositionType.BOTTOM) {
            userLayoutSetting.leftPanel = {
              layout: "bottomTop",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "bottomTop",
              type: "image",
              width: `100%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
          } else if (slide.selectedTextPosition == textPositionType.LEFT) {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "image",
              width: `60%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "image",
              width: `60%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
          }
          break;

        case contentType.TEXT_VIDEO:
          if (slide.selectedTextPosition == textPositionType.TOP) {
            userLayoutSetting.leftPanel = {
              layout: "topBottom",
              type: "video",
              width: `100%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${URL.API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
            userLayoutSetting.rightPanel = {
              layout: "topBottom",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else if (slide.selectedTextPosition == textPositionType.BOTTOM) {
            userLayoutSetting.leftPanel = {
              layout: "bottomTop",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "bottomTop",
              type: "video",
              width: `100%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${URL.API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
          } else if (slide.selectedTextPosition == textPositionType.LEFT) {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "video",
              width: `60%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${URL.API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "video",
              width: `60%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${URL.API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
          }
          break;

        case contentType.IMAGE:
          userLayoutSetting.leftPanel = {
            type: "topBottom",
            width: `100%`,
            altText: "Taj Mahal",
            content: introClosureSlideData[index].imageUrl
              ? introClosureSlideData[index].imageUrl
              : "images/taj-img.png",
          };
          break;

        case contentType.VIDEO:
          userLayoutSetting.leftPanel = {
            type: "topBottom",
            width: `100%`,
            content: introClosureSlideData[index].videoUrl
              ? introClosureSlideData[index].videoUrl
              : `${URL.API_URL}/video/poster.mp4`,
            caption: "",
            transcript: introClosureSlideData[index].transcriptUrl,
            isExternalVideo: introClosureSlideData[index].isExternalVideo,
          };
          break;

        default:
          break;
      }

      userLayoutSetting.audioContent =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.src
          : this.props.globalSetting.audioData.closureSlide.src;
      userLayoutSetting.slideLevelAudioMode =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.mode
          : this.props.globalSetting.audioData.closureSlide.mode;
      userLayoutSetting.interactionBackgroundColor =
        this.props.globalSetting.introductionSlides[index].backgroundColor;
      userLayoutSetting.slideTitle =
        this.props.globalSetting.introductionSlides[index].slideTitle;
      // userLayoutSetting.menuTitle = introClosureSlideData[index].menuTitle;
      if (slide.toggleFlag) xliffContent.push(slide.textContent);
      introClosureJsonData.push(userLayoutSetting);
    });

    interactionName = this.getInteractionName();

    xliffContent.push(globalSetting.introductionSlides[0].altText);
    xliffContent.push(globalSetting.introductionSlides[0].logoAltText);

    xliffContent = xliffContent.filter(function (content, id, array) {
      return content && content.trim() !== "" && array.indexOf(content) == id;
    });

    xliffContent = xliffContent.sort(function (a, b) {
      return b.length - a.length;
    });
    interactionJSON = {
      xliffContent,
      acrossAllAudioChecked: globalSetting.audioData.acrossAllSlides.checked,
      userType: this.props.accountInfo.currentPlan,
      interactionData,
      textConstant,
      userTypeId: this.props.userTypeId,
      expiryDate: this.props.accountInfo.expiryDate,
    };

    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();
    menuSetting = this.props.menuSetting;

    dispatch(
      builderActions.saveUserInteraction(
        { layoutSetting },
        interactionJSON,
        sessionStorage.getItem("email"),
        interactionId,
        backgroundSetting,
        globalSetting,
        this.props.menuSetting,
        this.props.userInteractionId,
        interactionName,
        preview,
        textConstant
      )
    );
  };

  handleBalloonHitInteraction = (interactionId, preview) => {
    let interactionData = [];
    let interactionName = "";
    let userLayoutSetting = {};
    let interactionJSON = {};
    let layoutSetting = {};
    let backgroundSetting = this.props.backgroundSetting;
    let globalSetting = {};
    let xliffContent = [];
    let introClosureSlideData = [];
    let introClosureJsonData = [];
    let textConstant = _.cloneDeep(this.props.textConstant);
    let audio = [];
    let menuSetting = {};

    xliffContent = _.union(
      _.values(textConstant.AltText),
      _.values(textConstant.IntroductionSlide),
      _.values(textConstant.ResultScreen),
      _.values(textConstant.ClosureSlide),
      _.values(textConstant.Slides)
    );
    const { dispatch } = this.props;

    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();

    audio = this.props.globalSetting.audioData.audioSlideData;

    userLayoutSetting = {
      ...this.props.layoutSetting,
      ...this.props.globalSetting,
      ...this.props.backgroundSetting,
    };

    let userLayoutSettingForPreview = JSON.parse(
      JSON.stringify(userLayoutSetting)
    );

    userLayoutSettingForPreview.slides.map((slide, index) => {
      if (slide.name === UITextMessages.label.INFORMATION) {
        userLayoutSettingForPreview.slides.splice(index, 1);
        userLayoutSettingForPreview.slidesInfo.splice(index, 0, slide);
      }
    });

    interactionData.push(userLayoutSettingForPreview);

    layoutSetting.slides.forEach((slide, index) => {
      if (slide.name === "Question") {
        xliffContent.push(slide.textContent);
        xliffContent.push(slide.questionData.questionContent);
        slide.questionData.options &&
          slide.questionData.options.forEach((option, id) => {
            xliffContent.push(option);
          });
        xliffContent.push(slide.feedbackPanel.correct.textContent);
        xliffContent.push(slide.feedbackPanel.wrong.textContent);
      }
    });

    introClosureSlideData = this.props.introClosureData;

    this.props.globalSetting.introductionSlides.forEach((slide, index) => {
      userLayoutSetting = {};
      switch (slide.appliedContentType) {
        case contentType.IMAGE_TEXT:
          if (slide.selectedTextPosition == textPositionType.TOP) {
            userLayoutSetting.leftPanel = {
              layout: "topBottom",
              type: "image",
              width: `100%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
            userLayoutSetting.rightPanel = {
              layout: "topBottom",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else if (slide.selectedTextPosition == textPositionType.BOTTOM) {
            userLayoutSetting.leftPanel = {
              layout: "bottomTop",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "bottomTop",
              type: "image",
              width: `100%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
          } else if (slide.selectedTextPosition == textPositionType.LEFT) {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "image",
              width: `60%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "image",
              width: `60%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
          }
          break;

        case contentType.TEXT_VIDEO:
          if (slide.selectedTextPosition == textPositionType.TOP) {
            userLayoutSetting.leftPanel = {
              layout: "topBottom",
              type: "video",
              width: `100%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${URL.API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
            userLayoutSetting.rightPanel = {
              layout: "topBottom",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else if (slide.selectedTextPosition == textPositionType.BOTTOM) {
            userLayoutSetting.leftPanel = {
              layout: "bottomTop",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "bottomTop",
              type: "video",
              width: `100%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${URL.API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
          } else if (slide.selectedTextPosition == textPositionType.LEFT) {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "video",
              width: `60%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${URL.API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "video",
              width: `60%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${URL.API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
          }
          break;

        case contentType.IMAGE:
          userLayoutSetting.leftPanel = {
            type: "topBottom",
            width: `100%`,
            altText: "Taj Mahal",
            content: introClosureSlideData[index].imageUrl
              ? introClosureSlideData[index].imageUrl
              : "images/taj-img.png",
          };
          break;

        case contentType.VIDEO:
          userLayoutSetting.leftPanel = {
            type: "topBottom",
            width: `100%`,
            content: introClosureSlideData[index].videoUrl
              ? introClosureSlideData[index].videoUrl
              : `${URL.API_URL}/video/poster.mp4`,
            caption: "",
            transcript: introClosureSlideData[index].transcriptUrl,
            isExternalVideo: introClosureSlideData[index].isExternalVideo,
          };
          break;

        default:
          break;
      }

      userLayoutSetting.audioContent =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.src
          : this.props.globalSetting.audioData.closureSlide.src;
      userLayoutSetting.slideLevelAudioMode =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.mode
          : this.props.globalSetting.audioData.closureSlide.mode;
      userLayoutSetting.interactionBackgroundColor =
        this.props.globalSetting.introductionSlides[index].backgroundColor;
      userLayoutSetting.slideTitle =
        this.props.globalSetting.introductionSlides[index].slideTitle;
      userLayoutSetting.menuTitle = introClosureSlideData[index].menuTitle;
      if (slide.toggleFlag) xliffContent.push(slide.textContent);
      introClosureJsonData.push(userLayoutSetting);
    });

    interactionName = this.getInteractionName();

    xliffContent.push(globalSetting.introductionSlides[0].altText);
    xliffContent.push(globalSetting.introductionSlides[0].logoAltText);

    xliffContent.push(
      globalSetting.resultScreen.feedbackPanel.correct.textContent
    );
    xliffContent.push(
      globalSetting.resultScreen.feedbackPanel.wrong.textContent
    );

    xliffContent.push(
      globalSetting.resultScreen.feedbackPanel.correct.textContent
    );
    xliffContent.push(
      globalSetting.resultScreen.feedbackPanel.wrong.textContent
    );

    xliffContent = xliffContent.filter(function (content, id, array) {
      return content && content.trim() !== "" && array.indexOf(content) == id;
    });

    xliffContent = xliffContent.sort(function (a, b) {
      return b.length - a.length;
    });
    interactionJSON = {
      xliffContent,
      acrossAllAudioChecked: globalSetting.audioData.acrossAllSlides.checked,
      userType: this.props.accountInfo.currentPlan,
      interactionData,
      textConstant,
      userTypeId: this.props.userTypeId,
      expiryDate: this.props.accountInfo.expiryDate,
    };

    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();
    menuSetting = this.props.menuSetting;

    dispatch(
      builderActions.saveUserInteraction(
        { layoutSetting },
        interactionJSON,
        sessionStorage.getItem("email"),
        interactionId,
        backgroundSetting,
        globalSetting,
        this.props.menuSetting,
        this.props.userInteractionId,
        interactionName,
        preview,
        textConstant
      )
    );
  };

  handleKnifeHitInteraction = (interactionId, preview) => {
    let interactionData = [];
    let interactionName = "";
    let userLayoutSetting = {};
    let interactionJSON = {};
    let layoutSetting = {};
    let backgroundSetting = this.props.backgroundSetting;
    let globalSetting = {};
    let xliffContent = [];
    let introClosureSlideData = [];
    let introClosureJsonData = [];
    let textConstant = _.cloneDeep(this.props.textConstant);
    let audio = [];
    let menuSetting = {};

    xliffContent = _.union(
      _.values(textConstant.AltText),
      _.values(textConstant.IntroductionSlide),
      _.values(textConstant.ResultScreen),
      _.values(textConstant.ClosureSlide),
      _.values(textConstant.Slides)
    );
    const { dispatch } = this.props;

    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();

    audio = this.props.globalSetting.audioData.audioSlideData;

    userLayoutSetting = {
      ...this.props.layoutSetting,
      ...this.props.globalSetting,
      ...this.props.backgroundSetting,
    };

    interactionData.push(userLayoutSetting);

    layoutSetting.slides.forEach((slide, index) => {
      xliffContent.push(slide.textContent);
      xliffContent.push(slide.questionData.questionContent);
      slide.questionData.options &&
        slide.questionData.options.forEach((option, id) => {
          xliffContent.push(option);
        });
      xliffContent.push(slide.feedbackPanel.correct.textContent);
      xliffContent.push(slide.feedbackPanel.wrong.textContent);
    });

    introClosureSlideData = this.props.introClosureData;

    this.props.globalSetting.introductionSlides.forEach((slide, index) => {
      userLayoutSetting = {};
      switch (slide.appliedContentType) {
        case contentType.IMAGE_TEXT:
          if (slide.selectedTextPosition == textPositionType.TOP) {
            userLayoutSetting.leftPanel = {
              layout: "topBottom",
              type: "image",
              width: `100%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
            userLayoutSetting.rightPanel = {
              layout: "topBottom",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else if (slide.selectedTextPosition == textPositionType.BOTTOM) {
            userLayoutSetting.leftPanel = {
              layout: "bottomTop",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "bottomTop",
              type: "image",
              width: `100%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
          } else if (slide.selectedTextPosition == textPositionType.LEFT) {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "image",
              width: `60%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "image",
              width: `60%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
          }
          break;

        case contentType.TEXT_VIDEO:
          if (slide.selectedTextPosition == textPositionType.TOP) {
            userLayoutSetting.leftPanel = {
              layout: "topBottom",
              type: "video",
              width: `100%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${URL.API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
            userLayoutSetting.rightPanel = {
              layout: "topBottom",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else if (slide.selectedTextPosition == textPositionType.BOTTOM) {
            userLayoutSetting.leftPanel = {
              layout: "bottomTop",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "bottomTop",
              type: "video",
              width: `100%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${URL.API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
          } else if (slide.selectedTextPosition == textPositionType.LEFT) {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "video",
              width: `60%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${URL.API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "video",
              width: `60%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${URL.API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
          }
          break;

        case contentType.IMAGE:
          userLayoutSetting.leftPanel = {
            type: "topBottom",
            width: `100%`,
            altText: "Taj Mahal",
            content: introClosureSlideData[index].imageUrl
              ? introClosureSlideData[index].imageUrl
              : "images/taj-img.png",
          };
          break;

        case contentType.VIDEO:
          userLayoutSetting.leftPanel = {
            type: "topBottom",
            width: `100%`,
            content: introClosureSlideData[index].videoUrl
              ? introClosureSlideData[index].videoUrl
              : `${URL.API_URL}/video/poster.mp4`,
            caption: "",
            transcript: introClosureSlideData[index].transcriptUrl,
            isExternalVideo: introClosureSlideData[index].isExternalVideo,
          };
          break;

        default:
          break;
      }

      userLayoutSetting.audioContent =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.src
          : this.props.globalSetting.audioData.closureSlide.src;
      userLayoutSetting.slideLevelAudioMode =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.mode
          : this.props.globalSetting.audioData.closureSlide.mode;
      userLayoutSetting.interactionBackgroundColor =
        this.props.globalSetting.introductionSlides[index].backgroundColor;
      userLayoutSetting.slideTitle =
        this.props.globalSetting.introductionSlides[index].slideTitle;
      userLayoutSetting.menuTitle = introClosureSlideData[index].menuTitle;
      if (slide.toggleFlag) xliffContent.push(slide.textContent);
      introClosureJsonData.push(userLayoutSetting);
    });

    interactionName = this.getInteractionName();

    xliffContent.push(globalSetting.introductionSlides[0].altText);
    xliffContent.push(globalSetting.introductionSlides[0].logoAltText);

    xliffContent.push(
      globalSetting.resultScreen.feedbackPanel.correct.textContent
    );
    xliffContent.push(
      globalSetting.resultScreen.feedbackPanel.wrong.textContent
    );

    xliffContent.push(
      globalSetting.resultScreen.feedbackPanel.correct.textContent
    );
    xliffContent.push(
      globalSetting.resultScreen.feedbackPanel.wrong.textContent
    );

    if ("spinText" in globalSetting) xliffContent.push(globalSetting.spinText);

    xliffContent = xliffContent.filter(function (content, id, array) {
      return content && content.trim() !== "" && array.indexOf(content) == id;
    });

    xliffContent = xliffContent.sort(function (a, b) {
      return b.length - a.length;
    });
    interactionJSON = {
      xliffContent,
      acrossAllAudioChecked: globalSetting.audioData.acrossAllSlides.checked,
      userType: this.props.accountInfo.currentPlan,
      interactionData,
      textConstant,
      userTypeId: this.props.userTypeId,
      expiryDate: this.props.accountInfo.expiryDate,
    };

    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();
    menuSetting = this.props.menuSetting;

    if (interactionData[0] && interactionData[0].setLevel) {
      let userglobalSettingForPreview1 = JSON.parse(
        JSON.stringify(interactionData[0].setLevel)
      );
      userglobalSettingForPreview1.sort((a, b) => a.val - b.val);
      interactionData[0].setLevel = userglobalSettingForPreview1;
    }

    dispatch(
      builderActions.saveUserInteraction(
        { layoutSetting },
        interactionJSON,
        sessionStorage.getItem("email"),
        interactionId,
        backgroundSetting,
        globalSetting,
        this.props.menuSetting,
        this.props.userInteractionId,
        interactionName,
        preview,
        textConstant
      )
    );
  };

  getGameBasedData = (layoutSetting, globalSetting, xliffContent) => {
    let interactionData = {};
    let buttonFontSetting = {};
    let questionOptionFontSetting = {};
    let resultScreen = {};
    let feedbackScreen = {};

    buttonFontSetting = globalSetting.navigation.submitButton;

    questionOptionFontSetting = globalSetting.time_interval_font;

    resultScreen = globalSetting.resultScreen;

    feedbackScreen = {
      feedbackScreenType: globalSetting.feedbackScreen.feedbackScreenType,
    };

    let userLayoutSetting = {
      ...layoutSetting,
      buttonFontSetting,
      questionOptionFontSetting,
      resultScreen,
      feedbackScreen,
    };

    interactionData.userInteractionSetting = userLayoutSetting;

    layoutSetting.slides.forEach((slide, index) => {
      if (index == 0) {
      } else {
        xliffContent.push(slide.textContent);
        xliffContent.push(slide.questionData.questionContent);
        slide.questionData.options &&
          slide.questionData.options.forEach((option, id) => {
            xliffContent.push(option);
          });
        xliffContent.push(slide.feedbackPanel.correct.textContent);
        xliffContent.push(slide.feedbackPanel.wrong.textContent);
      }
    });

    xliffContent.push(resultScreen.feedbackPanel.correct.textContent);
    xliffContent.push(resultScreen.feedbackPanel.wrong.textContent);

    xliffContent = xliffContent.filter(function (content, id, array) {
      return content && content.trim() !== "" && array.indexOf(content) == id;
    });
    xliffContent = xliffContent.sort(function (a, b) {
      return b.length - a.length;
    });
    interactionData.xliffContent = xliffContent;
    return interactionData;
  };

  handleFlipCardUserInteraction = (interactionId, preview) => {
    let interactionData = [];
    let interactionName = "";
    let userLayoutSetting = {};
    let interactionJSON = {};
    let layoutSetting = {};
    let backgroundSetting = this.props.backgroundSetting;
    let globalSetting = {};
    let xliffContent = [];
    let introClosureSlideData = [];
    let introClosureJsonData = [];
    let textConstant = _.cloneDeep(this.props.textConstant);
    let audio = [];
    let menuSetting = {};

    xliffContent = _.union(
      _.values(textConstant.AltText),
      _.values(textConstant.IntroductionSlide),
      _.values(textConstant.ResultScreen),
      _.values(textConstant.ClosureSlide),
      _.values(textConstant.Slides)
    );
    const { dispatch } = this.props;

    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();

    audio = this.props.globalSetting.audioData.audioSlideData;

    userLayoutSetting = {
      ...this.props.layoutSetting,
      ...this.props.globalSetting,
      ...this.props.backgroundSetting,
    };

    interactionData.push(userLayoutSetting);

    layoutSetting.slides.forEach((slide, index) => {
      xliffContent.push(slide.questionContent);
      xliffContent.push(slide.answerContent);
    });

    introClosureSlideData = this.props.introClosureData;

    this.props.globalSetting.introductionSlides.forEach((slide, index) => {
      userLayoutSetting = {};
      switch (slide.appliedContentType) {
        case contentType.IMAGE_TEXT:
          if (slide.selectedTextPosition == textPositionType.TOP) {
            userLayoutSetting.leftPanel = {
              layout: "topBottom",
              type: "image",
              width: `100%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
            userLayoutSetting.rightPanel = {
              layout: "topBottom",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else if (slide.selectedTextPosition == textPositionType.BOTTOM) {
            userLayoutSetting.leftPanel = {
              layout: "bottomTop",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "bottomTop",
              type: "image",
              width: `100%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
          } else if (slide.selectedTextPosition == textPositionType.LEFT) {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "image",
              width: `60%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "image",
              width: `60%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
          }
          break;

        case contentType.TEXT_VIDEO:
          if (slide.selectedTextPosition == textPositionType.TOP) {
            userLayoutSetting.leftPanel = {
              layout: "topBottom",
              type: "video",
              width: `100%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${URL.API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
            userLayoutSetting.rightPanel = {
              layout: "topBottom",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else if (slide.selectedTextPosition == textPositionType.BOTTOM) {
            userLayoutSetting.leftPanel = {
              layout: "bottomTop",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "bottomTop",
              type: "video",
              width: `100%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${URL.API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
          } else if (slide.selectedTextPosition == textPositionType.LEFT) {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "video",
              width: `60%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${URL.API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "video",
              width: `60%`,
              altText: "Taj Mahal",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${URL.API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
          }
          break;

        case contentType.IMAGE:
          userLayoutSetting.leftPanel = {
            type: "topBottom",
            width: `100%`,
            altText: "Taj Mahal",
            content: introClosureSlideData[index].imageUrl
              ? introClosureSlideData[index].imageUrl
              : "images/taj-img.png",
          };
          break;

        case contentType.VIDEO:
          userLayoutSetting.leftPanel = {
            type: "topBottom",
            width: `100%`,
            content: introClosureSlideData[index].videoUrl
              ? introClosureSlideData[index].videoUrl
              : `${URL.API_URL}/video/poster.mp4`,
            caption: "",
            transcript: introClosureSlideData[index].transcriptUrl,
            isExternalVideo: introClosureSlideData[index].isExternalVideo,
          };
          break;

        default:
          break;
      }

      userLayoutSetting.audioContent =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.src
          : this.props.globalSetting.audioData.closureSlide.src;
      userLayoutSetting.slideLevelAudioMode =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.mode
          : this.props.globalSetting.audioData.closureSlide.mode;
      userLayoutSetting.interactionBackgroundColor =
        this.props.globalSetting.introductionSlides[index].backgroundColor;
      userLayoutSetting.slideTitle =
        this.props.globalSetting.introductionSlides[index].slideTitle;
      // userLayoutSetting.menuTitle = introClosureSlideData[index].menuTitle;
      if (slide.toggleFlag) xliffContent.push(slide.textContent);
      introClosureJsonData.push(userLayoutSetting);
    });

    interactionName = this.getInteractionName();

    xliffContent.push(globalSetting.introductionSlides[0].altText);
    xliffContent.push(globalSetting.introductionSlides[0].logoAltText);

    xliffContent = xliffContent.filter(function (content, id, array) {
      return content && content.trim() !== "" && array.indexOf(content) == id;
    });

    xliffContent = xliffContent.sort(function (a, b) {
      return b.length - a.length;
    });
    interactionJSON = {
      xliffContent,
      acrossAllAudioChecked: globalSetting.audioData.acrossAllSlides.checked,
      userType: this.props.accountInfo.currentPlan,
      interactionData,
      textConstant,
      userTypeId: this.props.userTypeId,
      expiryDate: this.props.accountInfo.expiryDate,
    };

    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();
    menuSetting = this.props.menuSetting;

    dispatch(
      builderActions.saveUserInteraction(
        { layoutSetting },
        interactionJSON,
        sessionStorage.getItem("email"),
        interactionId,
        backgroundSetting,
        globalSetting,
        this.props.menuSetting,
        this.props.userInteractionId,
        interactionName,
        preview,
        textConstant
      )
    );
  };

  handleClickAndRevealAssessmentUserInteraction = (interactionId, preview) => {
    let interactionData = [];
    let interactionName = "";
    let userLayoutSetting = {};
    let clickAndRevealData = {};
    let interactionJSON = {};
    let layoutSetting = {};
    let backgroundSetting = this.props.backgroundSetting;
    let introClosureSlideData = [];
    let audio = [];
    let hotspotFont = {};
    let globalSetting = {};
    let menuSetting = {};
    let introClosureJsonData = [];

    let xliffContent = [];
    const { dispatch } = this.props;
    let textConstant = _.cloneDeep(this.props.textConstant);
    xliffContent = _.union(
      _.values(textConstant.IntroductionSlide),
      _.values(textConstant.ResultScreen),
      _.values(textConstant.Slides),
      _.values(textConstant.AltText)
    );
    // This reads selected color and image from Redux-store and bind in Background setting object
    backgroundSetting.bg_image = this.props.backgroundSetting.bg_image;
    backgroundSetting.bg_alt_text = this.props.backgroundSetting.bg_alt_text
      ? this.props.backgroundSetting.bg_alt_text
      : "";
    xliffContent.push(backgroundSetting.bg_alt_text);
    //Need to add JSON creation code for Image and Text only content types
    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();
    audio = this.props.globalSetting.audioData.audioSlideData;
    hotspotFont = this.props.globalSetting.hotspotFont;
    clickAndRevealData = {
      ...this.props.globalSetting.clickAndRevealData,
      audio,
      hotspotFont,
    };
    interactionData.push(clickAndRevealData);

    clickAndRevealData.hotspots.forEach((hotspot, index) => {
      xliffContent.push(hotspot.hotspotTitle);
      xliffContent.push(hotspot.modalTitle);
      xliffContent.push(hotspot.modalText);
    });

    introClosureSlideData = this.props.introClosureData;

    this.props.globalSetting.introductionSlides.forEach((slide, index) => {
      userLayoutSetting = {};
      // TODO: This code will be use in NEXT phase Commit ID - b62e2b020235fce1820f8ddd10b69319ebc0bb2e

      userLayoutSetting.toggleFlag = slide.toggleFlag;
      userLayoutSetting.appliedContentType = slide.appliedContentType;
      userLayoutSetting.slideTitle = slide.slideTitle;
      userLayoutSetting.backgroundColor = slide.backgroundColor;
      userLayoutSetting.textContent = slide.textContent;
      userLayoutSetting.imageUrl = slide.imageUrl;
      userLayoutSetting.altText = slide.altText;

      userLayoutSetting.audioContent =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.src
          : this.props.globalSetting.audioData.closureSlide.src;
      userLayoutSetting.slideLevelAudioMode =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.mode
          : this.props.globalSetting.audioData.closureSlide.mode;
      userLayoutSetting.interactionBackgroundColor =
        this.props.globalSetting.introductionSlides[index].backgroundColor;
      userLayoutSetting.slideTitle =
        this.props.globalSetting.introductionSlides[index].slideTitle;
      userLayoutSetting.menuTitle = introClosureSlideData[index].menuTitle;
      introClosureJsonData.push(userLayoutSetting);
      xliffContent.push(slide.slideTitle);
      if (slide.toggleFlag) xliffContent.push(slide.textContent);
    });
    interactionName = this.getInteractionName();

    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();
    menuSetting = this.props.menuSetting;

    let getGameBasedData = this.getGameBasedData(
      layoutSetting,
      globalSetting,
      xliffContent
    );

    interactionData.push(getGameBasedData.userInteractionSetting);
    let resultScreenAudioData = {
      passResult: this.props.globalSetting.audioData.passResult,
      failResult: this.props.globalSetting.audioData.failResult,
    };
    interactionData.push((resultScreenAudioData = resultScreenAudioData));
    xliffContent = getGameBasedData.xliffContent.filter(function (
      content,
      id,
      array
    ) {
      return content && content.trim() !== "" && array.indexOf(content) == id;
    });
    xliffContent = xliffContent.sort(function (a, b) {
      return b.length - a.length;
    });
    interactionJSON = {
      xliffContent,
      userType: this.props.accountInfo.currentPlan,
      acrossAllAudioChecked:
        this.props.globalSetting.audioData.acrossAllSlides.checked,
      interactionBackgroundImage: this.props.backgroundSetting.bg_image,
      backgroundImageAltText: this.props.backgroundSetting.bg_alt_text,
      interactionAudio: this.props.globalSetting.audioData.acrossAllSlides.src,
      audioModeType: this.props.globalSetting.audioData.acrossAllSlides.mode,
      interactionData,
      introClosureJsonData,
      introductionScene:
        this.props.globalSetting.introductionSlides[0].toggleFlag,
      textConstant,
      userTypeId: this.props.userTypeId,
      expiryDate: this.props.accountInfo.expiryDate,
    };

    dispatch(
      builderActions.saveUserInteraction(
        { layoutSetting },
        interactionJSON,
        sessionStorage.getItem("email"),
        interactionId,
        backgroundSetting,
        globalSetting,
        this.props.menuSetting,
        this.props.userInteractionId,
        interactionName,
        preview,
        textConstant
      )
    );
  };

  handlePanoramaUserInteraction = (interactionId, preview) => {
    let interactionData = [];
    let interactionName = "";
    let userLayoutSetting = {};
    let panoramaData = {};
    let interactionJSON = {};
    let layoutSetting = {};
    let backgroundSetting = this.props.backgroundSetting;
    let introClosureSlideData = [];
    let audio = [];
    let hotspotFont = {};
    let globalSetting = {};
    let menuSetting = {};
    let introClosureJsonData = [];
    let textConstant = this.props.textConstant;

    let xliffContent = [];
    const { dispatch } = this.props;
    // This reads selected color and image from Redux-store and bind in Background setting object
    backgroundSetting.bg_image = this.props.backgroundSetting.bg_image;
    backgroundSetting.bg_alt_text = this.props.backgroundSetting.bg_alt_text
      ? this.props.backgroundSetting.bg_alt_text
      : "";
    //Need to add JSON creation code for Image and Text only content types
    xliffContent = _.values(textConstant.AltText);
    xliffContent.push(backgroundSetting.bg_alt_text);
    audio = this.props.globalSetting.audioData.audioSlideData;
    hotspotFont = this.props.globalSetting.hotspotFont;
    panoramaData = {
      ...this.props.globalSetting.panoramaData,
      audio,
      hotspotFont,
    };
    interactionData.push(panoramaData);

    panoramaData.hotspots.forEach((hotspot, index) => {
      xliffContent.push(hotspot.hotspotTitle);
      xliffContent.push(hotspot.modalTitle);
      xliffContent.push(hotspot.modalText);
      xliffContent.push(hotspot.modalAltText ? hotspot.modalAltText : "");
      xliffContent.push(hotspot.selectedAltText ? hotspot.selectedAltText : "");
    });

    introClosureSlideData = this.props.introClosureData;
    this.props.globalSetting.introductionSlides.forEach((slide, index) => {
      userLayoutSetting = {};
      switch (slide.appliedContentType) {
        case contentType.IMAGE_TEXT:
          if (slide.selectedTextPosition == textPositionType.TOP) {
            userLayoutSetting.leftPanel = {
              layout: "topBottom",
              type: "image",
              width: `100%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
            userLayoutSetting.rightPanel = {
              layout: "topBottom",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else if (slide.selectedTextPosition == textPositionType.BOTTOM) {
            userLayoutSetting.leftPanel = {
              layout: "bottomTop",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "bottomTop",
              type: "image",
              width: `100%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
          } else if (slide.selectedTextPosition == textPositionType.LEFT) {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "image",
              width: `60%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "image",
              width: `60%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].imageUrl
                ? introClosureSlideData[index].imageUrl
                : "images/taj-img.png",
            };
          }
          break;

        case contentType.TEXT_VIDEO:
          if (slide.selectedTextPosition == textPositionType.TOP) {
            userLayoutSetting.leftPanel = {
              layout: "topBottom",
              type: "video",
              width: `100%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
            userLayoutSetting.rightPanel = {
              layout: "topBottom",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else if (slide.selectedTextPosition == textPositionType.BOTTOM) {
            userLayoutSetting.leftPanel = {
              layout: "bottomTop",
              type: "text",
              width: `100%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "bottomTop",
              type: "video",
              width: `100%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
          } else if (slide.selectedTextPosition == textPositionType.LEFT) {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "video",
              width: `60%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
          } else {
            userLayoutSetting.leftPanel = {
              layout: "singleContent",
              type: "text",
              width: `40%`,
              content: introClosureSlideData[index].textContent
                ? introClosureSlideData[index].textContent
                : UITextMessages.label.SLIDE_DEFAULT_TEXT,
            };
            userLayoutSetting.rightPanel = {
              layout: "singleContent",
              type: "video",
              width: `60%`,
              altText: introClosureSlideData[index].altText
                ? introClosureSlideData[index].altText
                : "",
              content: introClosureSlideData[index].videoUrl
                ? introClosureSlideData[index].videoUrl
                : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
              caption: "",
              transcript: introClosureSlideData[index].transcriptUrl,
              isExternalVideo: introClosureSlideData[index].isExternalVideo,
            };
          }
          break;

        case contentType.IMAGE:
          userLayoutSetting.leftPanel = {
            type: "topBottom",
            width: `100%`,
            altText: introClosureSlideData[index].altText
              ? introClosureSlideData[index].altText
              : "",
            content: introClosureSlideData[index].imageUrl
              ? introClosureSlideData[index].imageUrl
              : "images/taj-img.png",
          };
          break;

        case contentType.VIDEO:
          userLayoutSetting.leftPanel = {
            type: "topBottom",
            width: `100%`,
            content: introClosureSlideData[index].videoUrl
              ? introClosureSlideData[index].videoUrl
              : `${process.env.REACT_APP_API_URL}/video/poster.mp4`,
            caption: "",
            transcript: introClosureSlideData[index].transcriptUrl,
            isExternalVideo: introClosureSlideData[index].isExternalVideo,
          };
          break;

        default:
          break;
      }

      userLayoutSetting.textContent = slide.textContent;
      userLayoutSetting.appliedContentType = slide.appliedContentType;
      userLayoutSetting.imageUrl = slide.imageUrl;
      userLayoutSetting.altText = slide.altText ? slide.altText : "";
      userLayoutSetting.audioContent =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.src
          : this.props.globalSetting.audioData.closureSlide.src;
      userLayoutSetting.slideLevelAudioMode =
        index == 0
          ? this.props.globalSetting.audioData.introductionSlide.mode
          : this.props.globalSetting.audioData.closureSlide.mode;
      userLayoutSetting.interactionBackgroundColor =
        this.props.globalSetting.introductionSlides[index].backgroundColor;
      userLayoutSetting.slideTitle =
        this.props.globalSetting.introductionSlides[index].slideTitle;
      userLayoutSetting.menuTitle = introClosureSlideData[index].menuTitle;
      if (slide.toggleFlag) xliffContent.push(slide.textContent);
      introClosureJsonData.push(userLayoutSetting);

      xliffContent.push(userLayoutSetting.altText);
    });
    interactionName = this.getInteractionName();
    // xliffContent.push(interactionName);

    xliffContent = xliffContent.filter(function (content, id, array) {
      return content && content.trim() !== "" && array.indexOf(content) == id;
    });
    xliffContent = xliffContent.sort(function (a, b) {
      return b.length - a.length;
    });
    interactionJSON = {
      xliffContent,
      userType: this.props.accountInfo.currentPlan,
      acrossAllAudioChecked:
        this.props.globalSetting.audioData.acrossAllSlides.checked,
      interactionBackgroundImage: this.props.backgroundSetting.bg_image,
      backgroundImageAltText: this.props.backgroundSetting.bg_alt_text
        ? this.props.backgroundSetting.bg_alt_text
        : "",
      interactionAudio: this.props.globalSetting.audioData.acrossAllSlides
        .checked
        ? this.props.globalSetting.audioData.acrossAllSlides.src
        : this.props.globalSetting.audioData.audioSlideData[0].src,
      audioModeType: this.props.globalSetting.audioData.acrossAllSlides.mode,
      interactionData,
      introClosureJsonData,
      introductionScene:
        this.props.globalSetting.introductionSlides[0].toggleFlag,
      closureScene: this.props.globalSetting.introductionSlides[1].toggleFlag,
      textConstant,
      userTypeId: this.props.userTypeId,
      expiryDate: this.props.accountInfo.expiryDate,
    };

    layoutSetting = this.props.layoutSetting;
    globalSetting = this.props.globalSetting;
    interactionName = this.getInteractionName();
    menuSetting = this.props.menuSetting;

    dispatch(
      builderActions.saveUserInteraction(
        { layoutSetting },
        interactionJSON,
        sessionStorage.getItem("email"),
        interactionId,
        backgroundSetting,
        globalSetting,
        this.props.menuSetting,
        this.props.userInteractionId,
        interactionName,
        preview,
        textConstant
      )
    );
  };
  showUserInteractionPreview = (preview) => {
    const country = localStorage.getItem("country") || null;
    const occupation = localStorage.getItem("occupation") || null;
    if (
      !country ||
      country === "null" ||
      country === "" ||
      !occupation ||
      occupation === "null" ||
      occupation === ""
    ) {
      this.showUpdateUserInfoPopup();
    } else {
      this.saveUserInteraction(preview);
    }
  };

  saveUserInteraction = (preview) => {
    switch (parseInt(this.props.interactionId)) {
      case interactionType.PARALLAX:
        return this.handleParallaxUserInteraction(
          this.props.interactionId,
          preview
        );
      case interactionType.TIMELINE:
        return this.handleTimelineUserInteraction(
          this.props.interactionId,
          preview
        );
      case interactionType.CLICK_AND_REVEAL:
        return this.handleClickAndRevealUserInteraction(
          this.props.interactionId,
          preview
        );
      case interactionType.PANORAMA:
        return this.handlePanoramaUserInteraction(
          this.props.interactionId,
          preview
        );
      case interactionType.SIMPLE:
        return this.handleSimpleUserInteraction(
          this.props.interactionId,
          preview
        );
      case interactionType.GAMEBASED:
      case interactionType.OPEN_BOOK_CHALLENGE:
        return this.handleGameBasedInteraction(
          this.props.interactionId,
          preview
        );
      case interactionType.CLICK_AND_REVEAL_ASSESSMENT:
        return this.handleClickAndRevealAssessmentUserInteraction(
          this.props.interactionId,
          preview
        );
      case interactionType.FLIPCARD:
        return this.handleFlipCardUserInteraction(
          this.props.interactionId,
          preview
        );
      case interactionType.INTERACTIVE_VIDEO:
        return this.handleVideoBasedInteraction(
          this.props.interactionId,
          preview
        );
      case interactionType.KNIFE_HIT:
      case interactionType.BALLOON_HIT:
      case interactionType.BOWLING:
      case interactionType.CHEES_AND_MOUSE:
      case interactionType.HORSE_RIDING:
      case interactionType.MOUNTAIN_CLIMB:
      case interactionType.PIZZA:
      case interactionType.ROULETTE:
      case interactionType.SPIN_THE_WHEEL:
      case interactionType.SPIN_THE_WHEEL_NEW:
        return this.handleKnifeHitInteraction(
          this.props.interactionId,
          preview
        );
      case interactionType.PARKING_LOT:
      case interactionType.PINIT:
        return this.handleParkingLotInteraction(
          this.props.interactionId,
          preview
        );
      case interactionType.READ_OR_ANSWER:
      case interactionType.SUPER_GOLDIO:
        return this.handleBalloonHitInteraction(
          this.props.interactionId,
          preview
        );
      default:
        break;
    }
  };

  handlerBuilderClose = () => {
    parseInt(this.props.userInteractionId) === -1
      ? this.props.history.push(
          `/interactions?u=${this.props.accountInfo.encId}`
        )
      : this.props.history.push({
          pathname: "/mywork",
          search: `?u=${this.props.accountInfo.encId}`,
          state: { forceRefresh: true },
        });

        parseInt(this.props.userInteractionId) === -1 
        ? this.filterInteraction("Interactions")
        :this.filterInteraction("My_Work");
  


    this.setState(() => {
      return { interactionName: "", editableMode: false };
    });
  };

  handleOnViewModeChange = (value) => {
    const { dispatch } = this.props;
    dispatch(previewActions.updateViewMode(value));
  };
  /**
   * This function returns view of navigation menus on the basis of default/builder/publish
   * @returns
   */
  getMenuBarActionsView = () => {
    const expiryDate = new Date(this.props.accountInfo.expiryDate);
    let expiredStatus = false;
    let deactivationDate = _.cloneDeep(expiryDate);
    const currentDate = new Date(Date.now());

    if (expiryDate && expiryDate != "Invalid Date") {
      if (this.props.accountInfo.currentPlan.includes("Free")) {
        deactivationDate.setMonth(deactivationDate.getMonth() + 1);
      } else {
        deactivationDate.setMonth(deactivationDate.getMonth() + 3);
      }
      // if (currentDate >= deactivationDate) {
      //   this.logoutCurrentSession();
      // }
    }
    const content = (
      <div className="user-dropdown">
        <p>
          <Button
            type="link"
            data-href="/myprofile"
            onClick={this.handleRedirection} >
            {UITextMessages.label.MY_ACCOUNT}
          </Button>
          {/* <NavLink to="/myprofile">{UITextMessages.label.MY_PROFILE}</NavLink> */}
        </p>
        {/* <p>
          <NavLink to="/myaccount">
            {UITextMessages.label.ACCOUNT_DETAILS}
          </NavLink>
        </p> */}
        {/* <p><NavLink to=''>{UITextMessages.label.ABOUT_RAPTIVITY}</NavLink></p> */}
        <p>
          <Button type="link" data-href="/faq" onClick={this.handleRedirection}>
            {UITextMessages.label.FAQ}
          </Button>
          {/* <NavLink to="/faq">{UITextMessages.label.FAQ}</NavLink> */}
        </p>

        <UserTypeContext.Consumer>
          {(context) =>
            parseInt(context.contextId) === parseInt(userTypes.VC) ? (
              <>
                <p>
                  <Button type="link" onClick={this.handleLogoutClick}>
                    {UITextMessages.label.LOG_OUT}
                  </Button>{" "}
                </p>
                {this.getMyActivityView()}
              </>
            ) : (
              <p>
                <Button type="link" onClick={this.logoutCurrentSession}>
                  {UITextMessages.label.LOG_OUT}
                </Button>
              </p>
            )
          }
        </UserTypeContext.Consumer>
      </div>
    );

    if (this.props.viewModeNav === "builder") {
      if (this.props.unSavedChanges && timer.id === false) {
        timer.id = setTimeout(this.autoSave, 60000); //Need to replace hard coded timer value with dynamic one
      }
    } else {
      clearTimeout(timer.id);
      timer.id = false;
    }

    switch (this.props.viewModeNav) {
      case "builder":
        return (
          <>
            {/* Hold in phase 1 */}
            {/* <div className='undo-redo-btn'>
              <UndoIcon />
              <RedoIcon />
            </div> */}
            <div className="buider-nav-group">
              <div className="sort-bar-text-alignment">
                {this.state.editableMode ? (
                  <Input
                    value={this.state.interactionName}
                    onChange={this.handleInteractionTitle}
                    suffix={<Icon type="edit" />}
                    onBlur={this.handleInteractionTitleEditing}
                    onPressEnter={this.handleInteractionTitleEditing}
                    id="interactionName"
                    autoFocus={true}
                  />
                ) : (
                  <h3
                    id="title"
                    contentEditable={true}
                    onClick={this.handleContentToInput}
                    onBlur={this.handleInteractionTitleEditing}
                  >
                    <span>
                      {this.state.interactionName
                        ? this.state.interactionName
                        : this.props.interactionName}
                    </span>
                    <Icon component={Edit} className="icon-size" />
                  </h3>
                )}
              </div>
              <ul>
                <li className="">
                  <Button
                    id="builder-preview"
                    size="large"
                    onClick={() => this.showUserInteractionPreview(true)}
                  >
                    <Icon component={File} className="icon-size-btn" />
                    <span>{UITextMessages.label.PREVIEW}</span>
                  </Button>
                </li>
                <li>
                  <Button
                    id="builder-save"
                    size="large"
                    onClick={() => this.saveUserInteraction(false)}
                  >
                    <SaveIcon className="icon-size-btn" />
                    <span>{UITextMessages.label.SAVE}</span>
                  </Button>
                </li>
                <li className="seperator">
                  <Button
                    id="builder-close"
                    size="large"
                    onClick={this.handlerBuilderClose}
                    className="header-close-btn"
                  >
                    <Icon component={Close} className="icon-size-btn" />
                    <span>{UITextMessages.label.CLOSE}</span>
                  </Button>
                </li>
                {/* <li>
                  <Button
                    className="write-us-btn"
                    block
                  // disabled={props.isShowCase == 0 || props.isShowCase == 2 ? true : false}
                  > Write To Us
                  </Button>
                </li> */}
                {/* Hold in phase 1 */}
                {/* <li>
                  <Icon component={Feedback} className='icon-size' />
                </li>
                <li>
                  <Icon component={Help} className='icon-size' />
                </li> */}
              </ul>
              {/* Hold in phase 1 */}
              {/* <Select
                size='large'
                showSearch={false}
                defaultValue={<Icon component={Desktop} className='icon-size-select' />}
                className='width-cover'
                trigger={['click']}
              >
                <Select.Option value={0}>
                  <Icon component={Desktop} className='icon-size-select' />
                </Select.Option>
                <Select.Option value={1}>
                  <Icon component={iPadLandscape} className='icon-size-select' />
                </Select.Option>
                <Select.Option value={2}>
                  <Icon component={iPadPortrait} className='icon-size-select' />
                </Select.Option>
                <Select.Option value={3}>
                  <Icon component={MobileLandscape} className='icon-size-select' />
                </Select.Option>
                <Select.Option value={4}>
                  <Icon component={MobilePortrait} className='icon-size-select' />
                </Select.Option>
              </Select> */}
            </div>
          </>
        );
      case "preview":
        if (expiryDate && expiryDate != "Invalid Date") {
          if (
            currentDate >= expiryDate &&
            this.props.accountInfo.isSubscribed == 0
          ) {
            expiredStatus = true;
          }
        }

        return (
          <>
            <div className="preview-nav-group">
              <ul>
                {this.props.isDefaultPreview ? (
                  !this.props.isAuthenticated ? (
                    <li className="try-now-grp">
                      <Button
                        className="try-now-btn"
                        size="large"
                        onClick={() =>
                          window.location.assign(
                            "https://raptivity.com/#Raptivity-interaction-showcase"
                          )
                        }
                      >
                        <span>{UITextMessages.label.SHOWCASE}</span>
                      </Button>
                    </li>
                  ) : null
                ) : null}

                {this.props.isDefaultPreview ? (
                  !this.props.isAuthenticated ? (
                    <li className="try-now-grp">
                      <Button
                        className="try-now-btn"
                        size="large"
                        onClick={() => window.location.assign("/registration")}
                      >
                        <span>{UITextMessages.label.TRY_NOW}</span>
                      </Button>
                    </li>
                  ) : null
                ) : (
                  <li>
                    <Button
                      size="large"
                      onClick={() =>
                        expiredStatus
                          ? this.setState({ showSubscriptionModal: true })
                          : this.setState({ showPublishModal: true })
                      }
                    >
                      <Icon component={File} className="icon-size-btn" />
                      <span>{UITextMessages.label.PUBLISH}</span>
                    </Button>
                  </li>
                )}
                <li>
                  <Select
                    size="large"
                    showSearch={false}
                    defaultValue={
                      <Icon component={Desktop} className="icon-size-select" />
                    }
                    trigger={["click"]}
                    onChange={this.handleOnViewModeChange}
                  >
                    <Select.Option value={0}>
                      <Icon component={Desktop} className="icon-size-select" />
                    </Select.Option>
                    <Select.Option value={1}>
                      <Icon
                        component={iPadLandscape}
                        className="icon-size-select"
                      />
                    </Select.Option>
                    <Select.Option value={2}>
                      <Icon
                        component={iPadPortrait}
                        className="icon-size-select"
                      />
                    </Select.Option>
                    {/* Temprorry Commented code */}
                    {/* {this.props.previewInfo.interactionTemplateId === interactionType.CHEES_AND_MOUSE ? null : (
                    )} */}
                    <Select.Option value={3}>
                      <Icon
                        component={MobileLandscape}
                        className="icon-size-select"
                      />
                    </Select.Option>
                    <Select.Option value={4}>
                      <Icon
                        component={MobilePortrait}
                        className="icon-size-select"
                      />
                    </Select.Option>
                  </Select>
                </li>
                {/* Hold in phase 1 */}
                {/* <li>
                  <Icon component={Feedback} className='icon-size' />
                </li>
                <li>
                  <Icon component={Help} className='icon-size' />
                </li> */}
              </ul>
            </div>
          </>
        );
      case "myWork":
      case "myaccountdetails":
      case "myprofileform":
      case "index":
        let isFieldFilled = true;
        // if (!_.isEmpty(this.props.userInfo)) {
        //   const { occupation, country, organizationName, state, city } =
        //     this.props.userInfo.user;
        //   isFieldFilled =
        //     // !_.isEmpty(occupation) &&
        //     // !_.isEmpty(country) &&
        //     !_.isEmpty(organizationName) &&
        //     !_.isEmpty(state) &&
        //     !_.isEmpty(city) &&
        //     // !_.isNull(occupation) &&
        //     // !_.isNull(country) &&
        //     !_.isNull(organizationName) &&
        //     !_.isNull(state) &&
        //     !_.isNull(city);
        // }
        return (
          <>
            <div className="interaction-nav-menu">
              <ul className="menu-bar-horizontal" mode="horizontal">
                {this.props.userInfo.user ? (
                  <>
                    <UserTypeContext.Consumer>
                      {(context) =>
                        parseInt(context.contextId) ===
                        parseInt(userType.VC) ? (
                          <li
                            key="interactionNote"
                            onClick={() => this.handleInteractivityNote()}
                          >
                            <NavLink activeClassName="activeMenu">
                              {UITextMessages.label.MY_ACTIVITY_NOTE}
                            </NavLink>
                          </li>
                        ) : (
                          ""
                        )
                      }
                    </UserTypeContext.Consumer>
                    {/* {(localStorage.getItem('currentPlan')==="VCQuarterly" || 
                    localStorage.getItem('currentPlan')==="Standard" ||
                    localStorage.getItem('currentPlan')==="Annual Subscription" ||
                    localStorage.getItem('currentPlan')==="VCMonthly" ||
                    localStorage.getItem('currentPlan')==="Quarterly" ||
                    localStorage.getItem('currentPlan')==="Monthly") &&  */}

                    {isFieldFilled && (
                      <div className="myDIV">
                        <Button
                          className="referral-code-btn-label"
                          onClick={this.generateReferralCode}
                        >
                          <span className="refer-a-friend">
                            {UITextMessages.label.REFER_FRIEND}
                          </span>
                        </Button>
                      </div>
                    )}

                    {isFieldFilled && (
                      <li
                        key="myWork"
                        className={`seperator ${
                          this.state.showMYWork && "active-border1"
                        }`}
                      >
                        <NavLink
                          to={`/mywork?u=${this.props.accountInfo.encId}`}
                          activeClassName="activeMenu"
                          onClick={() => {
                            this.handleUnpublishedInteractions();
                            this.filterInteraction("My_Work");
                            
                          }}
                        >
                          {UITextMessages.label.MY_WORK_HEADER}
                        </NavLink>
                      </li>
                    )}
                  </>
                ) : null}
                {isFieldFilled && this.props.userInfo.user ? (
                  <li
                    key="interactions"
                    className={`"seperator" ${
                      this.state.ShowInteractions && "active-border1"
                    }`}
                  >
                    <NavLink
                      to={`/interactions?u=${this.props.accountInfo.encId}`}
                      onClick={() => this.filterInteraction("Interactions")}
                      activeClassName="activeMenu"
                      
                    >
                      {
                        <UserTypeContext.Consumer>
                          {(context) =>
                            parseInt(context.contextId) ===
                            parseInt(userType.VC)
                              ? UITextMessages.label.ACTIVITIES
                              : UITextMessages.label.INTERACTIONS
                          }
                        </UserTypeContext.Consumer>
                      }
                    </NavLink>
                  </li>
                ) : null}
                {isFieldFilled && this.props.userInfo.user ? (
                  <li key="WriteUs">
                    <WriteToUs />
                  </li>
                ) : (
                  ""
                )}
                {this.props.userInfo.user ? (
                  <li key="username">
                    <Text
                      style={{ color: "#FFFFFF", textTransform: "capitalize" }}
                    >
                      Hello {this.props.userInfo.user.name.split(" ")[0]}
                    </Text>
                  </li>
                ) : (
                  ""
                )}

                <li>
                  <Popover
                    className="menu-bar-user-pic"
                    placement="bottomRight"
                    content={content}
                    trigger="click"
                    visible={this.state.visible}
                  >
                    <div
                      className="ant-popover-profile"
                      onClick={() => {
                        this.handleTogglePopover();
                        
                      }}
                    >
                      <Avatar
                        size="large"
                        src={
                          this.props.userInfo.user &&
                          this.props.userInfo.user.profilepicurl
                        }
                      />
                      &nbsp;
                      <SmallDown />
                    </div>
                  </Popover>
                </li>
              </ul>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  getPromptMessage = () => {
    return (
      <>
        <NavigationPrompt when={this.props.unSavedChanges}>
          {({ onConfirm, onCancel }) => (
            <SavePrompt
              title={UITextMessages.message.UNSAVE_PROMPT_TITLE}
              message={UITextMessages.message.BUILDER_PROMPT_MESSAGE}
              when={true}
              handleSave={() => this.saveUserInteraction(false)}
              onCancel={onCancel}
              onConfirm={onConfirm}
            />
          )}
        </NavigationPrompt>
      </>
    );
  };
  handleInteractivityNote = () => {
    const { dispatch } = this.props;
    dispatch(interactionActions.getInteractivityNoteData());
  };
  showHowToCustomizePopup = (interactionvideo) => {
    this.setState({
      visibleHowToCustomizeModal: true,
      interactionvideo: interactionvideo,
    });
  };

  hideHowToCustomizePopup = () => {
    this.setState({
      visibleHowToCustomizeModal: false,
      playingCustomizeVideo: false,
    });
  };
  showUpdateUserInfoPopup = async () => {
    this.setState({
      visibleUpdateUserInfoModal: true,
    });
  };

  hideUpdateUserInfoPopup = () => {
    this.setState({
      visibleUpdateUserInfoModal: false,
    });
  };
  handleUnpublishedInteractions = () => {
    const { dispatch } = this.props;
    dispatch(interactionActions.getUnpublishedInteractions());
  };

  handlePublishModalClose = () => {
    this.setState({ showPublishModal: false });
  };

  generateReferralCode = async () => {
    const referralCode = this.randomString(8);
    const { dispatch } = this.props;
    const userInfo = await dispatch(getAllUserInfo());
    if (userInfo.status && userInfo.status === 200) {
      let result = userInfo.user.referralCode;
      if (result === "null" || result === "" || result === null) {
        dispatch(storeReferalCode(referralCode)).then((resp) => {
          this.showReferralCodePopup();
          document.getElementById("spinner").hidden = true;
        });
        this.state.referralCodeCopy = referralCode;
      } else {
        this.state.referralCodeCopy = result;
        this.showReferralCodePopup();
        document.getElementById("spinner").hidden = true;
      }
    }
  };

  randomString = (length) => {
    var result = "";
    let baseStr = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var i = length; i > 0; --i)
      result += baseStr[Math.floor(Math.random() * baseStr.length)];
    return result;
  };

  showReferralCodePopup = async () => {
    this.setState({
      visibleReferralCodeModal: true,
    });
  };

  hideReferralCodePopup = () => {
    this.setState({
      visibleReferralCodeModal: false,
    });
  };

  handleInteractionTitle = (e) => {
    let val = e.target.value;

    this.setState((state, props) => {
      return { interactionName: val };
    });

    const { dispatch } = this.props;
    dispatch(builderActions.setUnSavedChangesFlag(true));
  };

  handleInteractionTitleEditing = () => {
    this.setState({
      editableMode: false,
    });
  };
  handleContentToInput = () => {
    this.setState((state, props) => {
      return {
        editableMode: true,
        interactionName: state.interactionName
          ? state.interactionName
          : props.interactionName,
      };
    });
  };
  handleCancel = () => {
    this.setState({ showSubscriptionModal: false });
  };

  getMyActivityView = () => {
    const { showActivityModal, exportModalLoading } = this.state;
    const props = {};
    return (
      <Modal
        visible={showActivityModal}
        title={UITextMessages.label.MY_ACTIVITY_NOTE}
        onOk={this.handleInteractivityNote}
        onCancel={this.logoutCurrentSession}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={false}
            onClick={this.handleInteractivityNote}
            style={{ height: "40px", width: "130px", borderRadius: "8px" }}
          >
            {UITextMessages.label.DOWNLOAD}
          </Button>,
        ]}
        zIndex={"9999"}
        centered={true}
      >
        <div>
          <div className="upload-intro">
            <p
              className="upload_instruction"
              style={{ fontSize: "13px", color: "#403F50" }}
            >
              {
                <UserTypeContext.Consumer>
                  {(context) => UITextMessages.label.ACTIVITY_NOTE}
                </UserTypeContext.Consumer>
              }
            </p>
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    const { isModalVisible } = this.state;
    const { form ,userInfo  ,dispatch} = this.props;
    return (
      <>
        <Affix offsetTop={0}>
          <AntDHeader className={"layout-header"}>
            {this.getPromptMessage()}
            <div className="layout-inner-header">
              <div className="app-logo">
                {this.props.isAuthenticated && !this.props.isDefaultPreview ? (
                  <Preview_Logo />
                ) : (
                  ""
                )}
                {this.props.isAuthenticated && !this.props.isDefaultPreview ? (
                  <UserTypeContext.Consumer>
                    {(context) =>
                      context.contextId === userType.VC ? (
                        <p class="logo-virtual-seprator">
                          {UITextMessages.label.FOR_VIRTUAL_CLASSROOM}
                        </p>
                      ) : (
                        ``
                      )
                    }
                  </UserTypeContext.Consumer>
                ) : (
                  ""
                )}

                {this.props.previewInfo.interactionTemplateId &&
                  this.props.isDefaultPreview &&
                  logoData.map((type) => {
                    if (
                      parseInt(type.InteractionId) ===
                      this.props.previewInfo.interactionTemplateId
                    ) {
                      if (type.image === "NeutralLogo") {
                        return <Preview_Logo />;
                      } else if (type.image === "RVCLogo") {
                        return <RVC_Logo />;
                      }
                    }
                  })}
                {this.props.previewInfo.interactionTemplateId &&
                  this.props.isDefaultPreview &&
                  logoData.map((type) => {
                    if (
                      parseInt(type.InteractionId) ===
                      this.props.previewInfo.interactionTemplateId
                    ) {
                      if (type.showText === true) {
                        return (
                          <p class="logo-virtual-seprator">
                            {UITextMessages.label.FOR_VIRTUAL_CLASSROOM}
                          </p>
                        );
                      }
                    }
                  })}

                {/* <p><span>|</span> for Virtual Classroom</p> */}
                {this.props.viewModeNav === "builder" ? (
                  <>
                    <Button
                        className="customize-builder-btn"
                          ghost
                          onClick={() => {
                            window.open("https://www.raptivity.com/how-to-videos.html");
                          }}
                        // disabled={props.isShowCase == 0 || props.isShowCase == 2 ? true : false}
                        >
                          <img alt='How to customize Icon' className="cutomize-btn-icon" src={customizeVideo} /> {UITextMessages.label.HOW_TO_CUSTOMIZE}
                        </Button>
                  </>
                ) : null}
              </div>
              {/* {!window.location.pathname.includes('/builder/') & !window.location.pathname.includes('/preview/') ?
              <NavLink to='/myaccount'>
                  <img style={{width:175, height:60, position:"absolute", top:0}} src={SpecialOffer} />
              </NavLink>:null} */}

              {this.getMenuBarActionsView()}
              {/* <div className='clearfix' /> */}
            </div>
          </AntDHeader>
          {/* {this.props.viewModeNav === 'builder' && <UndoAndRedoBar />} */}
        </Affix>
        <PublishModal
          userInteractionName={this.props.previewInfo.userInteractionName}
          userInteractionId={this.props.previewInfo.userInteractionId}
          showPublishModal={this.state.showPublishModal}
          closePublishModal={this.handlePublishModalClose}
        />

        <SubscribeModal
          showModal={this.state.showSubscriptionModal}
          handleCancel={this.handleCancel}
        />
        <HowToCustomizeVideoModal
          visible={this.state.visibleHowToCustomizeModal}
          handleOnCancel={this.hideHowToCustomizePopup}
          videoUrl={this.state.interactionvideo}
        />
        <UpdateUserDetailsModal
          hideUpdateUserInfoPopup={this.hideUpdateUserInfoPopup}
          visible={this.state.visibleUpdateUserInfoModal}
          saveUserInteraction={this.saveUserInteraction}
          handleOnCancel={this.hideUpdateUserInfoPopup}
        />
        <CreateReferralCode
          visible={this.state.visibleReferralCodeModal}
          handleOnCancel={this.hideReferralCodePopup}
          referralCode={this.state.referralCodeCopy}
          dispatchProps={this.props}
        />
        <PersonalInfoPopup
        isModalVisible={this.state.isModalVisible}
        setModalVisible={this.setModalVisible}
        form = {form}
        userInfo={userInfo}
        dispatch={dispatch}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    builderDefaultSetting: state.builderReducer.builderDefaultSetting || {},
    interactionId: state.builderReducer.interactionId || "",
    demovideourl: state.builderReducer.demovideourl || "",
    demovideourlforvc: state.builderReducer.demovideourlforvc || "",
    userInteractionId: state.builderReducer.userInteractionId,
    previewInteractionId:
      state.previewReducer.previewInfo &&
      state.previewReducer.previewInfo.userInteractionId,
    layoutSetting: state.builderReducer.layoutSetting || {},
    backgroundSetting: state.builderReducer.backgroundSetting || {},
    userInfo: state.profileReducer.userInfo || {},
    bgColorInfo: state.backgroundSettingReducer.bgColorInfo || "",
    bgImageInfo: state.backgroundSettingReducer.bgImageInfo || "",
    slidesData: state.builderReducer.slidesData || {},
    interactionTitle: state.builderReducer.interactionTitle || "",
    globalSetting: state.builderReducer.globalSetting || null,
    menuSetting: state.builderReducer.menuSetting || null,
    timeIntervalFontSetting: state.builderReducer.globalSetting || null,
    introClosureData: state.builderReducer.introClosureData || [],
    unSavedChanges: state.builderReducer.unSavedChanges || false,
    simpleInteractionIntroductionSlide:
      state.builderReducer.simpleInteractionIntroductionSlide || {},
    interactionName: state.builderReducer.interactionName || "",
    accountInfo: state.accountReducer.accountInfo || [],
    previewInfo: state.previewReducer.previewInfo || {},
    textConstant: state.builderReducer.textConstant || {},
    builderReducer: state.builderReducer,
    tokenExists: state.tokenAuthenticationReducer.tokenStatus || false,
  };
};
export default withRouter(connect(mapStateToProps)(Form.create()(Header)));
