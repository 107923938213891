import React, { Component } from "react";
import { connect } from "react-redux";
import { UITextMessages } from "../../helpers/constants/uiTextMessages";
import * as actions from "./publishActions";
import * as actionTypes from "./publishActionTypes";
import { Button, Modal, Input, Radio, Row, Col } from "antd";
import util from "../../helpers/utils/util";
import messageType from "../../helpers/enums/messageTypes";
import ShareInteraction from "../../components/ShareInteraction";
import UserTypeContext from "../../context/userTypeContext";
import userTypes from "../../helpers/enums/userTypes";

class Publish extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: null,
      publishFormatSelected: 0,
      title: null,
      description: "",
    };
  }

  handlePublishFormatSelection = (event) => {
    this.setState({ publishFormatSelected: event.target.value });
  };

  handlePublishClick = () => {
    let title = "";

    title = this.getPublishTitle();

    if (title) {
      const { dispatch } = this.props;

      dispatch(
        actions.publishInteraction(
          this.props.userInteractionId,
          title,
          this.state.description,
          this.state.publishFormatSelected
        )
      );

      //trigger or track heatmap on below trigger
      util.registerHotJarEvents("trigger", "publish_trigger");

      //Hotjar Virtual Page View trigger.
      util.registerHotJarEvents("vpv", "/publish/");
    } else {
      util.displayMessage(
        messageType.ERROR,
        UITextMessages.message.ENTER_TITLE
      );
    }
  };

  handleTitleChange = (event) => {
    this.setState({ title: event.target.value });
  };

  handleDescriptionChange = (event) => {
    this.setState({ description: event.target.value });
  };

  handleDownloadClick = (event) => {
    const { dispatch } = this.props;

    dispatch(
      actions.downloadPublishedInteraction(
        this.props.publishedDetails.publishedInteractionId,
        this.props.publishedDetails.publishedInteractionName
      )
    );
  };

  getPublishTitle = () => {
    return this.state.title === null
      ? this.props.userInteractionName
      : this.state.title;
  };

  getPublishModalView = (context) => {
    let title = "";
    let label = this.getPublishTitle();

    title = this.getPublishTitle();

    return (
      <>
        <Row id="row-row" style={{ marginTop: "-3%" }}>
          <Col>
            <label style={{ color: "#777780" }}>
              {UITextMessages.label.INTERACTION_NAME}
              <span style={{ color: "red" }}>*</span>
            </label>
            <Input
              className="publishPopup-input"
              style={{}}
              value={title}
              type="text"
              placeholder={`${UITextMessages.label.TITLE}`}
              onChange={this.handleTitleChange}
            />
          </Col>
        </Row>
        <Row  id="row-row" >
          <Col>
            <label style={{ color: "#777780" }}>
              {UITextMessages.label.DESCRIPTION}
            </label>
            <Input
              className="publishPopup-input"
              style={{}}
              placeholder=""
              onChange={this.handleDescriptionChange}
            />
          </Col>
        </Row>
        <Row id="row-row" className="tracking-format" >
          <Col>
            <span className="format-select-label">
              {UITextMessages.label.SELECT_FORMAT}
            </span>
          </Col>
          <Col>
            <Radio.Group
              onChange={this.handlePublishFormatSelection}
              value={this.state.publishFormatSelected}
              className="format-select-col"
              id="format-select-col"
              size="large"
            >
              <Radio value={0}>None</Radio>
              <Radio value={1}>Scorm 1.2</Radio>
              <Radio value={2}>xAPI</Radio>
            </Radio.Group>
          </Col>
        </Row>
      </>
    );
  };

  getPublishInProgressView = () => {
    return (
      <>
        {" "}
        <div className="popup-loader">
          <div id="spinner" className="spinner">
            <div className="spinner-inner">
              <div className="containLoader">
                <div className="circleGroup circle-1"></div>
                <div className="circleGroup circle-2"></div>
                <div className="circleGroup circle-3"></div>
                <div className="circleGroup circle-4"></div>
                <div className="circleGroup circle-5"></div>
                <div className="circleGroup circle-6"></div>
                <div className="circleGroup circle-7"></div>
                <div className="circleGroup circle-8"></div>
              </div>
              <div className="innerText">
                <p >
                  {
                    <UserTypeContext.Consumer>
                      {(context) =>
                        parseInt(context.contextId) === parseInt(userTypes.VC)
                          ? UITextMessages.message.VC_PUBLISH_IN_PROGRESS
                          : UITextMessages.message.PUBLISH_IN_PROGRESS
                      }
                    </UserTypeContext.Consumer>
                  }
                </p>
              </div>
            </div>
          </div>
        </div>{" "}
      </>
    );
  };

  getPublishSuccessView = () => {
    return (
      <ShareInteraction
        url={this.props.publishedDetails.url}
        name={this.props.publishedDetails.publishedInteractionName}
        handleDownloadClick={this.handleDownloadClick}
        showPublishSuccessMessage={true}
        packageType={this.state.publishFormatSelected}
      />
    );
  };

  render() {
    return (
      <UserTypeContext.Consumer>
        {(context) => {
          let view = "";
          let footerActions = null;
          
          switch (this.props.publishStatus) {
            case actionTypes.PUBLISH_IN_PROGRESS:
              view = this.getPublishInProgressView();
              break;

            case actionTypes.PUBLISH_SUCCESS:
              view = this.getPublishSuccessView();
              break;

            default:
              if (context.contextId !== userTypes.VC) {
                view = this.getPublishModalView(context);
                footerActions = [
                  <Button
                    key="back"
                    type="primary"
                    onClick={this.props.closePublishModal}
                    className="publish-cancelBtn"
                  >
                    {UITextMessages.label.CANCEL}
                  </Button>,
                  <Button
                    className="publishPopup-button"
                    key="publish"
                    type="primary"
                    onClick={this.handlePublishClick}
                  >
                    {UITextMessages.label.PUBLISH}
                  </Button>,
                ];
              } else {
                this.props.showPublishModal && this.handlePublishClick();
              }

              break;
          }

          return (
            <Modal
              className="publish-modal"
              centered={true}
              visible={this.props.showPublishModal}
              title={
                <UserTypeContext.Consumer>
                  {(context) =>
                    parseInt(context.contextId) === parseInt(userTypes.VC)
                      ? UITextMessages.label.VC_PUBLISH_INTERACTION
                      : UITextMessages.label.PUBLISH_INTERACTION
                  }
                </UserTypeContext.Consumer>
              }
              onCancel={this.props.closePublishModal}
              footer={footerActions ? footerActions : null}
            >
              {view}
            </Modal>
          );
        }}
      </UserTypeContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    publishStatus: state.publishReducer
      ? state.publishReducer.publishStatus || ""
      : "",
    publishedDetails: state.publishReducer
      ? state.publishReducer.publishedDetails || {}
      : {},
  };
};

export default connect(mapStateToProps)(Publish);
