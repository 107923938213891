import React from 'react';
import { Modal, Button } from 'antd';
import { UITextMessages } from '../helpers/constants/uiTextMessages';

const SavePrompt = props => {
  const handleSave = e => {
    props.handleSave(e);
    props.onCancel();
  };
  return (
    <div>
      <Modal
      className="save-changes-main-container"
        title={props.title}
        visible={true}
        centered
        onOk={props.onConfirm}
        onCancel={props.onCancel}
        footer={[
          <div className="save-prompt-buttons">
            <Button key='submit' type='primary' onClick={props.onConfirm}>
              {UITextMessages.label.REDIRECT_ANYWAY}
            </Button>
            {/* <div className="save-changes-anyway-btn"> */}
              <Button key='submit' className="save-changes-anyway-btn" type='primary' onClick={handleSave}>
                {UITextMessages.label.SAVE_CHANGES}
              </Button>
            {/* </div> */}
          </div>
        ]}
      >
        <p className="save-changes-anyway-content">{UITextMessages.message.BUILDER_PROMPT_MESSAGE}<br/>{UITextMessages.message.BUILDER_PROMPT_MESSAGE_Text}</p>
      </Modal>
    </div>
  );
};

export default SavePrompt;
