import { combineReducers } from 'redux';
import profileReducer from './containers/myProfile/profileReducers';
import loginReducer from './containers/login/loginReducers';
import forgotPasswordReducer from './containers/forgotPassword/forgotPasswordReducers';
import socialLoginReducer from './containers/login/socialLogin/socialLoginReducers';
import registrationReducer from './containers/registration/registrationReducers';
import proRegistrationReducer from './containers/proRegistration/proRegistrationReducers';
import emailVerificationReducer from './containers/emailVerification/emailVerificationReducers';
import resetPasswordReducer from './containers/forgotPassword/resetPassword/resetPasswordReducers';
import backgroundSettingReducer from './containers/backgroundsetting/BackgroundSettingReducer';
import builderReducer from './containers/builder/builderReducers';
import previewReducer from './containers/preview/previewReducer';
import interactionsReducer from './containers/interactions/interactionsReducer';
import accountReducer from './containers/myAccount/accountReducers';
import myWorkReducer from './containers/mywork/myWorkReducer';
import publishReducer from './containers/publish/publishReducer';
import tokenAuthenticationReducer from './containers/tokenAuthentication/tokenAuthenticationReducers';

const rootReducer = combineReducers({
  profileReducer,
  loginReducer,
  forgotPasswordReducer,
  socialLoginReducer,
  registrationReducer,
  proRegistrationReducer,
  resetPasswordReducer,
  backgroundSettingReducer,
  builderReducer,
  previewReducer,
  interactionsReducer,
  accountReducer,
  myWorkReducer,
  publishReducer,
  emailVerificationReducer,
  tokenAuthenticationReducer
});

export default rootReducer;
