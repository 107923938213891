const paypalForm = {
  PREMIUM: 'PREMIUM',
  BTSONE: 'BTSONE',
  BTSFIVE: 'BTSFIVE',
  BTSTEN: 'BTSTEN',
  CANCEL_PREMIUM: 'CANCEL_PREMIUM',
  BLANK_BUTTON : '',
  VCQUARTERLY: 'VCQUARTERLY',
  VCPREMIUM: 'VCPREMIUM',
  VCMONTHLY: 'VCMONTHLY',
  QUARTERLY:'QUARTERLY',
  MONTHLY:'MONTHLY',
  ONEDAY:'ONEDAY',
  CANCEL_ONEDAY:'CANCEL_ONEDAY',
  DEACTIVATE:'DEACTIVATE',
  PREMIUMINTERACTION: 'PREMIUM_INTERACTION',
  YEARLY: 'YEARLY'
};

export default paypalForm;
